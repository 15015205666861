<template>
  <div class="h-full">
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <el-dialog v-model="showCustomPricesDialog" :title="t('itemCustomPrice')" width="30%">
      <div>
        <el-select
          filterable
          clearable
          class="w-full"
          v-model="selectedOrderItemCustomPrice"
          value-key="id"
        >
          <el-option
            v-for="option in customPricesOrderItem?.item.customPrices"
            :key="option.id"
            :label="
              $ctx.locale == 'en' ? option.customPriceType?.enName : option.customPriceType?.arName
            "
            :value="option"
          ></el-option>
        </el-select>
        <div v-if="selectedOrderItemCustomPrice" class="mt-2 text-xl">
          {{ t('selectedPrice') }}:
          {{ formatMoney(selectedOrderItemCustomPrice?.priceAmount || 0, $ctx.currency) }}
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showCustomPricesDialog = false"> {{ t('dialogs.cancel') }}</el-button>
          <el-button type="primary" @click="confirmCustomPrice()">
            {{ t('dialogs.confirm') }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <div class="flex bg-white mx-4" style="height: 95vh">
      <div class="cart w-3/4 mt-10 px-6">
        <div class="header h-14 flex flex-1 justify-between">
          <div class="search-box h-full w-full">
            <el-autocomplete
              v-model="itemSearchTerm"
              :fetch-suggestions="searchItems"
              :placeholder="t('searchItems')"
              @select="addItem"
              class="w-full h-full"
              value-key="name"
              @keypress.enter.prevent="pressedEnter = true"
              :trigger-on-focus="false"
              :prefix-icon="Search"
              ref="searchInput"
            />
          </div>
        </div>

        <div class="flex flex-row w-full overflow-x-scroll" style="height: 40px" v-if="top5Items.length > 0">
          <div v-for="item in top5Items"
               :key="item.id"
               class="bg-gray-100 p-2 mx-2 text-nowrap rounded cursor-pointer hover:bg-gray-400 hover:text-white select-none"
               @click="addItem(item)"
          >
            {{item.name}}
          </div>
        </div>

        <div class="items-table w-full mt-6 overflow-y-scroll flex flex-col">
          <el-table
            :table-layout="'fixed'"
            :data="order.orderItems"
            stripe
            border
            style="width: 100%"
            height="100%"
            class="card rounded"
            header-cell-class-name="my-header"
          >
            <!-- <el-table-column
              :min-width="40"
              type="index"
              :index="indexMethod"
              label="#"
            /> -->
            <el-table-column :min-width="90" prop="item.externalID" :label="t('itemCode')" />
            <el-table-column :min-width="220" :label="t('itemName')">
              <template #default="scope">
                <div class="flex items-center justify-between">
                  <div class="truncate">
                    <span>
                      {{ scope.row.item.name }}
                    </span>
                  </div>
                  <div style="width: 30px">
                    <span v-if="scope.row.item.isSerialized" @click="openSerialDialog(scope.row)">
                      <img src="../../assets/serials.png" alt="serials icon" class="item-serial" />
                    </span>
                  </div>
                  <div
                    v-if="scope.row.item.customPrices && scope.row.item.customPrices.length"
                    class="mx-1"
                  >
                    <button
                      class="bg-gray-300 hover:bg-gray-500 text-black hover:text-white font-bold py-1 px-2 rounded"
                      type="button"
                      @click="selectCustomPrice(scope.row)"
                    >
                      <i class="fa fa-dollar-sign"></i>
                    </button>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :min-width="120" prop="item.salePriceAmount" :label="t('price')">
              <template #default="scope">
                <div v-if="scope.row.item.priceType == 'static'">
                  {{
                    getDineroValue(
                      scope.row.itemSalePriceAmount ? scope.row.itemSalePriceAmount : 0,
                      scope.row.currency,
                      scope.row.precision
                    )
                  }}
                </div>
                <money-input
                  style="width: 110px"
                  v-if="scope.row.item.priceType == 'dynamic'"
                  id="list-price"
                  :currency="$ctx.currency"
                  v-model="scope.row.itemSalePriceAmount"
                  @dinero-created="
                    (dinero) => {
                      scope.row.itemSalePriceDinero = dinero
                    }
                  "
                  ref="salePriceAmountRef"
                />
              </template>
            </el-table-column>
            <el-table-column :min-width="125" prop="quantity" :label="t('quantity')">
              <template #default="scope">
                <el-input-number
                  style="width: 115px"
                  v-model="scope.row.quantity"
                  :step="1"
                  size="mini"
                  :min="1"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column :min-width="110" :label="t('total')">
              <template #default="scope">
                {{ getItemTotal(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column :min-width="100" :label="t('notes')" align="center">
              <template #default="scope">
                <el-input id="notes" v-model="scope.row.notes" show-word-limit clearable>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column :min-width="80" :label="t('delete')" align="center">
              <template #default="scope">
                <span class="text-red-600 cursor-pointer" @click="order.removeItem(scope.$index)">
                  <font-awesome-icon icon="trash" />
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div class="mb-2 mt-2">
            <div>{{ t('notes') }}</div>
            <div>
              <el-input v-model="order.invoice.notes" :rows="2" type="textarea" placeholder="" />
            </div>
          </div>
        </div>
      </div>

      <div class="aside w-1/4 border-l-2 flex flex-col mt-10 px-4">
        <div class="customer-button w-full self-end">
          <horizontal-button
            :title="t('buttons.addCustomer')"
            faIcon="plus"
            styles="border border-primary text-primary button-secondary"
            @click="openCustomerDialog"
            :rounded="false"
          />
        </div>

        <div class="order-info my-4 bg-white">
          <div class="cashier-info flex justify-between">
            <h1>{{ t('user') }}</h1>
            <h1>{{ ctx.currentUser.fullName }}</h1>
          </div>

          <el-divider />

          <div
            class="customer-info border-primary p-4 bg-light-blue flex justify-center items-center"
          >
            <div class="flex flex-col">
              <h1 class="text-l text-primary">
                {{ customerName === '' ? t('noCustomer') : customerName }}
              </h1>
              <small>{{ order.invoice.customer?.businessName }}</small>
            </div>
            <font-awesome-icon
              v-if="customerName"
              icon="times"
              class="ml-auto text-primary cursor-pointer"
              @click="
                () => {
                  customerName = ''
                  order.customerID = ''
                  selectedIndex = null
                  customer = null
                  order.invoice.customer = undefined
                }
              "
            />
          </div>

          <el-divider />

          <div v-if="order.customerID" class="items-total flex justify-between items-center">
            <h1>{{ t('pouchID') }}</h1>
            <el-input
              id="pouchID"
              style="width: 150px"
              v-model="order.invoice.pouchID"
              show-word-limit
              clearable
            >
            </el-input>
          </div>

          <div class="items-total flex justify-between mt-1">
            <h1>{{ t('totalItems') }}</h1>
            <h1 v-if="order.orderItems.length">{{ getTotalItemsCount() }}</h1>
          </div>

          <el-divider />

          <div class="total-info">
            <div class="sub-total flex justify-between mb-2">
              <h1 class="text-l">{{ t('subTotal') }}</h1>
              <h1 v-if="order.orderItems.length" class="text-l">
                {{ formatDinero(order.computeTotal()) }}
              </h1>
            </div>
            <div class="total-discount flex justify-between">
              <div class="flex item-center justify-between">
                <h1 class="mr-2 text-l">{{ t('totalDiscount') }}</h1>
                <span v-if="order.orderItems.length" @click="openDiscountDialog">
                  <img src="../../assets/discountTag.svg" alt="discount icon" />
                </span>
              </div>
              <h1
                v-if="order.orderItems.length"
                class="text-red-400 text-l"
                :key="order.totalDiscountAmount"
              >
                - {{ formatDinero(order.totalDiscountDinero) }}
              </h1>
            </div>
          </div>

          <el-divider />

          <div class="total flex justify-between bg-gray-100 p-2 -mx-4">
            <h1 class="font-bold 2xl:text-3xl lg:text-2xl text-l">
              {{ t('total') }}
            </h1>
            <h1 v-if="order.orderItems.length" class="font-bold 2xl:text-3xl lg:text-2xl text-l">
              {{ formatDinero(order.computeTotalAfterDiscount()) }}
            </h1>
          </div>

          <div class="action-buttons">
            <!-- TODO: add support for printing quote -->
            <!--            <horizontal-button-->
            <!--              v-if="order.orderItems.length"-->
            <!--              class="my-4"-->
            <!--              :title="t('buttons.quote')"-->
            <!--              faIcon="save"-->
            <!--              styles="border border-primary text-l button-secondary"-->
            <!--              @click="openQuoteDialog"-->
            <!--              :rounded="false"-->
            <!--            />-->
            <horizontal-button
              v-if="order.orderItems.length"
              class="my-4"
              :title="t('buttons.cancel')"
              faIcon="cancel"
              styles="border border-red-500 h-12 text-l text-red-500 hover:bg-red-500 hover:text-white"
              @click="resetOrder()"
              :rounded="false"
            />
            <horizontal-button
              :title="t('buttons.checkout')"
              styles="button-primary 2xl:h-20 lg:h-16 h-12 2xl:text-3xl lg:text-2xl text-l font-bold mt-4"
              :rounded="false"
              @click="
                () => {
                  if (order.orderItems.length) {
                    checkoutDrawerOpen = true
                  }
                }
              "
            />
          </div>
          <el-drawer
            v-model="checkoutDrawerOpen"
            :direction="'rtl'"
            @open="openCheckoutDialog"
            :lock-scroll="false"
            class="overflow-scroll"
          >
            <div class="px-5">
              <div class="mb-2 w-full">
                <!-- I chose to keep the below as a comment just in case we decided to use labels later on again -->
                <!-- <p class="mb-2 font-semibold">{{ t("selectPaymentType") }}</p> -->
                <el-radio-group class="w-full flex" v-model="order.paymentType">
                  <el-radio-button class="flex-1 text-2xl" label="direct" border>
                    {{ t('buttons.direct') }}</el-radio-button
                  >
                  <el-radio-button class="flex-1 text-2xl" label="credit_sale" border>
                    {{ t('buttons.creditSale') }}</el-radio-button
                  >
                  <el-radio-button class="flex-1 text-2xl" label="layaway" border>
                    {{ t('buttons.layaway') }}</el-radio-button
                  >
                </el-radio-group>
              </div>

              <div class="flex justify-between items-center mt-8">
                <h1 class="w-auto flex items-center 2xl:text-l lg:text-l text-m">
                  {{ t('totalDue') }}
                </h1>
                <div class="line border w-1/3 mx-2"></div>
                <h1
                  class="text-m w-auto flex items-center justify-end 2xl:text-l lg:text-l text-m text-red-500"
                >
                  {{ formatDinero(order.computeTotalAfterDiscount()) }}
                </h1>
              </div>

              <PaymentInput
                :totalDue="order.computeTotalAfterDiscount().getAmount()"
                v-model:changeDue="order.changeDueAmount"
                v-model:paidBeforeChange="order.invoice.reqOnlyTotalCashPaidAmount"
                v-model:totalPaid="order.totalPaidAmount"
                v-model:epayment="order.invoice.reqOnlyTotalEpaymentAmount"
                :currency="order.currency"
                :precision="order.precision"
                ref="paymentInput"
              />

              <div class="flex items-center justify-between mb-2">
                <horizontal-button
                  class="text-lg complete-payment"
                  :title="t('buttons.completePay')"
                  @click="openCashierPinDialog(getCasherInputRef)"
                  :rounded="false"
                />
              </div>
              <div class="mt-1">
                <div>{{ t('notes') }}</div>
                <div>
                  <el-input v-model="order.paymentNotes" :rows="2" type="textarea" placeholder="" />
                </div>
              </div>
            </div>
          </el-drawer>
          <el-drawer
            v-model="quoteDrawerOpen"
            :direction="'rtl'"
            @open="openQuoteDialog"
            :lock-scroll="false"
            class="overflow-scroll"
          >
            <div class="p-5 h-full">
              <div class="flex p-6 justify-between bg-gray-300">
                <h1 class="text-l">quote #</h1>
                <h1 class="text-l">001929</h1>
              </div>
              <div class="flex justify-center items-center flex-col h-3/6">
                <img src="../../assets/quoteDone.svg" alt="quote done " />
                <h1 class="mt-4 text-2xl">quote have been saved</h1>
              </div>
              <div class="h-2/6 flex flex-col justify-between">
                <horizontal-button
                  styles="h-20 border-primary text-primary text-2xl"
                  :title="t('buttons.printQuote')"
                  @click="() => {}"
                  :rounded="false"
                />
                <horizontal-button
                  styles="h-20 border-primary text-primary text-2xl font-bold"
                  :title="t('buttons.checkoutQuote')"
                  @click="() => {}"
                  :rounded="false"
                />
                <horizontal-button
                  class="mt-4"
                  styles="h-20 bg-primary text-white text-2xl font-bold"
                  :title="t('buttons.newTransaction')"
                  @click="resetOrder"
                  :rounded="false"
                />
              </div>
            </div>
          </el-drawer>
          <el-dialog
            :title="t('selectCustomer')"
            v-model="customerDialogOpen"
            width="30%"
            @close="closeCustomerDialog"
          >
            <div class="flex items-center justify-center" :dir="$ctx.getDir()">
              <el-select
                filterable
                clearable
                class="w-full"
                v-model="order.customerID"
                value-key="contactDisplayName"
                remote
                @change="onCustomerNameChange"
                :remote-method="searchCustomers"
                :key="order.customerID"
              >
                <el-option
                  v-for="option in customers"
                  :key="option.id"
                  :label="option.contactDisplayName"
                  :value="option.id"
                ></el-option>
              </el-select>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button
                  @click="
                    () => {
                      customerDialogOpen = false
                      newCustomerDialogVisiable = true
                    }
                  "
                  type="secondary"
                  ><span class="fa fa-plus"></span>
                  {{ t('views.customers.newCustomer') }}</el-button
                >
                <el-button type="primary" @click="closeCustomerDialog">{{
                  t('buttons.confirm')
                }}</el-button>
              </span>
            </template>
          </el-dialog>
          <el-dialog
            :title="t('selectSerials')"
            v-model="insertSerialDialogVisible"
            width="30%"
            @close="closeSerialDialog"
          >
            <div class="flex items-center justify-center" :dir="$ctx.getDir()">
              <el-select
                v-if="currentOrderItem.item.id"
                filterable
                clearable
                multiple
                class="w-full"
                v-model="currentOrderItem.serialIDs"
                :multiple-limit="currentOrderItem.quantity"
              >
                <el-option
                  v-for="(item, i) in currentOrderItem.item.itemStocks[0].itemSerials"
                  :key="i"
                  :label="item.number"
                  :value="item.id"
                  ref="serialInput"
                >
                  <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                    {{ item.number }}
                  </span>
                </el-option>
              </el-select>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="closeSerialDialog">{{
                  t('buttons.confirm')
                }}</el-button>
              </span>
            </template>
          </el-dialog>
          <el-dialog v-model="discountDialogOpen" width="30%" @close="closeDiscountDialog">
            <div class="w-full flex flex-col items-center justify-center" :dir="$ctx.getDir()">
              <div class="w-full flex items-center justify-between">
                <h1 class="text-l font-bold">{{ t('subTotal') }}</h1>
                <h1 class="text-l font-bold">
                  {{ formatDinero(order.computeTotal()) }}
                </h1>
              </div>

              <el-divider />

              <el-radio-group class="w-full flex mb-4" v-model="order.discountType">
                <el-radio-button class="flex-1" label="percentage" border>
                  {{ t('percentage') }}</el-radio-button
                >
                <el-radio-button class="flex-1" label="amount" border>
                  {{ t('amount') }}</el-radio-button
                >
              </el-radio-group>

              <div
                v-if="order.discountType === 'percentage'"
                class="mb-4 w-full flex items-center justify-between"
              >
                <h1 class="text-l font-bold">{{ t('percentage') }}</h1>
                <div class="flex items-center">
                  <span class="mx-6 text-l font-bold">%</span>
                  <el-input-number
                    v-model="order.discountByPercentage"
                    :step="1"
                    size="medium"
                    :min="0"
                    :controls="false"
                    ref="discountPercentageInput"
                  />
                </div>
              </div>
              <div
                v-if="order.discountType === 'amount'"
                class="mb-6 w-full flex items-center justify-between"
              >
                <h1 class="text-l font-bold">{{ t('amount') }}</h1>
                <div class="flex items-center">
                  <span class="mx-6 text-l font-bold">{{ $ctx.currency }}</span>
                  <el-input-number
                    v-model="order.discountByAmount"
                    size="medium"
                    :min="0"
                    :controls="false"
                    ref="discountAmountInput"
                  />
                </div>
              </div>

              <div class="w-full flex items-center justify-between bg-gray-200 p-4">
                <h1 class="text-l font-bold">{{ t('total') }}</h1>
                <h1 class="text-blue-500 text-l font-bold">
                  {{ formatDinero(order.computeTotalAfterDiscount()) }}
                </h1>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" class="bg-primary" @click="closeDiscountDialog">{{
                  t('buttons.confirm')
                }}</el-button>
                <el-button type="danger" @click="cancelDiscount">{{
                  t('buttons.cancelDiscount')
                }}</el-button>
              </span>
            </template>
          </el-dialog>
          <el-dialog v-model="newCustomerDialogVisiable" :title="t('views.customers.newCustomer')">
            <NewCustomerDialog @success="onCustomerCreater"></NewCustomerDialog>
          </el-dialog>
          <el-dialog v-model="cashierPinDialogOpen" width="30%" @close="closeDiscountDialog">
            <div>
              <!-- Enter the pin -->
              <div class="my-2">
                <p>{{ t('enterCashierPin') }}</p>
              </div>

              <!-- password input up to 6 chars -->
              <div class="my-2">
                <el-input
                  id="password"
                  v-model="cashierPin"
                  type="password"
                  required
                  show-password
                  autofocus
                  dir="ltr"
                  :maxlength="4"
                  :minlength="4"
                  @keyup.enter="submitOrder"
                  ref="cashierPinInput"
                />
              </div>

              <!-- finish payment -->
              <div class="flex items-center justify-between mb-2">
                <horizontal-button
                  class="text-lg complete-payment"
                  :title="t('buttons.completePay')"
                  @click="submitOrder"
                  :rounded="false"
                />
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue'
import HorizontalButton from '@/components/HorizontalButton.vue'
import Item, { ItemCustomPrice } from '@/models/inventory/Item'
import { i18nOrderMessages, Order, OrderItem } from '@/models/sales/Order'
import PaymentInput from './components/PaymentInput.vue'
import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { useI18n } from 'vue-i18n'
import { localizeFieldName } from '@/plugins/i18n'
import { Register, RegisterLog } from '@/models/company/Register'
import { useContext } from '@/plugins/context'
import { useRouter, useRoute } from 'vue-router'
import AlertBadge from '@/components/AlertBadge.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import Customer from '@/models/customer/Customer'
import Dinero, { Currency } from 'dinero.js'
import { formatDinero, formatMoney } from '@/utils/money'
import { Route } from '@/router/routes'
import User from '@/models/user/User'
import Location from '@/models/company/Location'
import MoneyInput from '@/components/form/MoneyInput.vue'
import NewCustomerDialog from '@/dialogs/customer/NewCustomerDialog.vue'
import { ElInput } from 'element-plus'
import CustomPriceType from '@/models/inventory/CustomPrice'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'new-checkout',
  components: {
    HorizontalButton,
    PaymentInput,
    AlertBadge,
    LoadingSpinner,
    MoneyInput,
    NewCustomerDialog,
    ElInput
  },
  async mounted() {
    // load register status
    try {
      this.loading = true
      const registerLog = await this.http.get<RegisterLog>(
        `${Register.ENDPOINT}/status/${this.ctx.currentRegister.id}`
      )

      this.ctx.updateRegisterStatus(RegisterLog.from(registerLog).action)

      if (!registerLog.action || registerLog.action === 'close') {
        await this.router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER)
      }
      this.getCustomPriceTypes()

      const top5Items = await this.$http.get<Item[]>("/inventory/top5items")
      this.top5Items = top5Items.map((item) => Item.from(item))

    } catch (error) {
      this.error.title = error.title
      this.error.body = error.body
    } finally {
      this.loading = false
      setTimeout(() => {
        this.searchInput.focus()
      }, 100)
    }
  },

  watch: {
    'order.totalDiscountAmount': {
      handler: function (value: number) {
        if (isNaN(value)) {
          value = 0;
        }
        this.order.totalDiscountDinero = Dinero({
          amount: value,
          currency: this.order.currency,
          precision: this.order.precision
        })
      }
    },
    customer: function (val: Customer | null) {
      if (val) {
        for (let i = 0; i < this.order.orderItems.length; i++) {
          if (this.order.orderItems[i].customPriceID?.length) continue
          if (this.order.orderItems[i].item.customPrices != null) {
            let found = false
            this.order.orderItems[i].item.customPrices.forEach((cp) => {
              if (cp.id == val.customerType && cp.priceAmount! > 0) {
                found = true
                this.order.orderItems[i].itemSalePriceAmount = cp.priceAmount!
              }
            })
            if (!found) {
              this.order.orderItems[i].itemSalePriceAmount =
                this.order.orderItems[i].item.salePriceAmount!
            }
          }
        }
      } else {
        for (let i = 0; i < this.order.orderItems.length; i++) {
          if (this.order.orderItems[i].customPriceID?.length) continue
          this.order.orderItems[i].itemSalePriceAmount =
            this.order.orderItems[i].item.salePriceAmount!
        }
      }
    }
  },

  setup() {
    const http = useHTTP()
    const alertModal = useAlertModal()
    const ctx = useContext()
    const router = useRouter()
    const route = useRoute()
    const selectedIndex = ref()
    const itemSearchTerm = ref('')
    const order = ref(new Order())
    const paymentType = ref('')
    const checkoutDrawerOpen = ref(false)
    const quoteDrawerOpen = ref(false)
    const pressedEnter = ref(false)
    const data = ref(order.value.orderItems)
    const error = ref({ title: '', body: '' })
    const loading = ref(false)
    const customerDialogOpen = ref(false)
    const newCustomerDialogVisiable = ref(false)
    const customers = ref(new Array<Customer>())
    const customer = ref(null as Customer | null)
    const customerName = ref('')
    const direction = ref('rtl')
    const currentOrderItem = ref(new OrderItem(new Item()))
    const insertSerialDialogVisible = ref(false)
    const discountDialogOpen = ref(false)
    const cashierPinDialogOpen = ref(false)
    const cashierPin = ref('')
    // const cashierPinInput = ref<HTMLInputElement | null>(null);
    const searchInput = ref()
    const discountPercentageInput = ref()
    const discountAmountInput = ref()
    const customPriceTypes = ref([] as Array<CustomPriceType>)
    const top5Items = ref([] as Array<Item>)
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        en: {
          ...i18nOrderMessages.en,
          notes: 'Notes',
          totalItems: 'Total Items',
          totalDiscount: 'Total Discount',
          subTotal: 'Sub Total',
          totalDue: 'Total Due',
          searchItems: 'Enter item barcode or SKU or name',
          delete: 'Delete',
          noCustomer: 'No Customer Selected',
          amount: 'Amount',
          percentage: 'Percentage',
          enterCashierPin: 'Enter cashier pin',
          buttons: {
            addCustomer: 'Add Customer',
            payment: 'Payment',
            direct: 'Direct',
            creditSale: 'Credit Sale',
            layaway: 'Layaway',
            quote: 'Save as Quote',
            cancel: 'Cancel Order',
            cancelDiscount: 'Cancel Discount',
            confirm: 'Confirm',
            completePay: 'Process Payment',
            checkout: 'Checkout',
            printQuote: 'Print Quote',
            checkoutQuote: 'Checkout Quote Items',
            newTransaction: 'New Transaction'
          },

          selectSerials: 'Select serials',
          selectCustomer: 'Select a customer',
          selectPaymentType: 'Select order type',

          outOfStockErr:
            'Item {name} is out of stock. Please update your stock before selling this item',

          successMsg: 'Sale complete',

          errPaymentMustMatch:
            "Payment must be made in full. If you'd like to pay partially, choose credit sale",

          errCashierPinRequired: 'Cashier pin is required',
          itemCustomPrice: 'Item Custom Price',
          selectedPrice: 'Selected Price'
        },
        ar: {
          ...i18nOrderMessages.ar,
          notes: 'الملاحظات',
          totalItems: 'إجمالي العناصر',
          totalDiscount: 'اجمالي التخفيض',
          subTotal: 'المبلغ الإجمالي',
          totalDue: 'الاجمالي المستحق',
          searchItems: 'ادخل الباركود او ال SKU او اسم المنتج',
          delete: 'حذف',
          noCustomer: 'لم يتم اختيار أي زبون',
          amount: 'الخصم بالمبلغ',
          percentage: 'الخصم بالنسبة',
          enterCashierPin: 'ادخل رمز الكاشير الخاص بك',
          buttons: {
            addCustomer: 'اضافة زبون',
            payment: 'دفع',
            direct: 'مباشر',
            creditSale: 'آجل',
            layaway: 'عربون',
            quote: 'الحفظ كعرض',
            cancel: 'الغاء البيع',
            cancelDiscount: 'الغاء التخفيض',
            confirm: 'التأكيد',
            completePay: 'اتمام الدفع',
            checkout: 'الدفع',
            printQuote: 'اطبع قائمة التسعير',
            checkoutQuote: 'إتمام بيع قائمة التسعير',
            newTransaction: 'عملية بيع جديدة'
          },

          selectSerials: 'اختر الارقام',
          selectCustomer: 'اختر زبون',
          selectPaymentType: 'اختر نوع البيع',

          outOfStockErr: 'نفذ مخزون المنتج {name}. يرجى اعادة تحديث المخزن قبل بيع المنتج.',

          successMsg: 'تم اكمال البيع',

          errPaymentMustMatch:
            'يجب ان يتم دفع المبلغ بشكل كامل. لدفع مبلغ جزئي، قم بتحويل نوع الدفع الى دين او عربون',

          errCashierPinRequired: 'يرجى ادخال رمز الكاشير لاتمام البيع',
          itemCustomPrice: 'اختيار سعر المنتج',
          selectedPrice: 'السعر المختار'
        }
      }
    })
    const headers = [t('itemName'), t('price'), t('quantity'), t('total')]

    function openSerialDialog(oi: OrderItem) {
      currentOrderItem.value = oi
      insertSerialDialogVisible.value = true
    }

    function closeSerialDialog() {
      currentOrderItem.value = new OrderItem(new Item())
      insertSerialDialogVisible.value = false
    }

    function updateItemsCustomPrice() {
      if (customer.value) {
        for (let i = 0; i < order.value.orderItems.length; i++) {
          if (order.value.orderItems[i].customPriceID?.length) continue
          if (order.value.orderItems[i].item.customPrices != null) {
            let found = false
            order.value.orderItems[i].item.customPrices.forEach((cp) => {
              if (cp.id == customer.value!.customerType && cp.priceAmount! > 0) {
                found = true
                order.value.orderItems[i].itemSalePriceAmount = cp.priceAmount!
              }
            })
            if (!found) {
              order.value.orderItems[i].itemSalePriceAmount =
                order.value.orderItems[i].item.salePriceAmount!
            }
          }
        }
      }
    }

    function addItem(selected: Item) {
      itemSearchTerm.value = ''
      // don't add items that are out of stock!
      if (
        selected.currentLocationStock.quantity <= 0 &&
        !(selected.unlimitedStock || selected.sellOutOfStock)
      ) {
        alertModal.showDanger({
          title: t('outOfStockErr', { name: selected.name })
        })
        return
      }

      const oi = order.value.addItem(selected)
      updateItemsCustomPrice()
      if (selected.isSerialized) {
        openSerialDialog(oi)
      }
    }

    async function searchItems(query: string, cb: Function) {
      if (pressedEnter.value) {
        itemSearchTerm.value = ''
      }
      // if an item already exists in the table, don't do remote search
      const cachedItem = order.value.findByBarcode(query) || order.value.findBySKU(query)
      if (cachedItem) {
        addItem(cachedItem)
        cb([])
        return
      }
      try {
        const url = `${Item.ENDPOINT}?q=${query}`
        let items = await http.get<Item[]>(url)
        items = items.map((item) => Item.from(item))
        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (pressedEnter.value && items.length === 1) {
          addItem(items[0])
          cb([])
        } else {
          // show them all options
          cb(items)
        }
      } catch (error) {
        alertModal.showDanger({ title: error.title })
        cb([])
      }
      pressedEnter.value = false
    }

    function isOrderValid() {
      const errors: string[] = []
      if (order.value.customerID === '' && order.value.paymentType !== 'direct') {
        errors.push(t('noCustomer'))
      }
      order.value.orderItems.forEach((oi) => {
        if (oi.item?.isSerialized && oi.serialIDs.length !== oi.quantity) {
          const errMsg = `${oi.item.name}: ${t('validation.matchingLength', {
            thisField: localizeFieldName('lenSerialNumbers', i18nOrderMessages),
            thatField: localizeFieldName('quantity', i18nOrderMessages)
          })}`
          errors.push(errMsg)
        }
      })

      if (errors.length) {
        error.value.title = t('validation.inputErrors')
        error.value.body = errors.join('\n')
        checkoutDrawerOpen.value = false

        return false
      }

      return true
    }

    function openQuoteDialog() {
      quoteDrawerOpen.value = true
    }

    function openCustomerDialog() {
      ctx.getDir() === 'rtl' ? (direction.value = 'rtl') : (direction.value = 'ltr')

      customerDialogOpen.value = true
    }

    function closeCustomerDialog() {
      if (selectedIndex.value == undefined) {
        customerName.value = ''
        customer.value = null
        order.value.invoice.customer = undefined
      } else if (selectedIndex.value >= 0) {
        customerName.value = customers.value[selectedIndex.value].contactDisplayName
        customer.value = customers.value[selectedIndex.value]
        order.value.invoice.customer = customer.value
      } else {
        customerName.value = ''
        customer.value = null
        order.value.invoice.customer = undefined
      }

      customerDialogOpen.value = false
    }

    function closeDiscountDialog() {
      discountDialogOpen.value = false
    }

    function cancelDiscount() {
      discountDialogOpen.value = false
      order.value.discountByAmount = 0
      order.value.discountByPercentage = 0
    }

    function openDiscountDialog() {
      discountDialogOpen.value = true
      order.value.discountType = 'amount'
      setTimeout(() => {
        const input = discountAmountInput.value.$el.querySelector('input')
        input.focus()
        input.select()
      }, 100)
    }

    async function searchCustomers(query: string) {
      if (query) {
        try {
          const url = `${Customer.ENDPOINT}?q=${query}`
          const customersRes = await http.get<Customer[]>(url)
          customers.value = customersRes.map((customer) => Customer.from(customer))
        } catch (error) {
          alertModal.showDanger({ title: error.title })
          customers.value = []
        }
      }
    }

    async function getCustomPriceTypes() {
      const customPriceTypesResp = await http.get<CustomPriceType[]>(CustomPriceType.ENDPOINT)

      customPriceTypes.value = customPriceTypesResp.map((cat) => CustomPriceType.from(cat))
    }

    function onCustomerNameChange(value: string) {
      selectedIndex.value = customers.value.findIndex((customer) => customer.id === value)
    }

    function getDineroValue(amount: number, currency: Currency, precision: number) {
      const dinero = Dinero({
        amount,
        currency,
        precision
      })

      return formatDinero(dinero)
    }

    function getItemTotal(orderItem: OrderItem) {
      const total =
        (orderItem.quantity as number) *
        (orderItem.itemSalePriceAmount ? orderItem.itemSalePriceAmount : 1)
      return getDineroValue(total, orderItem.currency, orderItem.precision)
    }

    function getTotalItemsCount() {
      const totalCount = order.value.orderItems.reduce((acc, item) => {
        return acc + item.quantity
      }, 0)

      return totalCount
    }

    function resetOrder() {
      order.value = new Order()
      selectedIndex.value = null
      customerName.value = ''
      customer.value = null
      // order.value.empty();
      pressedEnter.value = false
      itemSearchTerm.value = ''
      error.value = { title: '', body: '' }

      currentOrderItem.value = new OrderItem(new Item())
      insertSerialDialogVisible.value = false

      customerDialogOpen.value = false
      customers.value = new Array<Customer>()

      checkoutDrawerOpen.value = false
      quoteDrawerOpen.value = false
    }

    function isValidPayment() {
      const totalPaid =
        order.value.invoice.reqOnlyTotalCashPaidAmount +
        order.value.invoice.reqOnlyTotalEpaymentAmount

      if (order.value.paymentType === 'direct' && order.value.totalDueAmount > totalPaid) {
        error.value.title = t('errPaymentMustMatch')
        checkoutDrawerOpen.value = false
        cashierPinDialogOpen.value = false

        return false
      }

      return true
    }

    async function openCashierPinDialog(casherInputRef: any) {
      // for validation purposes
      order.value.preparePayload(
        ctx.currentUser as User,
        ctx.currentRegister as Register,
        ctx.currentLocation as Location
      )

      if (!isOrderValid() || !isValidPayment()) {
        return
      }

      cashierPinDialogOpen.value = true

      nextTick(() => {
        setTimeout(() => {
          casherInputRef().focus()
        }, 0);
      })
    }

    async function submitOrder() {
      if (cashierPin.value === '') {
        error.value.title = t('errCashierPinRequired')
        cashierPinDialogOpen.value = false
        checkoutDrawerOpen.value = false
        return
      }

      const requestData = order.value.preparePayload(
        ctx.currentUser as User,
        ctx.currentRegister as Register,
        ctx.currentLocation as Location
      )

      if (!isOrderValid() || !isValidPayment()) {
        return
      } else {
        error.value.title = ''
        error.value.body = ''
        cashierPinDialogOpen.value = false

        try {
          const o = await http.post<Order>(Order.ENDPOINT, requestData, {
            headers: { pin: cashierPin.value }
          })
          alertModal.showSuccess({ title: t('successMsg') })
          await router.push(Route.SALES_ORDERS_ORDER_RECEIPT.replace(':id', o.id || ''))
        } catch (err) {
          error.value.title = err.title
          error.value.body = err.body
          checkoutDrawerOpen.value = false
        }
      }
    }

    function indexMethod(index: number) {
      return index + 1
    }

    async function onCustomerCreater(_customer: Customer) {
      order.value.customerID = _customer.id || ''
      customer.value = _customer
      customerName.value = `${_customer.firstName} ${_customer.lastName}`
      newCustomerDialogVisiable.value = false
    }

    const showCustomPricesDialog = ref(false)
    const customPricesOrderItem = ref(null as OrderItem | null)
    const selectedOrderItemCustomPrice = ref(null as ItemCustomPrice | null)

    function selectCustomPrice(orderItem: OrderItem) {
      selectedOrderItemCustomPrice.value = null

      const currentItemPrices = orderItem.item.customPrices
      const newItemPrices = [] as Array<ItemCustomPrice>
      customPriceTypes.value.forEach((cp) => {
        const t = currentItemPrices.filter((i) => i.id == cp.id)
        if (t.length) {
          if (t[0].priceAmount! > 0) {
            newItemPrices.push({
              id: cp.id,
              priceAmount: t[0].priceAmount,
              priceName: cp.enName,
              customPriceType: cp
            } as ItemCustomPrice)
          }
        }
      })
      orderItem.item.customPrices = newItemPrices

      customPricesOrderItem.value = orderItem

      showCustomPricesDialog.value = true
    }

    function confirmCustomPrice() {
      if (customPricesOrderItem.value) {
        if (selectedOrderItemCustomPrice.value) {
          if (selectedOrderItemCustomPrice.value.priceAmount! <= 0) {
            showCustomPricesDialog.value = false
            return
          }
          customPricesOrderItem.value!.itemSalePriceAmount =
            selectedOrderItemCustomPrice.value.priceAmount!
          customPricesOrderItem.value!.customPriceID = selectedOrderItemCustomPrice.value.id!
        }
      }

      showCustomPricesDialog.value = false
    }

    return {
      ctx,
      pressedEnter,
      itemSearchTerm,
      selectedIndex,
      order,
      searchItems,
      addItem,
      headers,
      data,
      checkoutDrawerOpen,
      paymentType,
      http,
      alertModal,
      t,
      error,
      loading,
      router,
      route,
      customerDialogOpen,
      newCustomerDialogVisiable,
      customers,
      customerName,
      openCustomerDialog,
      closeCustomerDialog,
      onCustomerNameChange,
      searchCustomers,
      direction,
      currentOrderItem,
      insertSerialDialogVisible,
      closeSerialDialog,
      openSerialDialog,
      getDineroValue,
      getItemTotal,
      discountDialogOpen,
      closeDiscountDialog,
      cashierPinDialogOpen,
      cashierPin,
      // cashierPinInput,
      openCashierPinDialog,
      openDiscountDialog,
      getTotalItemsCount,
      formatDinero,
      formatMoney,
      resetOrder,
      submitOrder,
      quoteDrawerOpen,
      openQuoteDialog,
      cancelDiscount,
      searchInput,
      discountPercentageInput,
      discountAmountInput,
      indexMethod,
      onCustomerCreater,
      isOrderValid,
      customer,
      selectCustomPrice,
      showCustomPricesDialog,
      customPricesOrderItem,
      confirmCustomPrice,
      selectedOrderItemCustomPrice,
      getCustomPriceTypes,
      Search,
      top5Items
    }
  },
  methods: {
    getCasherInputRef() {
      /* @ts-ignore */
      return this.$refs['cashierPinInput']
    },
    openCheckoutDialog() {
      this.$nextTick(() => {
        /* @ts-ignore */
        const paymentInput = this.$refs['paymentInput']
        if (paymentInput) {
          /* @ts-ignore */
          paymentInput.cashReceivedInputFocus()
        }
      })
      // order.value.prepareForCheckout();
      if (!this.isOrderValid()) {
        this.checkoutDrawerOpen = false
      }
    }
  }
})
</script>

<style>
@import url(../../assets/css/index.css);
.bg-primary {
  background-color: var(--primary-bg-color);
}

.border-primary {
  border: 1px solid var(--primary-bg-color);
}

.text-primary {
  color: var(--primary-bg-color);
}

.bg-light-blue {
  background-color: #f2f7ff;
}

.item-name {
  width: 50%;
}

.item-price {
  height: inherit;
  width: auto;
}

.item-quantity {
  width: 15%;
}

.item-total {
  width: 15%;
}

.item-delete {
  width: 5%;
}

.el-radio-button__inner {
  width: 100% !important;
  font-size: 1.2rem;
}

.items-table {
  height: 65vh !important;
}

.el-drawer__header {
  padding-top: 5px;
}

.item-serial {
  height: 20px;
  width: 65px;
}
</style>

<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/inventory/itemcategories')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
        <template v-if="$can('delete', '/inventory/itemcategories')">
          <el-option
            key="delete"
            :label="$t('views.common.listView.selectedRows.delete')"
            value="delete"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/inventory/itemcategories')">
          <horizontal-button
            :title="$t('views.inventory.categories.newCategory')"
            @click.prevent="$router.push($Route.INVENTORY_ITEM_CATEGORIES_NEW_CATEGORY)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'

import Category, { categoryI18nMessages } from '@/models/inventory/Category'
import { useI18n } from 'vue-i18n'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'categories',

  setup() {
    const { t } = useI18n({
      messages: categoryI18nMessages
    })

    return { t, Search }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Category[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t('name'),
        field: 'name',
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t('parentCategory'),
        field: 'parentCategory.name'
      }
    ]
  },

  mounted() {
  },

  watch: {
    searchTerm() {
      this.filterResult()
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<Category[]>(Category.ENDPOINT)
        this.rowData = data.map((category) => Category.from(category))
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridApi?.sizeColumnsToFit()
      await this.updateRowData()
    },

    async delete(categories: Category[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.deleteConfirm'))) {
        try {
          await this.$http.delete(Category.ENDPOINT, { data: categories })
          await this.updateRowData()
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.deleteSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },

    async applyToSelected() {
      if (this.selectedValue === 'delete' || this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'delete') {
            // send request to archive
            await this.delete(selected)
          } else {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    filterResult() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.INVENTORY_ITEM_CATEGORIES_CATEGORY.replace(':id', event.data.id)
      )
    }
  }
})
</script>

<style></style>

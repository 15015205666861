<template>
  <el-form
    @submit.prevent="validateSubmit()"
    ref="customerForm"
    :model="customer"
    :rules="rules"
    label-position="top"
    novalidate
  >
    <div class="w-full">
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button :title="$t('actions.saveChanges')" isSuccess faIcon="save" />
        </div>

        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm()"
          />
        </div>
      </div>
    </div>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      class="w-full"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <div class="w-full">
      <h1>{{ t('sections.contact') }}</h1>

      <input-group>
        <el-form-item :label="t('firstName')" prop="firstName" class="input-label">
          <el-input
            id="firstName"
            v-model="customer.firstName"
            :placeholder="t('placeholders.firstName')"
            maxlength="127"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('lastName')" prop="lastName" class="input-label">
          <el-input
            id="lastName"
            v-model="customer.lastName"
            :placeholder="t('placeholders.lastName')"
            maxlength="127"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>
      </input-group>

      <input-group>
        <el-form-item :label="t('businessName')" prop="businessName" class="input-label">
          <el-input
            id="businessName"
            v-model="customer.businessName"
            :placeholder="t('placeholders.businessName')"
            maxlength="127"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>
      </input-group>

      <input-group>
        <el-form-item :label="t('phone')" prop="phone" class="input-label">
          <el-input
            id="phone"
            v-model="customer.phone"
            :placeholder="t('placeholders.phone')"
            maxlength="15"
            dir="ltr"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('phone2')" prop="phone2" class="input-label">
          <el-input
            id="phone2"
            v-model="customer.phone2"
            :placeholder="t('placeholders.phone2')"
            maxlength="15"
            dir="ltr"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('email')" prop="email" class="input-label">
          <el-input
            id="email"
            v-model="customer.email"
            type="email"
            :placeholder="t('placeholders.email')"
            dir="ltr"
            maxlength="255"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('customerType')" prop="selectedLocationID" class="input-label">
          <el-select v-model="customer.customerType" filterable clearable class="w-full">
            <el-option
              v-for="type in customPriceTypes"
              :key="type"
              :label="$ctx.locale === 'en' ? type.enName : type.arName"
              :value="type.id"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">{{
                $ctx.locale === 'en' ? type.enName : type.arName
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </input-group>
      <el-form-item :label="t('dateOfBirth')" prop="dateOfBirth" class="input-label">
        <el-date-picker
          id="country"
          v-model="customer.dateOfBirth"
          type="date"
          format="DD-MM-YYYY"
          placeholder="Pick a date"
          style="width: 100%"
        />
      </el-form-item>
    </div>

    <div class="w-full">
      <h1>{{ t('sections.address') }}</h1>
      <el-form-item :label="t('streetAddress')" prop="streetAddress" class="input-label">
        <el-input
          id="streetAddress"
          v-model="customer.streetAddress"
          :placeholder="t('placeholders.streetAddress')"
          show-word-limit
          maxlength="255"
          clearable
        >
        </el-input>
      </el-form-item>

      <input-group>
        <el-form-item :label="t('city')" prop="city" class="input-label">
          <el-input
            id="city"
            :placeholder="t('placeholders.city')"
            maxlength="70"
            show-word-limit
            clearable
            v-model="customer.city"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('postalCode')" prop="postalCode" class="input-label">
          <el-input
            id="postalCode"
            :placeholder="t('placeholders.postalCode')"
            show-word-limit
            maxlength="10"
            clearable
            v-model="customer.postalCode"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('country')" prop="country" class="input-label">
          <el-input
            id="country"
            :placeholder="t('placeholders.country')"
            maxlength="70"
            show-word-limit
            clearable
            v-model="customer.country"
          >
          </el-input>
        </el-form-item>
      </input-group>
    </div>
  </el-form>
</template>

<script lang="ts" setup>
import { ref, defineEmits, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { ElForm } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { useHTTP } from '@/plugins/http'

import AlertBadge from '@/components/AlertBadge.vue'
import HorizontalButton from '@/components/HorizontalButton.vue'
import InputSection from '@/components/form/InputSection.vue'
import InputGroup from '@/components/form/InputGroup.vue'
import Customer, { customerI18nMessages } from '@/models/customer/Customer'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { ElMessageBox } from 'element-plus'
import CustomPriceType from '@/models/inventory/CustomPrice'

const http = useHTTP()
const route = useRoute()
const router = useRouter()
const alertModel = useAlertModal()
const customPriceTypes = ref([] as Array<CustomPriceType>)

const emit = defineEmits(['success'])

const { t } = useI18n({
  messages: {
    en: {
      ...customerI18nMessages.en,
      phoneNumberAlreadyUsedMsg:
        'This phone number has been previously used. Are you sure you want to use it again?',
      sections: {
        customer: 'Customer Info',
        contact: 'Contcat Info',
        address: 'Address'
      },

      placeholders: {
        firstName: 'Example: Ahmed',
        lastName: 'Example: Al-Iraqi',
        phone: 'Example: 077011111111',
        phone2: 'Example: 077011111111',
        email: 'Example: info[at]hamza.com',
        streetAddress: 'Example: Next to Almansour Mall',
        city: 'Example: Baghdad',
        postalCode: 'Example: 10015',
        country: 'Example: Iraq',
        businessName: 'Example: Thecky'
      },
      created: 'Customer was created successfully'
    },
    ar: {
      ...customerI18nMessages.ar,
      phoneNumberAlreadyUsedMsg:
        'تم استخدام رقم الهاتف مسبقا, هل انت متأكد انك تريد استخدامه مرة اخرى؟',
      sections: {
        customer: 'عن الزبون',
        contact: 'معلومات الاتصال',
        address: 'العنوان'
      },

      placeholders: {
        firstName: 'مثلا: احمد',
        lastName: 'مثلا: العراقي',
        phone: 'مثلا: 077011111111',
        phone2: 'مثلا: 077011111111',
        email: `مثلا: info[at]hamaza.com`,
        streetAddress: 'مثلا: مجاور لمول المنصور',
        city: 'مثلا: بغداد',
        postalCode: 'مثلا: 10015',
        country: 'مثلا: العراق',
        businessName: 'مثلا: الذكي'
      },
      created: 'تم انشاءالزبون بنجاح'
    }
  }
})

const customer = ref(new Customer())
const customerForm = ref(null)
const error = ref({ title: '', body: '' })

const rules = {
  firstName: [{ max: 127, message: t('validation.maxLength', { len: 127 }) }],
  lastName: [{ max: 127, message: t('validation.maxLength', { len: 127 }) }],
  phone: [{ max: 15, message: t('validation.maxLength', { len: 15 }) }],
  phone2: [{ max: 15, message: t('validation.maxLength', { len: 15 }) }],
  email: [{ max: 255, message: t('validation.maxLength', { len: 255 }) }],
  streetAddress: [{ max: 255, message: t('validation.maxLength', { len: 255 }) }],
  city: [{ max: 70, message: t('validation.maxLength', { len: 70 }) }],
  postalCode: [{ max: 10, message: t('validation.maxLength', { len: 10 }) }],
  country: [{ max: 70, message: t('validation.maxLength', { len: 70 }) }]
}

async function submit(customer: Customer) {
  try {
    const newCustomer = await http.post(Customer.ENDPOINT, customer)
    emit('success', newCustomer)
  } catch (error) {
    return error
  }
}

onBeforeMount(async () => {
  const customPriceTypesResp = await http.get<CustomPriceType[]>(CustomPriceType.ENDPOINT)

  customPriceTypes.value = customPriceTypesResp.map((cat) => CustomPriceType.from(cat))

  const regularCustomPrice = new CustomPriceType()
  regularCustomPrice.id = 'regular'
  regularCustomPrice.enName = 'Regular'
  regularCustomPrice.arName = 'اعتيادي'

  customPriceTypes.value = [regularCustomPrice, ...customPriceTypes.value]

  if (!customer.value.customerType.length) customer.value.customerType = regularCustomPrice.id
})

async function confirmDuplicatedPhone() {
  const phones = [customer.value.phone, customer.value.phone2]

  const promises = phones.map((phone) =>
    http.get<Customer[]>(Customer.ENDPOINT + `?phone=${encodeURIComponent(phone)}`)
  )

  try {
    const results = await Promise.all(promises)

    for (let i = 0; i < results.length; i++) {
      const customers = results[i]
      if (customers.length > 0) {
        const matchingCustomer = customers.find((customerx) => customerx.id !== customer.value.id)
        if (matchingCustomer) {
          const confirmed = await ElMessageBox.confirm(t('phoneNumberAlreadyUsedMsg'), 'Warning', {
            confirmButtonText: t('OK'),
            cancelButtonText: t('Cancel'),
            type: 'warning'
          })

          return confirmed // Return true if confirmed, false if canceled
        }
      }
    }

    return true // Return true if no duplicates found
  } catch (error) {
    // Handle errors here
    console.error(error)
    return false // Return false in case of an error
  }
}

function clearForm() {
  const form = customerForm.value as typeof ElForm
  form.resetFields()
}

async function validateSubmit() {
  const form = customerForm.value as typeof ElForm
  form?.validate(async (valid: boolean) => {
    if (!valid) {
      document.getElementById('main')?.scrollTo({ top: 0 })
      return false
    }

    if (await confirmDuplicatedPhone()) {
      const resp = await submit(customer.value)

      if (resp) {
        error.value.title = resp?.title
        error.value.body = resp?.body
        document.getElementById('main')?.scrollTo({ top: 0 })
        return false
      } else {
        clearForm()
        alertModel.showSuccess({ title: t('created'), body: '' })
      }
      return true
    }
    return false
  })
}
</script>

<style></style>

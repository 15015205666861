<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/accounting/transactions')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-10"
        :prefix-icon="Search"
        v-model="searchTerm"
      />
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
      :rowBuffer="rowBuffer"
      :rowModelType="rowModelType"
      :cacheBlockSize="cacheBlockSize"
      :cacheOverflowSize="cacheOverflowSize"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :infiniteInitialRowCount="infiniteInitialRowCount"
      :maxBlocksInCache="maxBlocksInCache"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions, IGetRowsParams, ModuleRegistry, ProcessCellForExportParams } from 'ag-grid-community'

import { Transaction, TransactionsPaginated, transactionI18nMessages } from '@/models/accounting/Transaction'
import { useI18n } from 'vue-i18n'
import { formatPostgresDate, parseFormat } from '@/utils/date'
import { formatDinero } from '@/utils/money'
import { Search } from '@element-plus/icons-vue'
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { onBeforeMount } from 'vue'
import { ref } from 'vue'
ModuleRegistry.registerModules([InfiniteRowModelModule])

export default defineComponent({
  name: 'transactions',

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...transactionI18nMessages.en
        },
        ar: {
          ...transactionI18nMessages.ar
        }
      }
    })

    const rowBuffer = ref<any>(null)
    const rowSelection = ref<any>(null)
    const rowModelType = ref<any>(null)
    const cacheBlockSize = ref<any>(null)
    const cacheOverflowSize = ref<any>(null)
    const maxConcurrentDatasourceRequests = ref<any>(null)
    const infiniteInitialRowCount = ref<any>(null)
    const maxBlocksInCache = ref<any>(null)

    onBeforeMount(() => {
      rowBuffer.value = 0
      rowSelection.value = 'multiple'
      rowModelType.value = 'infinite'
      cacheBlockSize.value = 100
      cacheOverflowSize.value = 100
      maxConcurrentDatasourceRequests.value = 2
      infiniteInitialRowCount.value = 0
      maxBlocksInCache.value = 10
    })


    return {
      t,
      Search,
      rowBuffer,
      rowSelection,
      rowModelType,
      cacheBlockSize,
      cacheOverflowSize,
      maxConcurrentDatasourceRequests,
      infiniteInitialRowCount,
      maxBlocksInCache
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Transaction[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      // filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        field: '-',
        hide: true,
        lockVisible: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep'
        }
      },
      {
        headerName: this.t('transactionID'),
        field: 'externalID',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        minWidth: 200
      },
      {
        headerName: this.t('orderID'),
        field: 'referenceableExternalID'
      },
      {
        headerName: this.t('models.common.date'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr' },
        valueGetter: (params) => {
          const tx = params.node?.data as Transaction
          if (!tx) return ''
          if (tx.createdAt) {
            const createdAt = new Date(tx.createdAt)
            return formatPostgresDate(String(createdAt), 'DD/MM/YYYY')
          }
          return ''
        }
      },
      {
        headerName: this.t('models.common.time'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr' },
        valueGetter: (params) => {
          const tx = params.node?.data as Transaction
          if (!tx) return ''
          if (tx.createdAt) {
            const createdAt = new Date(tx.createdAt)
            return formatPostgresDate(String(createdAt), 'hh:mm A')
          }
          return ''
        }
      },
      {
        headerName: this.t('type'),
        field: 'receiptType',
        valueFormatter: (params) => {
          if(!params.value) return '';
          const tx = params.node?.data as Transaction
          return this.t(tx.receiptType)
        },
        cellStyle: ({ value }) => {
          const base = { textAlign: 'center', backgroundColor: 'transparent', color: '#000' }

          if (value == 'sale') {
            base['backgroundColor'] = '#52C750'
          } else if (value == 'return') {
            base['backgroundColor'] = '#DB4141'
            base['color'] = '#fff'
          } else {
            base['backgroundColor'] = '#E8FF5C'
          }

          return base
        }
      },
      {
        headerName: this.t('totalAmount'),
        field: 'amountAmount',
        valueFormatter: (params) => {
          if(!params.value) return '';
          const transaction = params.node?.data as Transaction
          return formatDinero(transaction.amountDinero)
        }
      },
      {
        headerName: this.t('customer'),
        valueGetter: (params) => {
          if(!params.data) return '';
          const tx = params.node?.data as any
          if (tx.customerInvoice.customer) {
            return `${tx.customerInvoice.customer?.firstName} ${tx.customerInvoice.customer?.lastName}`
          }
          return ''
        }
      },
      {
        headerName: this.t('user'),
        field: 'user.fullName',
        minWidth: 200
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterItems()
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()
      try {
        const data = await this.$http.get<Transaction[]>('/sales/transactions')
        this.rowData = data.map((transaction) => Transaction.from(transaction))
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      const dataSource = {
        rowCount: undefined,
        getRows: async (params: IGetRowsParams) => {
          this.gridApi?.showLoadingOverlay()

          let url = `/sales/transactions?startRow=${params.startRow}&endRow=${params.endRow}`
          if (params.sortModel.length) {
            url += `&colId=${params.sortModel[0].colId}&sort=${params.sortModel[0].sort}`
          }

          if (Object.keys(params.filterModel).length) {
            url += `&query=${params.filterModel['-'].filter}`
          }

          const res = await this.$http.get<TransactionsPaginated>(url)
          this.rowData = res.data.map((transaction) => Transaction.from(transaction))
          this.gridApi?.hideOverlay()

          const lastRow = res.total
          // call the success callback
          params.successCallback(this.rowData, lastRow)
        }
      }
      this.gridApi?.setGridOption('datasource', dataSource)
      this.gridApi?.setGridOption('serverSideSortOnServer', true)
      // await this.updateRowData()
    },

    async applyToSelected() {
      if (this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          this.gridApi?.exportDataAsCsv({
            onlySelected: true,
            processCellCallback: this.processCellForExport
          })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.exportSuccess')
          })

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    processCellForExport(params: ProcessCellForExportParams) {
      if (params.column.getColDef().headerName === 'Transaction Amount') {
        return params.node?.data.amountDinero ? formatDinero(params.node?.data.amountDinero) : ''
      } else {
        //if the cell doesn't belong to any of the above don't change it
        return params.value
      }
    },

    filterItems() {
      // this.gridApi?.setQuickFilter(this.searchTerm)
      this.gridApi?.setFilterModel({ '-': { filter: this.searchTerm } })
    },


    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.SALES_TRANSACTIONS_RECEIPT.replace(':transactionID', event.data.id)
      )
    }
  }
})
</script>

<style></style>

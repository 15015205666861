import UUIDBase from '../UUIDBase'
import Location from '@/models/company/Location'
import User from '@/models/user/User'
import Customer from '@/models/customer/Customer'
import { Register } from '@/models/company/Register'
import Item from '@/models/inventory/Item'
import ItemSerial from '@/models/inventory/ItemSerial'
import Dinero, { Currency, Dinero as DineroType } from 'dinero.js'
import { maxDinero } from '@/utils/money'
import { CustomerInvoice, CustomerInvoicePayment } from './CustomerInvoice'

export const i18nOrderMessages = {
  en: {
    pouchID: 'Pouch #',
    location: 'Location',
    user: 'Cashier',
    customer: 'Customer',
    register: 'Register',
    paymentType: 'Payment Type',
    totalIndividualDiscount: 'Total Individual Discounts',
    totalAdditionalDiscount: 'Additional Discount',
    total: 'Total',
    totalBeforeDiscounts: 'Total Before Discounts',
    itemName: 'Item Name',
    itemCode: 'Item Code',
    soldAtPrice: 'SoldAt price',
    quantity: 'Quantity',
    returnedQuantity: 'Returned Quantity',
    price: 'Price',
    itemTotal: 'Total',
    applyDiscount: 'Apply discount',
    currentDiscountValue: 'Current Discount Value',
    changeDiscountValue: 'Change Discount Value',
    discountType: 'Discount type',
    discountByPercent: 'Discount by percentage',
    discountByMonetaryValue: 'Discount by monetary value',
    discountValue: 'Discount Value',
    priceAfterDiscount: 'Price after discount',
    lenSerialNumbers: 'Number of Serial Numbers',
    typecredit: 'Credit',
    typedirect: 'Direct',
    typelayaway: 'Layaway',
    typeReturn: 'Return',
    typefinance: 'Finance',
    receiptNumber: 'Receipt Number',
    orderNumber: 'Order Number',
    paymentComplete: 'Payment Complete?',
    serialNumbers: 'Serial Numbers',
    initialToReturnAmount: 'Subtotal to return to customer',
    previouslyPaidAmount: 'Total payments made by customer',
    finalToReturnAmount: 'Total to return',
    isDelivered: 'Delivered?',
    totalPaid: 'Total Paid',
    balance: 'Balance',
    type: 'Type'
  },
  ar: {
    pouchID: 'رقم المحفظة',
    location: 'الموقع',
    user: 'الكاشير',
    customer: 'الزبون',
    register: 'الخزانة',
    paymentType: 'نوع الطلب',
    totalIndividualDiscount: 'مجموع الخصوم الفردية',
    totalAdditionalDiscount: 'الخصومات الاضافية',
    total: 'المجموع الكلي',
    totalBeforeDiscounts: 'المجموع قبل الخصم',
    itemName: 'اسم المنتج',
    itemCode: 'كود المنتج',
    soldAtPrice: 'سعر البيع',
    quantity: 'الكمية',
    returnedQuantity: 'الكمية المرجعة',
    price: 'السعر',
    itemTotal: 'الاجمالي',
    applyDiscount: 'اضافة خصم',
    currentDiscountValue: 'قيمة الخصم الحالية',
    changeDiscountValue: 'تغيير قيمة الخصم',
    discountType: 'نوع الخصم',
    discountByPercent: 'خصم بالنسبة المؤوية',
    discountByMonetaryValue: 'خصم بالقيمة المالية',
    discountValue: 'قيمة الخصم',
    priceAfterDiscount: 'السعر بعد الخصم',
    lenSerialNumbers: 'عدد الارقام',
    typecredit: 'آجل',
    typedirect: 'مباشر',
    typelayaway: 'عربون',
    typeReturn: 'مسترجع',
    typefinance: 'بالدين',
    receiptNumber: 'رقم الفاتورة',
    orderNumber: 'رقم الطلب',
    paymentComplete: 'تم الدفع كاملا',
    serialNumbers: 'Serial Numbers',
    initialToReturnAmount: 'المبلغ الأولي للترجيع',
    previouslyPaidAmount: 'المبلغ المدفوع مسبقا من قبل الزبون',
    finalToReturnAmount: 'مبلغ الترجيع الاجمالي',
    isDelivered: 'تم التسليم؟',
    totalPaid: 'المبلغ المدفوع',
    type: 'النوع',
    balance: 'المتبقي'
  }
}

export class OrderPayment extends UUIDBase {
  static ENDPOINT = '/sales/orderpayments'
  orderID = ''
  externalID = ''
  paymentMethod = 'cash'

  currency = ''
  precision = 0

  totalDueAmount = 0
  totalDueDinero = Dinero()

  paidBeforeChangeAmount = 0
  paidBeforeChangeDinero = Dinero()

  changeDueAmount = 0
  changeDueDinero = Dinero()

  totalPaidAmount = 0
  totalPaidDinero = Dinero()

  locationID = ''
  location?: Location

  userID = ''
  user?: User

  registerID = ''
  register?: Register

  static from(json: Record<string, unknown> | OrderPayment) {
    const op = Object.assign(new OrderPayment(), json)

    if (op.location) op.location = Location.from(op.location)
    if (op.user) op.user = User.from(op.user)

    op.totalDueDinero = Dinero({
      amount: op.totalDueAmount,
      currency: op.currency as Currency,
      precision: op.precision
    })

    op.paidBeforeChangeDinero = Dinero({
      amount: op.paidBeforeChangeAmount,
      currency: op.currency as Currency,
      precision: op.precision
    })

    op.changeDueDinero = Dinero({
      amount: op.changeDueAmount,
      currency: op.currency as Currency,
      precision: op.precision
    })

    op.totalDueDinero = Dinero({
      amount: op.totalDueAmount,
      currency: op.currency as Currency,
      precision: op.precision
    })

    op.totalPaidDinero = Dinero({
      amount: op.totalPaidAmount,
      currency: op.currency as Currency,
      precision: op.precision
    })

    return op
  }
}

export class OrderStatus extends UUIDBase {
  orderID = ''
  status = ''
}

export class OrderItemSerial extends UUIDBase {
  orderItemID = ''
  itemSerialID: string
  itemSerial?: ItemSerial
  returned?: Date

  constructor(serialID: string) {
    super()
    this.itemSerialID = serialID
  }
}

export class OrderItem extends UUIDBase {
  constructor(item: Item) {
    super()
    this.item = item
    this.itemID = item.id || ''

    this.currency = item.salePriceCurrency as Currency
    this.precision = item.salePricePrecision || 0

    this.itemSalePriceAmount = item.salePriceAmount || 0
    this.itemSalePriceDinero = Dinero({
      amount: this.itemSalePriceAmount,
      currency: this.currency,
      precision: this.precision
    })

    this.itemCostAmount = item.costAmount || 0
    this.itemCostDinero = Dinero({
      amount: this.itemCostAmount,
      currency: this.currency,
      precision: this.precision
    })

    // discount
    this.individualDiscountDinero = Dinero({
      amount: 0,
      currency: this.currency,
      precision: this.precision
    })

    this.additionalDiscountDinero = Dinero({
      amount: 0,
      currency: this.currency,
      precision: this.precision
    })

    if (item.isSerialized) {
      this.orderItemSerials = new Array<OrderItemSerial>()
    }
  }

  static from(json: OrderItem | Record<string, unknown>) {
    const item = Item.from(json.item as Item)
    item.salePriceAmount = Number(json.itemSalePriceAmount || 0)
    const o = Object.assign(new OrderItem(item), json)
    // sold at is initial the same price
    o.soldAtPriceDinero = Dinero({
      amount: o.soldAtPriceAmount,
      currency: o.currency,
      precision: o.soldAtPricePrecision
    })
    return o
  }

  orderID = ''

  itemID = ''
  item: Item
  notes = ''

  currency = '' as Currency
  precision = 0

  itemSalePriceAmount = 0
  itemSalePriceDinero: DineroType

  itemCostAmount = 0
  itemCostDinero = Dinero()

  soldAtPriceAmount = 0
  soldAtPriceDinero?: DineroType
  soldAtPricePrecision = 0

  individualDiscountAmount = 0
  individualDiscountDinero: DineroType

  additionalDiscountAmount = 0
  additionalDiscountDinero: DineroType

  quantity = 1
  returnedQuantity = 0

  // cash or percent
  discountByPercent = true
  percentDiscountAmount = 0

  orderItemSerials = new Array<OrderItemSerial>()

  serialIDs = new Array<string>()

  customPriceID?: string

  includesItemSerialID(serialID: string) {
    return !!this.orderItemSerials?.some((serial) => serial.itemSerialID === serialID)
  }

  insertItemSerialID(id: string) {
    if (!this.includesItemSerialID(id)) {
      this.orderItemSerials.push(new OrderItemSerial(id))
    }
  }

  populateInventoryTransferItemSerials(itemSerialIDs?: string[]) {
    if (itemSerialIDs) {
      this.resetOrderItemSerials()
      itemSerialIDs.forEach((id) => this.insertItemSerialID(id))
    }
  }

  resetOrderItemSerials() {
    if (this.orderItemSerials) this.orderItemSerials.length = 0
  }

  get itemTotalWithoutDiscount() {
    return this.itemSalePriceDinero.multiply(this.quantity)
  }

  get itemTotalAfterIndividualDiscount() {
    return this.itemSalePriceDinero.subtract(this.individualDiscountDinero).multiply(this.quantity)
  }

  get itemTotal() {
    return this.soldAtPriceDinero!.multiply(this.quantity - this.returnedQuantity)
  }

  get itemReturnTotal() {
    return this.soldAtPriceDinero!.multiply(1)
  }

  updateSoldAtPrice() {
    this.soldAtPriceDinero = maxDinero(
      this.itemSalePriceDinero
        .subtract(this.individualDiscountDinero)
        .subtract(this.additionalDiscountDinero),
      0
    )

    this.soldAtPriceAmount = this.soldAtPriceDinero.getAmount()
  }

  applyDiscountByCash() {
    this.individualDiscountAmount = Math.min(
      Math.max(this.individualDiscountAmount, 0),
      this.itemSalePriceAmount
    )

    this.individualDiscountDinero = Dinero({
      amount: this.individualDiscountAmount,
      currency: this.currency,
      precision: this.precision
    })

    this.updateSoldAtPrice()
  }

  applyDiscountByPercent() {
    // value must be between 0 and 100
    this.percentDiscountAmount = Math.min(Math.max(this.percentDiscountAmount, 0), 100)

    // compute the cash amount
    this.individualDiscountDinero = this.itemSalePriceDinero
      .multiply(this.percentDiscountAmount)
      .divide(100)
    this.individualDiscountAmount = this.individualDiscountDinero.getAmount()

    this.applyDiscountByCash()
  }

  applyAdditionalDiscount(percent: number) {
    percent = Math.min(Math.max(percent, 0), 100)

    // compute cash amount
    this.additionalDiscountDinero = this.itemSalePriceDinero
      .subtract(this.individualDiscountDinero)
      .multiply(percent)
      .divide(100)

    this.additionalDiscountAmount = this.additionalDiscountDinero.getAmount()
    this.updateSoldAtPrice()
  }

  toJSON() {
    const copy = Object.assign({}, this) as Record<string, unknown>
    const ignoreItemFields = ['vendor', 'itemBrand', 'itemCategory', 'itemTags', 'itemStocks']
    for (const k in copy) {
      // clean unnecessary fields from payload
      if (k.toLowerCase().includes('dinero') || k in ignoreItemFields) {
        copy[k] = undefined
      }
    }

    return copy
  }
}

export class OrderChange extends UUIDBase {
  constructor(changeType: string) {
    super()
    this.changeType = changeType
  }

  static from(json: Record<string, unknown> | OrderChange) {
    const o = Object.assign(new OrderChange((json.changeType as string) || ''), json)

    if (o.location) o.location = Location.from(o.location)
    if (o.user) o.user = User.from(o.user)

    o.initialChangeDinero = Dinero({
      amount: o.initialChangeAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })

    o.previouslyPaidDinero = Dinero({
      amount: o.previouslyPaidAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })

    o.totalChangeDinero = Dinero({
      amount: o.totalChangeAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })

    return o
  }

  orderID = ''
  externalID = ''

  // return or exchange
  changeType: string

  locationID = ''
  location?: Location

  userID = ''
  user?: User

  registerID = ''
  register?: Register

  currency = '' as Currency
  precision = 0

  initialChangeAmount = 0
  initialChangeDinero?: DineroType

  previouslyPaidAmount = 0
  previouslyPaidDinero?: DineroType

  totalChangeAmount = 0
  totalChangeDinero?: DineroType

  transactionID = ''

  paidBackByCashTransactionID = ''
  paidBackByEpaymentTransactionID = ''

  orderChangeItems: Record<string, OrderChangeItem> = {}

  paidBackByCash = 0
  paidBackByEpayment = 0
}

export type OrderChangeItem = {
  quantity: number
  orderChangeItemSerials: Record<string, OrderChangeItemSerial>

  // used for UI purposes
  serialIDs: Array<string>
}

export type OrderChangeItemSerial = {}

export interface OrdersPaginated {
  data: Array<Order>,
  total: number
}
export class Order extends UUIDBase {
  static ENDPOINT = '/sales/orders'
  externalID = ''

  dueDate?: Date

  locationID = ''
  location?: Location

  userID = ''
  user?: User

  customerID = ''
  customer?: Customer

  registerID = ''
  register?: Register

  invoiceID = ''
  invoice = new CustomerInvoice()

  // one of direct|layaway|finance|partial
  paymentType: 'direct' | 'credit_sale' = 'direct'
  paymentComplete?: Date

  currency = '' as Currency
  precision = 0

  // total discounts = individual + additional
  discountByAmount = 0
  discountByPercentage = 0
  totalDiscountAmount = 0
  totalDiscountDinero?: DineroType

  totalDueAmount = 0
  totalBalanceAmount = 0
  paidBeforeChangeAmount = 0
  changeDueAmount = 0
  totalPaidAmount = 0
  paymentMethod?: string
  paymentNotes?: string

  discountType?: 'percentage' | 'amount'

  totalBeforeDiscountAmount = 0
  totalBeforeDiscountDinero?: DineroType

  totalAmount = 0
  totalDinero?: DineroType

  orderItems = new Array<OrderItem>()
  orderChanges = new Array<OrderChange>()

  isDelivered = false

  static from(json: Record<string, unknown> | Order) {
    const o = Object.assign(new Order(), json)
    if (o.location) o.location = Location.from(o.location)
    if (o.user) o.user = User.from(o.user)
    if (o.customer) o.customer = Customer.from(o.customer)
    if (o.register) o.register = Register.from(o.register)
    if (o.invoice) o.invoice = CustomerInvoice.from(o.invoice)
    o.orderItems = o.orderItems.map((oi) => OrderItem.from(oi))
    if (o.orderChanges) o.orderChanges = o.orderChanges.map((oc) => OrderChange.from(oc))

    o.totalDiscountDinero = Dinero({
      amount: o.totalDiscountAmount,
      currency: (o.invoice?.currency as Currency) || ('IQD' as Currency),
      precision: o.precision
    })

    o.totalBeforeDiscountDinero = Dinero({
      amount: o.totalBeforeDiscountAmount,
      currency: (o.invoice?.currency as Currency) || ('IQD' as Currency),
      precision: o.precision
    })

    o.totalDinero = Dinero({
      amount: o.totalAmount,
      currency: (o.invoice?.currency as Currency) || ('IQD' as Currency),
      precision: o.precision
    })

    o.currency = o.invoice?.currency as Currency
    return o
  }

  // initCurrenciesFromItem is used to avoid relying on context
  initCurrenciesFromItem(item: Item) {
    this.currency = item.salePriceCurrency as Currency
    this.precision = item.salePricePrecision || 0

    const emptyDinero = Dinero({
      amount: 0,
      currency: this.currency,
      precision: this.precision
    })

    this.totalDiscountDinero = Object.assign({}, emptyDinero)
    this.totalBeforeDiscountDinero = Object.assign({}, emptyDinero)
    this.totalDinero = Object.assign({}, emptyDinero)
  }

  includes(item: Item) {
    return this.indexOf(item) >= 0
  }

  indexOf(item: Item) {
    return this.orderItems.findIndex((oi) => oi.item?.id === item.id)
  }

  incrementQuantity(item: Item) {
    for (const oi of this.orderItems) {
      if (oi.item?.id === item.id) {
        oi.quantity++
      }
    }
  }

  findByBarcode(barcode: string): Item | undefined {
    for (const oi of this.orderItems) {
      if (oi.item?.barcode === barcode) {
        return oi.item
      }
    }
  }

  findBySKU(sku: string): Item | undefined {
    for (const oi of this.orderItems) {
      if (oi.item?.sku === sku) {
        return oi.item
      }
    }
  }

  addItem(item: Item) {
    // set currencies if this is the first item
    if (!this.orderItems.length) {
      this.initCurrenciesFromItem(item)
    }

    let oi
    if (this.includes(item)) {
      oi = this.orderItems[this.indexOf(item)]
      this.incrementQuantity(item)
    } else {
      // insert at index 0
      oi = new OrderItem(item)
      this.orderItems.splice(0, 0, oi)
    }

    return oi
  }

  removeItem(index: number) {
    this.orderItems.splice(index, 1)
  }

  empty() {
    return this.orderItems.length === 0
  }

  addCustomer(customer: Customer) {
    this.customer = customer
    this.customerID = customer.id || ''
  }

  computeTotal() {
    if (this.orderItems.length) {
      const total = this.orderItems.reduce((acc, item) => {
        return acc + item.itemSalePriceAmount * item.quantity
      }, 0)
      this.totalBeforeDiscountAmount = total
      const dinero = Dinero({
        amount: total,
        currency: (this.invoice?.currency as Currency) || 'IQD',
        precision: this.precision
      })

      return dinero

      // I won't delete these lines from here for the memory of the recursive error in checkout :)
      // const dineros = this.orderItems.map(oi =>
      //   oi.soldAtPriceDinero.multiply(oi.quantity - oi.returnedQuantity)
      // );

      // const currency = dineros[0].getCurrency();
      // const precision = dineros[0].getPrecision();
      // this.totalDinero = sumDineros(dineros, currency, precision);
      // this.totalAmount = this.totalDinero.getAmount();

      // return this.totalDinero;
    }
  }

  computeTotalAfterDiscount() {
    if (this.orderItems.length) {
      let totalPrice = 0
      let totalPriceForEligibleItems = 0
      for (const oi of this.orderItems) {
        totalPrice += oi.itemSalePriceAmount * (oi.quantity - oi.returnedQuantity)
        if (oi.item.discountEligible) {
          totalPriceForEligibleItems += oi.itemSalePriceAmount * (oi.quantity - oi.returnedQuantity)
        }
      }

      if (this.discountType === 'percentage') {
        const percentage = this.discountByPercentage / 100

        let finalTotalPrice = 0
        for (const oi of this.orderItems) {
          if (!oi.item.discountEligible) {
            finalTotalPrice += oi.itemSalePriceAmount * (oi.quantity - oi.returnedQuantity)
          } else {
            const oiPriceForOneItem = oi.itemSalePriceAmount
            const oiFinalPriceForOneItem = oiPriceForOneItem - oiPriceForOneItem * percentage
            const oiFinalPrice = Math.max(
              oiFinalPriceForOneItem * (oi.quantity - oi.returnedQuantity),
              0
            )
            finalTotalPrice += oiFinalPrice
          }
        }
        this.totalDiscountAmount = totalPrice - Math.round(finalTotalPrice)
        const dinero = Dinero({
          amount: Math.round(finalTotalPrice),
          currency: (this.invoice?.currency as Currency) || 'IQD',
          precision: this.precision
        })
        return dinero
      } else {
        const percentage = this.discountByAmount / totalPriceForEligibleItems

        let finalTotalPrice = 0
        for (const oi of this.orderItems) {
          if (!oi.item.discountEligible) {
            finalTotalPrice += oi.itemSalePriceAmount * (oi.quantity - oi.returnedQuantity)
          } else {
            const oiPriceForOneItem = oi.itemSalePriceAmount
            const oiFinalPriceForOneItem = oiPriceForOneItem - oiPriceForOneItem * percentage
            const oiFinalPrice = Math.max(
              oiFinalPriceForOneItem * (oi.quantity - oi.returnedQuantity),
              0
            )
            finalTotalPrice += oiFinalPrice
          }
        }
        this.totalDiscountAmount = totalPrice - Math.round(finalTotalPrice)
        if (isNaN(finalTotalPrice)) {
          finalTotalPrice = 0;
        }
        const dinero = Dinero({
          amount: Math.round(finalTotalPrice),
          currency: (this.invoice?.currency as Currency) || 'IQD',
          precision: this.precision
        })
        return dinero
      }
    }
    this.totalDiscountDinero = Dinero({
      amount: this.totalDiscountAmount,
      currency: (this.invoice?.currency as Currency) || 'IQD',
      precision: this.precision
    })
    this.totalDueAmount = this.totalBeforeDiscountAmount - this.totalDiscountAmount
    return Dinero({
      amount: this.totalDueAmount,
      currency: (this.invoice?.currency as Currency) || 'IQD',
      precision: this.precision
    })
  }

  computeTotalAfterDiscountOld() {
    if (this.orderItems.length) {
      if (this.discountType === 'percentage') {
        const beforeDiscount = this.totalBeforeDiscountAmount
        const totalAfterDiscount = Math.floor(
          beforeDiscount - (beforeDiscount * this.discountByPercentage) / 100
        )

        this.totalDiscountAmount = this.totalBeforeDiscountAmount - totalAfterDiscount
        const dinero = Dinero({
          amount: totalAfterDiscount,
          currency: (this.invoice?.currency as Currency) || 'IQD',
          precision: this.precision
        })
        return dinero
      } else {
        const beforeDiscount = this.totalBeforeDiscountAmount
        const totalAfterDiscount = beforeDiscount - this.discountByAmount

        this.totalDiscountAmount = this.totalBeforeDiscountAmount - totalAfterDiscount
        const dinero = Dinero({
          amount: totalAfterDiscount,
          currency: (this.invoice?.currency as Currency) || 'IQD',
          precision: this.precision
        })
        return dinero
      }
    }
    this.totalDiscountDinero = Dinero({
      amount: this.totalDiscountAmount,
      currency: (this.invoice?.currency as Currency) || 'IQD',
      precision: this.precision
    })
    this.totalDueAmount = this.totalBeforeDiscountAmount - this.totalDiscountAmount
    return Dinero({
      amount: this.totalDueAmount,
      currency: (this.invoice?.currency as Currency) || 'IQD',
      precision: this.precision
    })
  }

  getOrderItemForPayload() {
    return this.orderItems.map((oi) => {
      const orderItemSerials = oi.item.isSerialized
        ? oi.serialIDs.map((serialId) => {
            return {
              itemSerialID: serialId
            }
          })
        : []
      if (orderItemSerials.length) {
        return {
          itemID: oi.item.id,
          quantity: oi.quantity,
          notes: oi.notes,
          orderItemSerials,
          customPriceID: oi.customPriceID
        }
      } else {
        return {
          itemID: oi.itemID,
          quantity: oi.quantity,
          notes: oi.notes,
          itemSalePriceAmount: oi.itemSalePriceAmount,
          customPriceID: oi.customPriceID
        }
      }
    })
  }

  preparePayload(user: User, register: Register, location: Location) {
    if (this.paymentType === 'direct') {
      this.totalDueAmount = this.totalBeforeDiscountAmount - this.totalDiscountAmount
      this.changeDueAmount = this.paidBeforeChangeAmount - this.totalDueAmount
      this.totalPaidAmount = this.paidBeforeChangeAmount - this.changeDueAmount
      this.totalBalanceAmount = this.totalDueAmount - this.totalPaidAmount
    } else {
      this.totalDueAmount = this.totalBeforeDiscountAmount - this.totalDiscountAmount
      this.changeDueAmount = 0
      this.totalPaidAmount = this.paidBeforeChangeAmount
      this.totalBalanceAmount = this.totalDueAmount - this.totalPaidAmount
    }
    this.userID = user.id || ''
    this.registerID = register.id || ''
    this.locationID = location.id || ''
    this.invoice = {
      pouchID: this.invoice.pouchID,
      customerID: this.customerID,
      billDueDate: new Date(),
      currency: this.currency,
      precision: this.precision,
      totalBeforeDiscounts: this.totalBeforeDiscountAmount,
      totalDiscountAmount: this.totalDiscountAmount,
      totalDueAmount: this.totalDueAmount,
      totalBalanceAmount: this.totalBalanceAmount,
      paymentType: this.paymentType,
      customerInvoicePayments: [
        {
          currency: this.currency,
          precision: this.precision,
          totalDueAmount: this.totalDueAmount,
          paidBeforeChangeAmount: this.paidBeforeChangeAmount,
          changeDueAmount: this.changeDueAmount,
          totalPaidAmount: this.totalPaidAmount,
          paymentMethod: this.paymentMethod,
          notes: this.paymentNotes
        } as CustomerInvoicePayment
      ],
      transactions: [],
      reqOnlyTotalCashPaidAmount: this.invoice.reqOnlyTotalCashPaidAmount,
      reqOnlyTotalEpaymentAmount: this.invoice.reqOnlyTotalEpaymentAmount,
      notes: this.invoice.notes
    }

    return {
      userID: this.userID,
      registerID: this.registerID,
      locationID: this.locationID,
      invoice: this.invoice,
      orderItems: this.getOrderItemForPayload(),
      orderChanges: null
    }
  }

  computeTotalPaid() {
    let totalPaid = 0
    for (const tx of this.invoice?.transactions) {
      if (tx.isReceipt && (tx.receiptType == 'sale' || tx.receiptType == 'payment')) {
        totalPaid += tx.amountAmount!
      }

      if (tx.receiptType == 'return') {
        totalPaid -= tx.amountAmount!
      }
    }

    return Dinero({
      amount: totalPaid,
      currency: (this.invoice?.currency as Currency) || 'IQD',
      precision: this.precision
    })
  }

  // in case of finance or layaway
  computePaymentRemaining() {
    this.totalBalanceAmount = this.totalDueAmount! - this.totalPaidAmount!
    return Dinero({
      amount: this.totalBalanceAmount,
      currency: (this.invoice?.currency as Currency) || 'IQD',
      precision: this.precision
    })
  }
}

<template>
  <div
    class="flex flex-col items-center mx-4 mt-3 px-2"
    id="small-receipt"
    :class="{
      'dir-ltr': $ctx.receiptLanguage == 'en',
      'dir-rtl': $ctx.receiptLanguage == 'ar'
    }"
  >

    <div
      class="flex flex-col items-center w-full"
      style="height: 100vh;"
      v-for="transaction in transactions"
      :key="transaction.id"
    >
      <!-- header info -->
      <div id="small-receipt-header" class="flex flex-col w-full">
        <div class="flex mt-6 justify-center w-full">
          <img src="@/assets/images/logo.png" width="250" alt="" />
        </div>
      </div>

      <div id="small-receipt-body" class="w-full">
        <!-- title -->
        <div class="flex flex-col w-full mt-2 items-center">
          <h2 class="bg-gray-200 rounded py-1 px-2 font-bold mt-3 capitalize">
            <p v-if="transaction.receiptType == 'sale'">
              {{ receiptTranslate("Sales Receipt", "receipt.salesReceipt") }}
            </p>
            <p v-if="transaction.receiptType == 'payment'">
              {{
                receiptTranslate("Payment Receipt", "receipt.paymentReceipt")
              }}
            </p>
            <p v-if="transaction.receiptType == 'return'">
              {{
                receiptTranslate("Return Receipt", "receipt.returnReceipt")
              }}
            </p>
          </h2>
        </div>

        <div class="flex flex-col px-5 items-center">
          <div>
            <svg :id="`barcode-${transaction.externalID}`" class="mt-3"></svg>
            <div class="flex items-center justify-center">
              <span class="font-bold mx-5" style="font-size: 20px;">
                {{ transaction.externalID.slice(0, 5) }}-{{
                  transaction.externalID.slice(5)
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex w-full px-5 mt-4">
          <div>
            <table class="w-full text-sm">
              <tr>
                <td>
                  <p>{{ receiptTranslate("Type", "receipt.type") }}</p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    <span v-if="order?.invoice.paymentType == 'direct'">{{
                        receiptTranslate("Direct", "receipt.direct")
                      }}</span>
                    <span v-if="order?.invoice.paymentType == 'credit_sale'">{{
                        receiptTranslate("Credit", "receipt.credit")
                      }}</span>
                    <span v-if="order?.invoice.paymentType == 'layaway'">{{
                        receiptTranslate("Layaway", "receipt.layaway")
                      }}</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Customer", "receipt.customer") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    {{
                      order.invoice.customer?.contactDisplayName
                        ? `${order.invoice.customer?.contactDisplayName} ${
                          order.invoice.customer?.businessName
                            ? " - " + order.invoice.customer?.businessName
                            : ""
                        }`
                        : receiptTranslate(
                          "Cash Customer",
                          "receipt.cashCustomer"
                        )
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Location", "receipt.location") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    {{ order?.location?.enName || "" }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Order No", "receipt.orderNo") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">{{ order?.externalID }}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Cashier", "receipt.cashier") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    {{ transaction.user?.fullName }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Date & Time", "receipt.dateAndTime") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5" dir="ltr">
                    {{ parseFormat(order?.createdAt) }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{
                      receiptTranslate("Payment Notes", "receipt.paymentNotes")
                    }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5" dir="ltr">
                    {{ transaction.description }}
                  </p>
                </td>
              </tr>
            </table>
          </div>

          <div class="flex flex-col items-center">
            <div
              v-if="order?.invoice.pouchID"
              class="flex flex-col w-full mt-2 items-center"
            >
              <h2 class="bg-gray-200 rounded py-1 px-2 flex">
                <span>{{ receiptTranslate("Pouch #", "receipt.pouch") }}</span>
                <span class="flex justify-end custom-bold mx-2">
                  {{ order?.invoice.pouchID }}
                </span>
              </h2>
            </div>
          </div>
        </div>

        <!-- payment info -->
        <div class="text-sm flex flex-col w-full">
          <div class="flex-col">
            <div class="w-full p-2"></div>

            <div class="px-4 w-full mt-2">
              <div>
                <table
                  class="payment-table"
                  :class="{ 'ar-receipt': $ctx.receiptLanguage == 'ar' }"
                >
                  <tr>
                    <td>
                      <p class="p-0">
                        {{
                          receiptTranslate("Payment ID", "receipt.paymentID")
                        }}: {{ transaction.externalID }}
                      </p>
                    </td>
                    <td>
                      <p class="text-right">
                        {{ parseFormat(transaction.createdAt) }}
                      </p>
                    </td>
                  </tr>
                </table>

                <table
                  class="payment-table"
                  :class="{ 'ar-receipt': $ctx.receiptLanguage == 'ar' }"
                >
                  <tr v-if="transaction.receiptType != 'return'">
                    <td>
                      {{
                        receiptTranslate(
                          "Payment Method",
                          "receipt.paymentMethod"
                        )
                      }}
                    </td>
                    <td>
                      <p class="text-right">
                        {{
                          transaction.paymentMethod === "debit-card"
                            ? "E-Payment"
                            : "Cash"
                        }}
                      </p>
                    </td>
                  </tr>

                  <tr
                    v-if="
                      transaction.receiptType != 'return' &&
                        transaction.paymentMethod === 'cash'
                    "
                  >
                    <td>{{ receiptTranslate("Cash", "receipt.cash") }}</td>
                    <td>
                      <p class="text-right">
                        {{ formatDinero(transaction.paidBeforeChangeDinero) }}
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      transaction.receiptType != 'return' &&
                        transaction.paymentMethod === 'cash'
                    "
                  >
                    <td>
                      {{ receiptTranslate("Change Due", "receipt.changeDue") }}
                    </td>
                    <td>
                      <p class="text-right">
                        {{ formatDinero(transaction.changeDueDinero) }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ receiptTranslate("Total Paid", "receipt.totalPaid") }}
                    </td>
                    <td>
                      <p
                        v-if="transaction.receiptType != 'return'"
                        class="text-right"
                      >
                        {{ formatDinero(transaction.amountDinero) }}
                      </p>
                      <p
                        v-if="transaction.receiptType == 'return'"
                        class="text-right"
                      >
                        ({{ formatDinero(transaction.amountDinero) }})
                      </p>
                    </td>
                  </tr>
                  <tr v-if="transaction.receiptType != 'return'">
                    <td>
                      {{ receiptTranslate("Balance", "receipt.balance") }}
                    </td>
                    <td>
                      <p class="text-right">
                        {{ formatDinero(transaction.balanceDinero) }}
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        id="small-receipt-footer"
        class="flex justify-center self-center w-full mt-auto"
      >
        <div
          class="flex items-center justify-between pt-2 px-4 mt-2 mb-4"
          style="background: #EFEFEF; bottom: 0px; width: 95%;"
        >
          <p><img src="@/assets/images/print_logo.png" width="80" /></p>
          <p class="thecky-site">thecky.com</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero, formatMoney } from "@/utils/money";
import { Transaction } from "@/models/accounting/Transaction";
import JsBarcode from "jsbarcode";
import transaction from "../../accounting/transactions/Transaction.vue";

export default defineComponent({
  computed: {
    transaction() {
      return transaction;
    }
  },
  components: {},

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en
        },
        ar: {
          ...i18nOrderMessages.ar,
          receipt: {
            salesReceipt: "فاتورة بيع",
            paymentReceipt: "إيصال الدفع",
            returnReceipt: "إيصال الارجاع",
            type: "نوع الطلب",
            direct: "مباشر",
            credit: "آجل",
            layaway: "عربون",
            customer: "الزبون",
            cashCustomer: "زبون",
            location: "المتجر",
            orderNo: "رقم الطلب",
            cashier: "موظف الكاشير",
            dateAndTime: "التاريخ والوقت",
            orderNotes: "ملاحظات الطلب",
            itemName: "اسم المنتج",
            qty: "الكمية",
            price: "السعر",
            finalPrice: "السعر النهائي",
            totalBeforeDiscounts: "المبلغ الكلي قبل التخفيض",
            totalDiscount: "المبلغ الكلي للتخفيض",
            totalDue: "الاجمالي",
            totalItemsSold: "عدد المنتجات المباعة",
            paymentsInfo: "تفاصيل المدفوعات",
            paymentID: "رقم الحركة",
            paymentMethod: "طريقة الدفع",
            cash: "كاش",
            changeDue: "المتبقي",
            totalPaid: "المبلغ المدفوع الكلي",
            balance: "الرصيد",
            paymentNotes: "ملاحظات الحركة"
          }
        }
      }
    });

    return { t };
  },
  props: {
    order: {
      type: Order,
      required: true
    },
    transactions: {
      type: Array as PropType<Array<Transaction>>,
      required: true
    },
    barcodeText: {
      type: String,
      required: true
    }
  },
  mounted() {
    for (const transaction of this.transactions) {
      setTimeout(() => {
        JsBarcode(
          `#barcode-${transaction.externalID}`,
          String(transaction.externalID!.slice()),
          {
            height: 50,
            displayValue: false
          }
        );
      }, 100);
    }
  },
  created() {
    // Add print CSS when component is created
    this.addPrintCSS();
  },
  beforeUnmount() {
    // Remove print CSS when component is about to be destroyed
    this.removePrintCSS();
  },
  methods: {
    parseFormat,
    formatDinero,
    formatMoney,
    truncateItemName(itemName: string) {
      const maxLength = Math.min(itemName.length, 40);
      return maxLength >= 40
        ? `${itemName.substring(0, maxLength)}...`
        : itemName;
    },
    print() {
      window.print();
    },
    addPrintCSS() {
      // Create a style element with a unique id
      const style = document.createElement("style");
      style.setAttribute("id", "print-css");
      style.textContent = `
          @media print {
            @page:first {
              size: A4;
              margin-left: 0;
              margin-right: 0;
              margin-top: 0px;
            }
    
            @page {
              size: A4;
              margin-left: 0;
              margin-right: 0;
              margin-top: 40px;
              margin-bottom: 30px;
            }
          }`;
      // Append the style to the document head
      document.head.appendChild(style);
    },
    removePrintCSS() {
      // Find and remove the style element
      const style = document.querySelector("#print-css");
      if (style && style.parentNode) {
        style.parentNode.removeChild(style);
      }
    },
    receiptTranslate(value: string, translateKey: string): string {
      return this.$ctx.receiptLanguage == "en"
        ? value
        : this.t(translateKey, this.$ctx.receiptLanguage);
    }
  }
});
</script>
<style scoped>
.thecky-site {
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
}

#small-receipt {
  width: 100mm;
  overflow: scroll;
  background-color: #fff;
}

.small-account-statement-table table {
  table-layout: fixed;
  /* width: 200px; */
  border-collapse: collapse;
}

.small-account-statement-table tr:not(:last-child) {
  border-bottom: 1pt solid #bbbbbb;
}

.small-account-statement-table td {
  padding-top: 4px;
  padding-bottom: 2px;
  border-bottom: 1pt solid #bbbbbb;
}

.small-account-statement-table th,
td {
  font-size: 12px;
  width: auto;
  overflow: hidden;
}

.small-account-statement-table td:nth-child(1) {
  width: auto;
  min-width: 60px;
  overflow: hidden;
}

.small-account-statement-table td:nth-child(2) {
  width: auto;
  min-width: 130px;
  overflow: hidden;
}

.small-account-statement-table td:nth-child(3) {
  width: auto;
  min-width: 55px;
  overflow: hidden;
}

.small-account-statement-table td:nth-child(4) {
  width: auto;
  min-width: auto;
  overflow: hidden;
}

.header-table td:nth-child(1) {
  width: 120px;
}

.header-table td:nth-child(2) {
  width: 300px;
}

#small-receipt-header {
  margin-top: 10px;
}

#small-receipt-body {
  margin-top: 10px;
}

@media print {
  body * {
    page-break-after: avoid;
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  #small-receipt,
  #small-receipt * {
    visibility: visible;
  }

  #small-receipt {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    margin: 0cm;
  }

  #small-receipt-header {
    margin-top: 0;
  }

  #small-receipt-body {
    position: relative;
  }

  p,
  div {
    page-break-inside: avoid;
  }
}
</style>

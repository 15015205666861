<script setup lang="ts">
import Item, { itemI18nMessages } from '@/models/inventory/Item'
import { useHTTP } from '@/plugins/http'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Route as $Route } from '@/router/routes'
import { useContext } from '@/plugins/context'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'

const messages = {
  en: {
    ...itemI18nMessages.en,

    sections: {
      itemInfo: 'Item Info',
      pricing: 'Pricing',
      options: 'Item options',
      stockInfo: 'Stock Info',
      descriptiveInfo: 'Descriptive Info',
      generalInfo: 'Item Info'
    },

    placeholders: {
      item: 'Example: backpack',
      description: 'Example: backpack with smooth fabric and life warranty',
      price: 'Example: 15000',
      cost: 'Example: 5000',
      barcode: 'Example: 1339620142715',
      sku: 'Example: 2392',
      dragDrop: 'Drop file here or click to upload',
      fileTypes: 'jpg/png files with a size less than 500kb'
    },

    select: {
      skipVendor: 'Skip vendor',
      skipBrand: 'Skip Brand',
      skipCategory: 'Skip Category'
    },

    tooltips: {
      cost: 'Cost is used in accounting',
      serialized: 'This feature is used to track items individually',
      unlimitedStock: 'Services have unlimited stock',
      discountEligible: 'Sales reps can apply discount at checkout',
      barcode: 'Must be unique',
      sku: 'Must be unique, used for internal tracking purposes',
      tags: 'To select more than one tag, click Ctrl'
    },

    createItem: 'Save Item',
    location: 'Location',
    serialNumbers: 'Serial Numbers',
    stockQuantity: 'Stock Quanity'
  },

  ar: {
    ...itemI18nMessages.ar,

    sections: {
      itemInfo: 'معلومات المنتج',
      pricing: 'التسعيير',
      options: 'اعدادات المنتج',
      stockInfo: 'بيانات التخزين',
      descriptiveInfo: 'البيانات الوصفية',
      generalInfo: 'معلومات المنتج'
    },

    placeholders: {
      item: 'مثلا: حقيبة ظهر',
      description: 'مثلا: حقيبة ظهر بخام ناعم',
      price: 'مثلا: 15000',
      cost: 'مثلا: 5000',
      barcode: 'مثلا: 1339620142715',
      sku: 'مثلا: 2392',
      dragDrop: 'اسحب ملف الصورة هنا أو اضغط لاختيار ملف',
      fileTypes: 'الملفات المدعومة: jpeg png'
    },

    select: {
      skipVendor: 'تخطي الموزع',
      skipBrand: 'تخطي العلامة التجارية',
      skipCategory: 'تخطي المجموعة'
    },

    tooltips: {
      cost: 'يستخدم في حسابات الارباح وغيرها من التقارير',
      serialized: 'يستخدم الترقيم في متابعة المنتجات بشكل فردي',
      unlimitedStock: 'مثلا: الخدمات لاتخضع للمخزون',
      discountEligible: 'يمكن لمندوب المبيعات ان يقوم بتخفيض المنتجات عند البيع',
      barcode: 'يجب ان يكون فريدا',
      sku: 'يجب ان يكون فريدا - يستخدم كرقم تخزين داخلي',
      tags: 'اضغط على ctrl لاختيار اكثر  من وسم'
    },

    createItem: 'حفظ المنتج',
    location: 'الموقع',
    serialNumbers: 'Serial Numbers',
    stockQuantity: 'الكمية'
  }
}

const $route = useRoute()
const $http = useHTTP()
const $ctx = useContext()
const { t } = useI18n({ messages, useScope: 'global' })

const id = $route.params.id as string

const item = ref<Item | undefined>()

// const stockInfo = computed(() => {
//   return item.value?.itemStocks.map(stock => {
//     // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//     // @ts-ignore
//     const location = stock.location[
//       $ctx.locale === "ar" ? "arName" : "enName"
//     ] as string;
//     // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//     // @ts-ignore
//     const quantity = stock.quantity;

//     return { location, quantity };
//   });
// });
const editUrl = computed(() => {
  return $Route.INVENTORY_ITEMS_SIMPLE_ITEM.replace(':id', id as string)
})

async function loadItem() {
  const url = `${Item.ENDPOINT}/${id}`
  const _item = await $http.get<Item>(url)
  if (_item) item.value = Item.from(_item)
}

onMounted(async () => {
  loadItem()
})
</script>

<template>
  <div class="grid grid-cols-3 pt-16 mx-16">
    <!-- item detail -->
    <div class="space-y-8 col-span-2">
      <!-- item name -->
      <div class="" v-if="item?.name">
        <div>
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('name') }}</p>
          <!-- value -->
          <p class="text-lg">{{ item?.name }}</p>
        </div>
      </div>

      <!-- item list price -->
      <div class="flex flex-wrap gap-16">
        <div v-if="item?.listPriceAmount">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('listPriceAmount') }}</p>
          <!-- value -->
          <p class="text-lg">{{ $ctx.currency }} {{ item?.listPriceAmount }}</p>
        </div>

        <!-- item sale price  -->
        <div v-if="item?.salePriceAmount">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('salePriceAmount') }}</p>
          <!-- value -->
          <p class="text-lg">{{ $ctx.currency }} {{ item?.salePriceAmount }}</p>
        </div>

        <!-- item wholesale price -->
        <div v-if="item?.wholesalePriceAmount">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('wholesalePriceAmount') }}</p>
          <!-- value -->
          <p class="text-lg">{{ $ctx.currency }} {{ item?.wholesalePriceAmount }}</p>
        </div>
        <div v-if="item?.costAmount">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('cost') }}</p>
          <!-- value -->
          <p class="text-lg">{{ $ctx.currency }} {{ item?.costAmount }}</p>
        </div>
      </div>

      <div class="flex flex-wrap gap-16">
        <div class="flex gap-4">
          <input type="checkbox" id="serialized" :checked="item?.isSerialized" disabled />
          <label for="serialized">{{ t('isSerialized') }}</label>
        </div>
        <div class="flex gap-4">
          <input type="checkbox" id="unlimited-stock" :checked="item?.unlimitedStock" disabled />
          <label for="unlimited-stock">{{ t('unlimitedStock') }}</label>
        </div>
        <div class="flex gap-4">
          <input type="checkbox" id="sell-out-of-stock" :checked="item?.sellOutOfStock" disabled />
          <label for="sell-out-of-stock">{{ t('sellOutOfStock') }}</label>
        </div>
        <div class="flex gap-4">
          <input
            type="checkbox"
            id="discount-eligible"
            :checked="item?.discountEligible"
            disabled
          />
          <label for="discount-eligible">{{ t('discountEligible') }}</label>
        </div>

        <div class="flex gap-4">
          <input type="checkbox" id="item-disabled" :checked="item?.disabled" disabled />
          <label for="item-disabled">{{ t('disabled') }}</label>
        </div>
      </div>
      <div class="flex flex-wrap gap-16">
        <div v-if="item?.barcode">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('barcode') }}</p>
          <!-- value -->
          <p class="text-lg">{{ item?.barcode }}</p>
        </div>
        <div v-if="item?.sku">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('sku') }}</p>
          <!-- value -->
          <p class="text-lg">{{ item?.sku }}</p>
        </div>
      </div>
      <div class="flex flex-wrap gap-16">
        <div v-if="item?.description">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('description') }}</p>
          <!-- value -->
          <p class="text-lg">{{ item?.description }}</p>
        </div>
      </div>
      <div class="flex flex-wrap gap-16">
        <div v-if="item?.vendor">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('vendor') }}</p>
          <!-- value -->
          <p class="text-lg">{{ item?.vendor.name }}</p>
        </div>
        <div v-if="item?.itemBrand">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('itemBrand') }}</p>
          <!-- value -->
          <p class="text-lg">{{ item?.itemBrand.name }}</p>
        </div>
      </div>
      <div class="flex flex-wrap gap-16">
        <div v-if="item?.vendor">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('itemCategory') }}</p>
          <!-- value -->
          <p class="text-lg capitalize">{{ item?.itemCategory.name }}</p>
        </div>
      </div>
      <div class="flex flex-wrap gap-16">
        <div v-if="item?.itemTags">
          <!-- label -->
          <p class="text-sm text-gray-500">{{ t('itemTags') }}</p>
          <!-- value -->
          <p class="text-lg flex gap-2">
            <span
              class="font-bold capitalize bg-blue-600 hover:bg-blue-700 text-white p-1 rounded-md shadow-md cursor-pointer hover:shadow-lg duration-200 transition-all"
              v-for="tag in item?.itemTags.map((tag) => tag.name)"
              :key="tag"
              >{{ tag }}</span
            >
          </p>
        </div>
      </div>
    </div>

    <!-- item more info -->
    <div class="col-span-1 space-y-8">
      <div class="flex justify-end">
        <router-link :to="editUrl">
          <font-awesome-icon icon="edit" />
        </router-link>
      </div>

      <div class="flex flex-col justify-start px-16 text-lg">
        {{ t('sections.stockInfo') }}
        <el-table :data="item?.itemStocks" style="width: 100%">
          <el-table-column type="expand" v-if="item?.isSerialized">
            <template #default="scope">
              {{ t('serialNumbers') }}
              <li v-for="(serial, i) in scope.row.itemSerials" :key="i">
                <el-tag :key="i" type="info">{{ serial.number }}</el-tag>
              </li>
            </template>
          </el-table-column>
          <el-table-column
            :label="t('location')"
            :prop="$ctx.getDir() === 'rtl' ? 'location.arName' : 'location.enName'"
          >
          </el-table-column>
          <el-table-column :label="t('stockQuantity')" prop="quantity"> </el-table-column>
        </el-table>
      </div>

      <div class="w-full h-px border-b border-black max-w-sm mx-auto"></div>

      <div class="flex flex-col justify-start px-16 text-lg">
        {{ t('sections.generalInfo') }}
        <el-timeline>
          <el-timeline-item key="createdAt" :timestamp="item?.formatCreatedAt()">
            {{ $t('models.common.createdAt') }}
          </el-timeline-item>
          <el-timeline-item key="updatedAt" :timestamp="item?.formatUpdatedAt()">
            {{ $t('models.common.updatedAt') }}
          </el-timeline-item>
        </el-timeline>
      </div>

      <!-- <div class="w-full h-px border-b border-black max-w-sm mx-auto"></div> -->

      <!-- <div class="flex flex-col justify-start px-16 text-lg">
        Sales History
        <ul class="list-disc">
          <li>
            <span class=" text-gray-500 mr-4">Today's Sales</span>
            <span>{{  }}</span>
          </li>

          <li>
            <span class=" text-gray-500 mr-3">Updated on</span>
            <span>{{ dayjs(item?.updatedAt).format("DD/MM/YYYY") }}</span>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<template>
  <div class="flex justify-center flex-col items-center w-full">
    <div v-if="$ctx.currency === 'IQD'" class="money mt-4">
      <div class="flex justify-between">
        <img
          class="w-4/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/250.webp"
          alt="250 iqd"
          @click="addToPaidAmount(250)"
        />
        <img
          class="w-4/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/500.webp"
          alt="500 iqd"
          @click="addToPaidAmount(500)"
        />
        <img
          class="w-4/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/1000.webp"
          alt="1000 iqd"
          @click="addToPaidAmount(1000)"
        />
      </div>

      <div class="flex justify-between mt-4">
        <img
          class="w-6/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/5000.webp"
          alt="5000 iqd"
          @click="addToPaidAmount(5000)"
        />
        <img
          class="w-6/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/10.webp"
          alt="10000 iqd"
          @click="addToPaidAmount(10000)"
        />
      </div>

      <div class="flex justify-between mt-4">
        <img
          class="w-6/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/25.webp"
          alt="25000 iqd"
          @click="addToPaidAmount(25000)"
        />
        <img
          class="w-6/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/50.webp"
          alt="50000 iqd"
          @click="addToPaidAmount(50000)"
        />
      </div>
    </div>

    <div v-if="$ctx.currency === 'USD'" class="money mt-4">
      <div class="flex justify-between">
        <img
          class="w-4/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/1usd.webp"
          alt="250 iqd"
          @click="addToPaidAmount(1)"
        />
        <img
          class="w-4/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/2usd.webp"
          alt="500 iqd"
          @click="addToPaidAmount(2)"
        />
        <img
          class="w-4/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/5usd.webp"
          alt="1000 iqd"
          @click="addToPaidAmount(5)"
        />
      </div>

      <div class="flex justify-between mt-4">
        <img
          class="w-6/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/10usd.webp"
          alt="5000 iqd"
          @click="addToPaidAmount(10)"
        />
        <img
          class="w-6/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/20usd.webp"
          alt="10000 iqd"
          @click="addToPaidAmount(20)"
        />
      </div>

      <div class="flex justify-between mt-4">
        <img
          class="w-6/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/50usd.webp"
          alt="25000 iqd"
          @click="addToPaidAmount(50)"
        />
        <img
          class="w-6/12 cursor-pointer px-2"
          src="../../../assets/paperMoney/100usd.webp"
          alt="50000 iqd"
          @click="addToPaidAmount(100)"
        />
      </div>
    </div>

    <div class="flex flex justify-between items-center my-3 text-primary w-full">
      <h1 class="2xl:text-l lg:text-l text-m">{{ t('cashReceived2') }}</h1>
      <div class="line border w-1/3 mx-2"></div>
      <h1 v-if="currency !== ''" class="2xl:text-l lg:text-l text-m">
        {{ formatMoney(paidBeforeChange, currency) }}
      </h1>
    </div>

    <div :dir="$ctx.getDir()" class="w-full">
      <div class="w-full mb-5">
        <!-- I chose to keep the below as a comment just in case we decided to use labels later on -->
        <!-- <p class="mb-2 font-semibold">{{ t("cash") }}</p> -->
        <div class="flex items-center gap-2">
          <money-input
            v-model="paidBeforeChangeVal"
            @input="updatePaidBeforeChange"
            :currency="currency"
            ref="cashReceivedInput"
            @click="isFocusedOnCashInput = true"
          />

          <el-popover
            placement="bottom"
            :width="350"
            trigger="click"
            :visible="currencyCalcVisible"
          >
            <template #reference>
              <span
                class="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-sm cursor-pointer"
                @click="currencyCalcVisible = !currencyCalcVisible"
              >
                <font-awesome-icon icon="calculator" />
              </span>
            </template>

            <div
              :dir="$ctx.getDir()"
              v-click-outside-drawer="
                () => {
                  currencyCalcVisible = false
                }
              "
            >
              <currency-converter
                :fromCurrency="$ctx.currency === 'IQD' ? 'USD' : 'IQD'"
                :toCurrency="$ctx.currency"
                :rate="$ctx.currency === 'IQD' ? $ctx.exchangeRates.IQD : $ctx.exchangeRates.USD"
                @converted-amount="(val) => (convertedAmount = val)"
              />
              <div class="flex justify-center">
                <el-button @click="useConvertedAmount"> {{ t('useConverted') }}</el-button>
              </div>
            </div>
          </el-popover>
        </div>
      </div>

      <div v-if="isFocusedOnCashInput" class="flex flex-col mb-5">
        <div v-if="calculatorTotalValue" class="mb-5">
          {{ t('clickToAdd') }}
          <b class="text-blue-700">{{ formatDinero(calculatorTotalValueDinero) }}</b>
          {{ t('toTheTotal') }}
        </div>

        <div class="flex">
          <div class="grid flex-1 grid-cols-3">
            <button
              v-for="(b, index) in calculatorButtons"
              :key="b.label"
              :id="'calc-button-' + b.label"
              @click="appendNumber(b.value)"
              class="p-3 font-semibold border hover:bg-blue-400 hover:text-white"
              :class="{ 'col-span-3': index === calculatorButtons.length - 1 }"
            >
              {{ b.label }}
            </button>
          </div>
          <div class="flex flex-col">
            <button @click="removeLastDigitFromCashTotal()" class="flex-1 p-6 text-xl border">
              <font-awesome-icon class="text-red-500" :icon="['fas', 'arrow-left']" />
            </button>
            <button @click="addAppendedNumberToCashTotal()" class="flex-1 p-6 text-xl border">
              <font-awesome-icon class="text-blue-500" :icon="['fas', 'plus']" />
            </button>
          </div>
        </div>
      </div>

      <template v-if="enableEpayments">
      <div
        v-if="!addEPaymentVisible"
        class="flex flex justify-between items-center my-3 text-primary w-full"
        @click="makeEPaymentVisible"
      >
        <p>{{ t('addEPayment') }}</p>
      </div>

      <div v-else>
        <div class="flex flex justify-between items-center my-3 text-primary w-full">
          <h1 class="2xl:text-l lg:text-l text-m">
            {{ t('epaymentRecieved') }}
          </h1>
          <div class="line border w-1/3 mx-2"></div>
          <h1 v-if="currency !== ''" class="2xl:text-l lg:text-l text-m">
            {{ formatMoney(epayment, currency) }}
          </h1>
        </div>

        <div class="w-full mb-5">
          <!-- I chose to keep the below as a comment just in case we decided to use labels later on -->
          <!-- <p class="mb-2 font-semibold">{{ t("cash") }}</p> -->
          <div class="flex items-center gap-2">
            <money-input
              v-model="epaymentVal"
              @input="updateEPayment"
              :currency="currency"
              ref="epaymentInput"
              @click="isFocusedOnCashInput = false"
            />

            <el-popover
              placement="bottom"
              :width="350"
              trigger="click"
              :visible="currencyCalcVisible"
            >
              <template #reference>
                <span
                  class="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-sm cursor-pointer"
                  @click="currencyCalcVisible = !currencyCalcVisible"
                >
                  <font-awesome-icon icon="calculator" />
                </span>
              </template>

              <div
                :dir="$ctx.getDir()"
                v-click-outside-drawer="
                  () => {
                    currencyCalcVisible = false
                  }
                "
              >
                <currency-converter
                  :fromCurrency="$ctx.currency === 'IQD' ? 'USD' : 'IQD'"
                  :toCurrency="$ctx.currency"
                  :rate="$ctx.currency === 'IQD' ? $ctx.exchangeRates.IQD : $ctx.exchangeRates.USD"
                  @converted-amount="(val) => (convertedAmount = val)"
                />
                <div class="flex justify-center">
                  <el-button @click="useConvertedAmount"> {{ t('useConverted') }}</el-button>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      </template>

      <div v-if="!isFocusedOnCashInput" class="flex flex-col mb-5">
        <div v-if="calculatorTotalValue" class="mb-5">
          {{ t('clickToAdd') }}
          <b class="text-blue-700">{{ formatDinero(calculatorTotalValueDinero) }}</b>
          {{ t('toTheTotal') }}
        </div>

        <div class="flex">
          <div class="grid flex-1 grid-cols-3">
            <button
              v-for="(b, index) in calculatorButtons"
              :key="b.label"
              :id="'calc-button-' + b.label"
              @click="appendNumber(b.value)"
              class="p-3 font-semibold border hover:bg-blue-400 hover:text-white"
              :class="{ 'col-span-3': index === calculatorButtons.length - 1 }"
            >
              {{ b.label }}
            </button>
          </div>
          <div class="flex flex-col">
            <button @click="removeLastDigitFromEPaymentTotal()" class="flex-1 p-6 text-xl border">
              <font-awesome-icon class="text-red-500" :icon="['fas', 'arrow-left']" />
            </button>
            <button @click="addAppendedNumberToEPaymentTotal()" class="flex-1 p-6 text-xl border">
              <font-awesome-icon class="text-blue-500" :icon="['fas', 'plus']" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import MoneyInput from '@/components/form/MoneyInput.vue'
import CurrencyConverter from '@/components/form/CurrencyConverter.vue'

// utils
import { useI18n } from 'vue-i18n'
import dinero, { Currency, Dinero } from 'dinero.js'
import { formatDinero, formatMoney } from '@/utils/money'

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          total: 'Total Due',
          cash: 'Amount Paid',
          changeDue: 'Change Due',
          totalPaid: 'Total received',
          useConverted: 'Use as amount paid',
          clickToAdd: 'Click + to add ',
          toTheTotal: 'to the total received',
          cashReceived: 'Cash Received',
          cashReceived2: 'Cash Received',
          epaymentRecieved: 'E-payment Received',
          cashLabel: 'Cash',
          epaymentLabel: 'e-Payment',
          addEPayment: 'Add e-Payment'
        },
        ar: {
          total: 'المبلغ المطلوب',
          cash: 'المبلغ المدفوع',
          changeDue: 'المبلغ الباقي',
          totalPaid: 'المبلغ المستحصل',
          useConverted: 'استخدم كمبلغ مدفوع',
          clickToAdd: 'اضغط على + لاضافة ',
          toTheTotal: 'للمبلغ المستحصل',
          cashReceived: 'المبلغ المستحصل',
          cashReceived2: 'المدفوع نقداّ',
          epaymentRecieved: 'المدفوع الكترونياّ',
          cashLabel: 'النقد',
          epaymentLabel: 'الدفع الاكتروني',
          addEPayment: 'اضف دفع الكتروني'
        }
      }
    })

    const calculatorButtons = [
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '0', value: 0 }
    ]

    return { t, calculatorButtons }
  },
  data() {
    const calculatorTotalValue = ''
    return {
      convertedAmount: 0,
      currencyCalcVisible: false,
      calculatorTotalValue,
      addEPaymentVisible: false,
      isFocusedOnCashInput: true
    }
  },

  props: {
    totalDue: {
      type: Number,
      required: true
    },

    changeDue: {
      type: Number,
      required: true
    },

    paidBeforeChange: {
      type: Number,
      required: true
    },

    totalPaid: {
      type: Number,
      required: true
    },

    epayment: {
      type: Number,
      required: false,
      default: 0
    },

    currency: {
      type: String,
      required: true
    },

    precision: {
      type: Number,
      required: true
    },

    enableEpayments: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    paidBeforeChangeVal: {
      get(): number {
        return this.paidBeforeChange
      },
      set(val: number) {
        this.$emit('update:paidBeforeChange', val)
      }
    },

    epaymentVal: {
      get(): number {
        return this.epayment
      },
      set(val: number) {
        this.$emit('update:epayment', val)
      }
    },

    changeDueDinero(): Dinero {
      return dinero({
        amount: this.changeDue,
        currency: this.currency as Currency,
        precision: this.precision
      })
    },

    totalDueDinero(): Dinero {
      return dinero({
        amount: this.totalDue,
        currency: this.currency as Currency,
        precision: this.precision
      })
    },

    totalPaidDinero(): Dinero {
      return dinero({
        amount: this.totalPaid,
        currency: this.currency as Currency,
        precision: this.precision
      })
    },

    calculatorTotalValueDinero(): Dinero {
      return dinero({
        amount: +this.calculatorTotalValue,
        currency: this.currency as Currency,
        precision: this.precision
      })
    }
  },

  components: {
    MoneyInput,
    CurrencyConverter
  },

  methods: {
    cashReceivedInputFocus() {
      this.$nextTick(() => {
        /* @ts-ignore */
        const cashReceivedInput = this.$refs['cashReceivedInput']
        if (cashReceivedInput) {
          /* @ts-ignore */
          cashReceivedInput.focus()
        }
      })
    },
    formatDinero,
    formatMoney,
    useConvertedAmount() {
      this.currencyCalcVisible = false
      this.updatePaidBeforeChange(this.convertedAmount)
    },

    // Caution: HORRIBLE CODE BELOW!
    updatePaidBeforeChange(newVal: number) {
      this.$emit('update:paidBeforeChange', newVal)
      this.updateChnageDue(newVal)
      this.updateTotalPaid(newVal)
    },

    updateEPayment(newVal: number) {
      this.$emit('update:epayment', Math.min(newVal, this.totalDue))
    },

    appendNumber(val: number) {
      // add the val (which is a number) to a string so it gets appended to the right side
      this.calculatorTotalValue += val
    },

    addAppendedNumberToCashTotal() {
      this.updatePaidBeforeChange(this.paidBeforeChange + Number(this.calculatorTotalValue))
      // reset the calculator
      this.calculatorTotalValue = ''
    },

    addAppendedNumberToEPaymentTotal() {
      this.updateEPayment(this.epayment + Number(this.calculatorTotalValue))
      // reset the calculator
      this.calculatorTotalValue = ''
    },

    removeLastDigitFromCashTotal() {
      // remove the temporary calculator value
      if (this.calculatorTotalValue) {
        this.calculatorTotalValue = this.calculatorTotalValue.substr(
          0,
          this.calculatorTotalValue.length - 1
        )
      } else {
        const result = Math.floor(this.paidBeforeChange / 10)
        this.updatePaidBeforeChange(result)
      }
    },

    removeLastDigitFromEPaymentTotal() {
      // remove the temporary calculator value
      if (this.calculatorTotalValue) {
        this.calculatorTotalValue = this.calculatorTotalValue.substr(
          0,
          this.calculatorTotalValue.length - 1
        )
      } else {
        const result = Math.floor(this.epayment / 10)
        this.updateEPayment(result)
      }
    },

    addToPaidAmount(val: number) {
      this.updatePaidBeforeChange(this.paidBeforeChange + val)
    },

    updateChnageDue(paidBeforeChange: number) {
      const change = this.computeChangeDue(paidBeforeChange)
      if (change >= 0) this.$emit('update:changeDue', change)
    },

    computeChangeDue(paidAmount: number) {
      return Math.max(paidAmount - this.totalDue, 0)
    },

    updateTotalPaid(paidBeforeChange: number) {
      const total = Math.min(paidBeforeChange + this.epayment, this.totalDue)
      this.$emit('update:totalPaid', total)
    },

    makeEPaymentVisible() {
      this.addEPaymentVisible = true
      this.isFocusedOnCashInput = false

      this.$nextTick(() => {
        /* @ts-ignore */
        const epaymentInput = this.$refs['epaymentInput']
        if (epaymentInput) {
          /* @ts-ignore */
          epaymentInput.focus()
        }
      })
    }
  }
})
</script>

<style>
#payment-box {
  width: 100%;
  background: var(--secondary-bg-color);
}

.payment-button:hover {
  background: #409eff;
  color: var(--success-hover-text-color);
}

.money {
  width: 80%;
}
</style>

<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/accounting/transactions')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/accounting/transactions')">
          <horizontal-button
            :title="$t('views.accounting.transactions.newTransaction')"
            @click.prevent="$router.push($Route.ACCOUNTING_TRANSACTIONS_NEW_TRANSACTION)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions, ProcessCellForExportParams } from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'

import { Transaction, transactionI18nMessages } from '@/models/accounting/Transaction'
import { useI18n } from 'vue-i18n'
import { parseFormat } from '@/utils/date'
import { formatDinero } from '@/utils/money'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'transactions',

  setup() {
    const { t } = useI18n({
      messages: transactionI18nMessages,
      unescape: 'global'
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Transaction[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.$t('models.common.id'),
        field: 'externalID',
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.$t('models.common.createdAt'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr' },
        valueFormatter: (params) => {
          return parseFormat(params.value)
        }
      },
      {
        headerName: this.t('amount'),
        field: 'amountAmount',
        valueFormatter: (params) => {
          const transaction = params.node?.data as Transaction
          return formatDinero(transaction.amountDinero)
        }
      },
      {
        headerName: this.t('fromAccount'),
        valueGetter: (params) => {
          const transaction = params.node?.data as Transaction
          return this.$ctx.locale === 'en'
            ? transaction.creditedAccount.enName
            : transaction.creditedAccount.arName
        }
      },
      {
        headerName: this.t('toAccount'),
        valueGetter: (params) => {
          const transaction = params.node?.data as Transaction
          return this.$ctx.locale === 'en'
            ? transaction.debitedAccount.enName
            : transaction.debitedAccount.arName
        }
      },
      {
        headerName: this.t('journalType'),
        field: 'journalType',
        valueFormatter: (params) => {
          return this.t(params.value)
        }
      },
      {
        headerName: this.t('user'),
        field: 'user.fullName'
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterItems()
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()
      try {
        const data = await this.$http.get<Transaction[]>(Transaction.ENDPOINT)
        this.rowData = data.map((transaction) => Transaction.from(transaction))
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      await this.updateRowData()
    },

    async applyToSelected() {
      if (this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          this.gridApi?.exportDataAsCsv({
            onlySelected: true,
            processCellCallback: this.processCellForExport
          })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.exportSuccess')
          })

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    processCellForExport(params: ProcessCellForExportParams) {
      if (params.column.getColDef().headerName === 'Transaction Amount') {
        return params.node?.data.amountDinero ? formatDinero(params.node?.data.amountDinero) : ''
      } else {
        //if the cell doesn't belong to any of the above don't change it
        return params.value
      }
    },

    filterItems() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.ACCOUNTING_TRANSACTIONS_TRANSACTION.replace(':id', event.data.id)
      )
    }
  }
})
</script>

<style></style>

import User from '../user/User'
import Dinero, { Currency, Dinero as DineroType } from 'dinero.js'
import Location from '../company/Location'
import { Account } from './Account'
import { Register } from '../company/Register'
import UUIDBase from '../UUIDBase'
import { CustomerInvoice } from '../sales/CustomerInvoice'
import VendorBill from '../vendor/VendorBill'

export const transactionI18nMessages = {
  en: {
    description: 'Description',
    amount: 'Transaction Amount',
    creditedAccount: 'Credited Account',
    debitedAccount: 'Debited Account',
    debit: 'Debit',
    credit: 'Credit',
    journalType: 'Journal Type',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    transfer: 'Transfer',
    user: 'Responsible Employee',
    location: 'Location',
    postingType: 'Posting type',
    transactionID: 'Transaction ID',
    associatedPostings: 'Associated Postings',
    associatedAccounts: 'Associated Accounts',
    account: 'Account',
    fromAccount: 'From Account',
    toAccount: 'To Account',
    totalAmount: 'Total Amount',
    orderID: 'Order ID',
    type: 'Type',
    customer: 'Customer',
    sale: 'Sale',
    payment: 'Payment',
    return: 'Return'
  },
  ar: {
    description: 'ملاحظات',
    amount: 'قيمة الحركة المالية',
    creditedAccount: 'الحساب الدائن',
    debitedAccount: 'الحساب المدين',
    debit: 'دائن',
    credit: 'مدين',
    journalType: 'نوع السجل',
    deposit: 'ايداع',
    withdrawal: 'سحب',
    transfer: 'تحويل',
    user: 'الموظف المسؤول',
    location: 'الموقع',
    postingType: 'نوع التسجيل',
    transactionID: 'رقم الحركة المالية',
    associatedPostings: 'التسجيلات المتعلقة بالحساب',
    associatedAccounts: 'الحسابات المتعلقة بالحركة المالية',
    account: 'الحساب',
    fromAccount: 'من حساب',
    toAccount: 'الى حساب',
    totalAmount: 'المبلغ الكلي',
    orderID: 'رقم الطلب',
    type: 'النوع',
    customer: 'الزبون',
    sale: 'بيع',
    payment: 'دفع',
    return: 'استرجاع'
  }
}

export class Posting extends UUIDBase {
  static ENDPOINT = '/accounting/postings'
  transactionID = ''

  accountID?: string
  account = new Account()

  amountAmount = 0
  amountCurrency = ''
  amountPrecision?: number
  amountDinero = Dinero()

  type = ''

  constructor(type?: string) {
    super()
    if (type) {
      this.type = type
    }
  }

  static from(json: Record<string, unknown> | Posting): Posting {
    const p = Object.assign(new Posting(), json)
    p.account = Account.from(p.account)
    p.amountDinero = Dinero({
      amount: p.amountAmount,
      currency: p.amountCurrency as Currency,
      precision: p.amountPrecision
    })

    return p
  }

  preparePayload(transactionDinero: DineroType) {
    let transactionAmount = transactionDinero.getAmount()
    if (this.type === 'credit') {
      // store credit values as negative values
      transactionAmount *= -1
    }

    this.amountAmount = transactionAmount
    this.amountCurrency = transactionDinero.getCurrency()
    this.amountPrecision = transactionDinero.getPrecision()

    return JSON.stringify(this)
  }
}

export interface TransactionsPaginated {
  data: Array<Transaction>,
  total: number
}

export class Transaction extends UUIDBase {
  static ENDPOINT = '/accounting/transactions'
  user = new User()
  userID?: string

  location = new Location()
  locationID?: string

  register = new Register()
  registerID?: string

  journalType = ''

  totalPaidAmount?: number
  amountAmount?: number
  currency = ''
  precision = 0
  totalPaidAmountDinero = Dinero()
  amountDinero = Dinero()
  label?: string
  referenceableID?: string
  referenceableType?: string

  customerInvoice?: CustomerInvoice
  customerInvoiceID?: string

  vendorBill?: VendorBill
  vendorBillID?: string

  description = ''

  // for validation purposes
  debitedAccountID?: string
  debitedAccount = new Account()
  creditedAccountID?: string
  creditedAccount = new Account()

  changeDueAmount?: number
  changeDueDinero = Dinero()

  totalDueAmount?: number
  totalDueAmountDinero = Dinero()

  balanceDinero = Dinero()

  paidBeforeChangeAmount?: number
  paidBeforeChangeDinero = Dinero()

  isReceipt = false
  receiptType = ''
  referenceableExternalID = ''
  PaymentMethod = ''

  static from(json: Record<string, unknown> | Transaction) {
    const t = Object.assign(new Transaction(), json)
    t.user = User.from(t.user)
    t.location = Location.from(t.location)
    t.register = Register.from(t.register)
    t.creditedAccount = Account.from(t.creditedAccount)
    t.debitedAccount = Account.from(t.debitedAccount)
    if (t.customerInvoice) t.customerInvoice = CustomerInvoice.from(t.customerInvoice!)
    if (t.vendorBill) t.vendorBill = VendorBill.from(t.vendorBill!)
    t.totalPaidAmountDinero = Dinero({
      amount: t.totalPaidAmount,
      currency: t.currency as Currency,
      precision: t.precision
    })
    t.amountDinero = Dinero({
      amount: t.amountAmount,
      currency: t.currency as Currency,
      precision: t.precision
    })
    t.changeDueDinero = Dinero({
      amount: t.changeDueAmount || 0,
      currency: t.currency as Currency,
      precision: t.precision
    })
    t.paidBeforeChangeDinero = Dinero({
      amount: t.paidBeforeChangeAmount || 0,
      currency: t.currency as Currency,
      precision: t.precision
    })
    t.totalDueAmountDinero = Dinero({
      amount: t.totalDueAmount || 0,
      currency: t.currency as Currency,
      precision: t.precision
    })
    t.balanceDinero = Dinero({
      amount: (t.totalDueAmount || 0) - (t.amountAmount || 0),
      currency: t.currency as Currency,
      precision: t.precision
    })

    return t
  }

  preparePayload(location: Location, register: Register, user?: User) {
    if (user) {
      this.user = user
      this.userID = user.id
    }
    this.location = location
    this.locationID = location.id

    this.register = register
    this.registerID = register.id

    this.amountAmount = this.amountDinero.getAmount()
    this.totalPaidAmount = this.totalPaidAmountDinero.getAmount()
    this.currency = this.amountDinero.getCurrency()
    this.precision = this.amountDinero.getPrecision()
  }
}

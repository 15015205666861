<template>
  <el-form
    @submit.prevent="validateSubmit('customerForm')"
    ref="customerForm"
    :model="customer"
    :rules="rules"
    label-position="top"
    novalidate
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button :title="$t('actions.saveChanges')" isSuccess faIcon="save" />
        </div>

        <div class="md:w-4/12 m-2" v-if="enableClearForm">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('customerForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <input-section>
      <h1>{{ t('sections.contact') }}</h1>

      <input-group>
        <el-form-item :label="t('firstName')" prop="firstName" class="input-label">
          <el-input
            id="firstName"
            v-model="customer.firstName"
            :placeholder="t('placeholders.firstName')"
            maxlength="127"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('lastName')" prop="lastName" class="input-label">
          <el-input
            id="lastName"
            v-model="customer.lastName"
            :placeholder="t('placeholders.lastName')"
            maxlength="127"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>
      </input-group>

      <input-group>
        <el-form-item :label="t('businessName')" prop="businessName" class="input-label">
          <el-input
            id="businessName"
            v-model="customer.businessName"
            :placeholder="t('placeholders.businessName')"
            maxlength="127"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>
      </input-group>

      <input-group>
        <el-form-item :label="t('phone')" prop="phone" class="input-label">
          <el-input
            id="phone"
            v-model="customer.phone"
            :placeholder="t('placeholders.phone')"
            maxlength="15"
            dir="ltr"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('phone2')" prop="phone2" class="input-label">
          <el-input
            id="phone2"
            v-model="customer.phone2"
            :placeholder="t('placeholders.phone2')"
            maxlength="15"
            dir="ltr"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('email')" prop="email" class="input-label">
          <el-input
            id="email"
            v-model="customer.email"
            type="email"
            :placeholder="t('placeholders.email')"
            dir="ltr"
            maxlength="255"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('customerType')" prop="selectedLocationID" class="input-label">
          <el-select v-model="customer.customerType" filterable clearable class="w-full">
            <el-option
              v-for="type in customPriceTypes"
              :key="type"
              :label="$ctx.locale === 'en' ? type.enName : type.arName"
              :value="type.id"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">{{
                $ctx.locale === 'en' ? type.enName : type.arName
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </input-group>
      <el-form-item :label="t('dateOfBirth')" prop="dateOfBirth" class="input-label">
        <el-date-picker
          id="country"
          v-model="customer.dateOfBirth"
          type="date"
          format="DD-MM-YYYY"
          placeholder="Pick a date"
          style="width: 100%"
        />
      </el-form-item>
    </input-section>

    <input-section>
      <h1>{{ t('sections.address') }}</h1>
      <el-form-item :label="t('streetAddress')" prop="streetAddress" class="input-label">
        <el-input
          id="streetAddress"
          v-model="customer.streetAddress"
          :placeholder="t('placeholders.streetAddress')"
          show-word-limit
          maxlength="255"
          clearable
        >
        </el-input>
      </el-form-item>

      <input-group>
        <el-form-item :label="t('city')" prop="city" class="input-label">
          <el-input
            id="city"
            :placeholder="t('placeholders.city')"
            maxlength="70"
            show-word-limit
            clearable
            v-model="customer.city"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('postalCode')" prop="postalCode" class="input-label">
          <el-input
            id="postalCode"
            :placeholder="t('placeholders.postalCode')"
            show-word-limit
            maxlength="10"
            clearable
            v-model="customer.postalCode"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('country')" prop="country" class="input-label">
          <el-input
            id="country"
            :placeholder="t('placeholders.country')"
            maxlength="70"
            show-word-limit
            clearable
            v-model="customer.country"
          >
          </el-input>
        </el-form-item>
      </input-group>
    </input-section>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import AlertBadge from '@/components/AlertBadge.vue'
import HorizontalButton from '@/components/HorizontalButton.vue'
import InputSection from '@/components/form/InputSection.vue'
import InputGroup from '@/components/form/InputGroup.vue'
import { ElForm } from 'element-plus'
import { ElMessageBox } from 'element-plus'

// models
import Customer, { customerI18nMessages } from '@/models/customer/Customer'
import { useI18n } from 'vue-i18n'
import CustomPriceType from '@/models/inventory/CustomPrice'

export default defineComponent({
  name: 'customer-form',

  setup() {
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        en: {
          ...customerI18nMessages.en,

          phoneNumberAlreadyUsedMsg:
            'This phone number has been previously used. Are you sure you want to use it again?',
          sections: {
            customer: 'Customer Info',
            contact: 'Contcat Info',
            address: 'Address'
          },

          placeholders: {
            firstName: 'Example: Ahmed',
            lastName: 'Example: Al-Iraqi',
            phone: 'Example: 077011111111',
            phone2: 'Example: 077011111111',
            email: 'Example: info[at]hamza.com',
            streetAddress: 'Example: Next to Almansour Mall',
            city: 'Example: Baghdad',
            postalCode: 'Example: 10015',
            country: 'Example: Iraq',
            businessName: 'Example: Thecky'
          },
          OK: 'OK',
          Cancel: 'Cancel'
        },
        ar: {
          ...customerI18nMessages.ar,

          phoneNumberAlreadyUsedMsg:
            'تم استخدام رقم الهاتف مسبقا, هل انت متأكد انك تريد استخدامه مرة اخرى؟',
          sections: {
            customer: 'عن الزبون',
            contact: 'معلومات الاتصال',
            address: 'العنوان'
          },

          placeholders: {
            firstName: 'مثلا: احمد',
            lastName: 'مثلا: العراقي',
            phone: 'مثلا: 077011111111',
            phone2: 'مثلا: 077011111111',
            email: `مثلا: info[at]hamaza.com`,
            streetAddress: 'مثلا: مجاور لمول المنصور',
            city: 'مثلا: بغداد',
            postalCode: 'مثلا: 10015',
            country: 'مثلا: العراق',
            businessName: 'مثلا: الذكي'
          },
          OK: 'موافق',
          Cancel: 'الغاء'
        }
      }
    })

    const rules = {
      firstName: [{ max: 127, message: t('validation.maxLength', { len: 127 }) }],
      lastName: [{ max: 127, message: t('validation.maxLength', { len: 127 }) }],
      phone: [{ max: 15, message: t('validation.maxLength', { len: 15 }) }],
      phone2: [{ max: 15, message: t('validation.maxLength', { len: 15 }) }],
      email: [{ max: 255, message: t('validation.maxLength', { len: 255 }) }],
      streetAddress: [{ max: 255, message: t('validation.maxLength', { len: 255 }) }],
      city: [{ max: 70, message: t('validation.maxLength', { len: 70 }) }],
      postalCode: [{ max: 10, message: t('validation.maxLength', { len: 10 }) }],
      country: [{ max: 70, message: t('validation.maxLength', { len: 70 }) }]
    }

    return { t, rules }
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    InputGroup
  },
  props: {
    initCustomer: {
      type: Customer,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      customer: this.initCustomer,
      error: { title: '', body: '' },
      customPriceTypes: [] as Array<CustomPriceType>
    }
  },

  async beforeMount() {
    const customPriceTypesResp = await this.$http.get<CustomPriceType[]>(CustomPriceType.ENDPOINT)

    this.customPriceTypes = customPriceTypesResp.map((cat) => CustomPriceType.from(cat))

    const regularCustomPrice = new CustomPriceType()
    regularCustomPrice.id = 'regular'
    regularCustomPrice.enName = 'Regular'
    regularCustomPrice.arName = 'اعتيادي'

    this.customPriceTypes = [regularCustomPrice, ...this.customPriceTypes]

    if (!this.customer.customerType.length) this.customer.customerType = regularCustomPrice.id
  },

  methods: {
    async confirmDuplicatedPhone() {
      const phones = [this.customer.phone, this.customer.phone2]

      const promises = phones.map((phone) =>
        this.$http.get<Customer[]>(Customer.ENDPOINT + `?phone=${encodeURIComponent(phone)}`)
      )

      try {
        const results = await Promise.all(promises)

        for (let i = 0; i < results.length; i++) {
          const customers = results[i]
          if (customers.length > 0) {
            const matchingCustomer = customers.find((customer) => customer.id !== this.customer.id)
            if (matchingCustomer) {
              const confirmed = await ElMessageBox.confirm(
                this.t('phoneNumberAlreadyUsedMsg'),
                'Warning',
                {
                  confirmButtonText: this.t('OK'),
                  cancelButtonText: this.t('Cancel'),
                  type: 'warning'
                }
              )

              return confirmed // Return true if confirmed, false if canceled
            }
          }
        }

        return true // Return true if no duplicates found
      } catch (error) {
        // Handle errors here
        console.error(error)
        return false // Return false in case of an error
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      form.resetFields()
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        }

        if (await this.confirmDuplicatedPhone()) {
          const resp = await this.submitAction(this.customer)

          if (resp) {
            this.error.title = resp?.title
            this.error.body = resp?.body
            document.getElementById('main')?.scrollTo({ top: 0 })
            return false
          } else {
            if (this.clearFormOnSubmit) {
              this.clearForm(formName)
            }

            this.$alertModal.showSuccess({ title: this.successMsg, body: '' })
          }
          return true
        }

        return false
      })
    }
  }
})
</script>

<style scoped>
form > h1 {
  @apply text-lg py-3 font-bold;
}

form > h2 {
  @apply py-3 font-bold;
}

formt > h3 {
  @apply py-3 font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>

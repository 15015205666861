import User from '@/models/user/User'
import Location from '@/models/company/Location'
import { App, reactive } from 'vue'
import { Register } from '@/models/company/Register'
import { Machine } from '@/models/company/Machine'

interface ExchangeRates {
  USD: number
  IQD: number
}

export interface Context {
  currency: string
  currencyPrecision: number
  locale: string
  currentUser: User
  currentLocation: Location
  currentRegister: Register
  currentMachine: Machine
  machineGUID: string
  currentPrinter: string
  companyName: string
  exchangeRates: ExchangeRates
  receiptLanguage: string
  receiptSize: string
  accountStatementSize: string
  getDir: () => string
  getAccessToken: () => string
  setLocale: (newLocale: string) => void
  setUser: (user: User) => void
  setLocation: (newLocation: Location) => void
  setRegister: (register: Register) => void
  updateRegisterStatus: (newStatus: string) => void
  setMachine: (machine: Machine) => void
  setMachineGUID: (guid: string) => void
  setPrinter: (printer: string) => void
  setExchangeRates: (exchangeRates: ExchangeRates) => void
  getReceiptSize: () => string
  setReceiptSize: (receiptSize: string) => void
  setReceiptLanguage: (lang: string) => void
  getAccountStatementSize: () => string
  setAccountStatementSize: (receiptSize: string) => void
}

const ctx: Context = {
  // default values
  currency: 'IQD',
  currencyPrecision: 0,
  locale: localStorage.getItem('locale') || 'ar',
  currentUser: User.from(JSON.parse(localStorage.getItem('user') || '{}')),
  currentLocation: Location.from(JSON.parse(localStorage.getItem('currentLocation') || '{}')),
  currentRegister: Register.from(JSON.parse(localStorage.getItem('register') || '{}')),
  currentMachine: Machine.from(JSON.parse(localStorage.getItem('machine') || '{}')),
  machineGUID: localStorage.getItem('machineGUID') || '',
  currentPrinter: localStorage.getItem('printer') || '',
  companyName: 'Aleef Pet',
  exchangeRates: {
    USD: 0.0006852,
    IQD: 1459.43
  },
  receiptLanguage: localStorage.getItem('receipt_language') || 'en',
  receiptSize: localStorage.getItem('receipt_size') || 'a4',
  accountStatementSize: localStorage.getItem('account_statement_size') || 'a4',
  getDir: function () {
    return this.locale === 'ar' ? 'rtl' : 'ltr'
  },
  getAccessToken: function () {
    return this.currentUser.accessToken
  },
  setLocale: function (newLocale: string) {
    localStorage.setItem('locale', newLocale)
    location.reload()
  },
  setUser: function (user: User) {
    this.currentUser = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  setLocation: function (newLocation: Location) {
    this.currentLocation = newLocation
    localStorage.setItem('currentLocation', JSON.stringify(newLocation))
  },
  setRegister: function (register: Register) {
    this.currentRegister = register
    localStorage.setItem('register', JSON.stringify(register))
  },
  updateRegisterStatus: function (newStatus: string) {
    this.currentRegister.status = newStatus
  },
  setMachine: function (machine: Machine) {
    this.currentMachine = machine
    localStorage.setItem('machine', JSON.stringify(machine))
  },
  setMachineGUID: function (guid: string) {
    this.machineGUID = guid
    localStorage.setItem('machineGUID', guid)
  },
  setPrinter: function (printer: string) {
    this.currentPrinter = printer
    localStorage.setItem('printer', printer)
  },
  setExchangeRates: function (exchangeRates: ExchangeRates) {
    this.exchangeRates = exchangeRates
  },
  getReceiptSize: function () {
    return this.receiptSize
  },
  setReceiptSize: function (receiptSize: string) {
    localStorage.setItem('receipt_size', receiptSize)
    this.receiptSize = receiptSize
  },
  setReceiptLanguage: function (newLocale: string) {
    localStorage.setItem('receipt_language', newLocale)
    this.receiptLanguage = newLocale
  },
  getAccountStatementSize: function () {
    return this.accountStatementSize
  },
  setAccountStatementSize: function (receiptSize: string) {
    localStorage.setItem('account_statement_size', receiptSize)
    this.accountStatementSize = receiptSize
  }
}

export function useContext() {
  return reactive(ctx)
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$ctx = ctx
  }
}

<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else class="grid grid-cols-11 gap-2">
    <div class="w-full mx-2 col-span-11 md:col-span-8">
      <div class="mt-2">
        <el-table
          :data="order.orderItems"
          :row-class-name="orderTableRowClassName"
          stripe
          border
          style="width: 100%"
          v-if="!order.empty()"
          height="300"
          :max-height="800"
          class="card rounded"
          header-cell-class-name="my-header"
        >
          <el-table-column :min-width="40" type="index" :index="indexMethod" label="#" />
          <el-table-column :min-width="90" prop="item.externalID" :label="t('itemCode')" />
          <el-table-column :min-width="220" :label="t('itemName')">
            <template #default="scope">
              <div class="truncate">
                {{ scope.row.item.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="120" prop="item.salePriceAmount" :label="t('price')">
            <template #default="scope">
              <span>
                {{ formatDinero(scope.row.itemSalePriceDinero) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="120"
            prop="item.soldAtPriceAmount"
            :label="t('soldAtPrice')"
            align="center"
          >
            <template #default="scope">
              <div class="flex justify-between">
                <span>
                  {{ formatDinero(scope.row.soldAtPriceDinero) }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column :min-width="120" prop="orderItemSerials" :label="t('quantity')">
            <template #default="scope">
              <div class="flex items-center">
                <div class="px-2 pt-1">
                  {{ scope.row.quantity - scope.row.returnedQuantity }}
                </div>
                <el-popover
                  v-if="scope.row.orderItemSerials.length"
                  placement="bottom-start"
                  :title="t('serialNumbers')"
                  :min-width="200"
                  trigger="hover"
                >
                  <template #default>
                    <p
                      v-for="(serial, i) in scope.row.orderItemSerials"
                      :key="i"
                      :class="{ 'line-through': serial.returned }"
                    >
                      {{ serial.itemSerial.number }}
                    </p>
                  </template>
                  <template #reference>
                    <div class="flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        height="16px"
                        width="16px"
                        version="1.1"
                        id="Capa_1"
                        viewBox="0 0 15.273 15.273"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            style="fill: #030104"
                            d="M0,1.803h0.897V13.47H0V1.803z M1.446,13.47h0.505V1.803H1.446V13.47z M2.315,13.47h0.504V1.803   H2.315V13.47z M3.482,13.47h1.104V1.803H3.482V13.47z M5.47,13.47h0.552V1.803H5.47V13.47z M6.878,13.47h2.288V1.803H6.878V13.47z    M12.84,13.47h0.752V1.803H12.84V13.47z M14.248,1.803V13.47h1.025V1.803H14.248z M10.084,13.47h0.492V1.803h-0.492V13.47z    M11.281,13.47h0.492V1.803h-0.492V13.47z"
                          />
                        </g>
                      </svg>
                    </div>
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column :min-width="80"
            prop="returnedQuantity"
            :label="t('returnedQty')"
            align="center"
          >
          </el-table-column> -->

          <el-table-column :min-width="120" prop="itemTotal" :label="t('itemTotal')" align="center">
            <template #default="scope">
              {{ formatDinero(scope.row.itemTotal) }}
            </template>
          </el-table-column>
          <el-table-column :min-width="150" :label="t('notes')">
            <template #default="scope">
              {{ scope.row.notes }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mt-4 font-bold">{{ t('relatedPayments') }}</div>
      <div class="mt-2">
        <el-table
          :data="transactions"
          stripe
          border
          style="width: 100%"
          v-if="!order.empty()"
          :max-height="800"
          class="card rounded"
          header-cell-class-name="my-header2"
        >
          <el-table-column :min-width="60" prop="externalID" :label="t('paymentID')" />
          <el-table-column :min-width="90" prop="amountAmount" :label="t('amount')">
            <template #default="scope">
              <order-payment-edit :data="scope.row" @updated="onTxUpdated"></order-payment-edit>
            </template>
          </el-table-column>
          <el-table-column :min-width="90" prop="paymentMethod" :label="t('paymentMethod')">
            <template #default="scope">
              {{ t(scope.row.paymentMethod) }}
            </template>
          </el-table-column>
          <el-table-column :min-width="110" prop="createdAt" :label="t('date')">
            <template #default="scope">
              <span>
                {{ formatPostgresDate(scope.row.createdAt, 'DD/MM/YYYY hh:mm A') }}
                {{ scope.row.createdAt != scope.row.updatedAt ? `(${t('edited')})` : '' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :min-width="70" prop="externalID" :label="t('totalPaid')">
            <template #default="scope">
              <span :class="{ 'text-red-600': scope.row.receiptType == 'return' }">
                {{ formatDinero(scope.row.totalPaidAmountDinero) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :min-width="90" :label="t('owed')">
            <template #default="scope">
              {{ formatDinero(getOwed(scope.row)) }}
            </template>
          </el-table-column>
          <el-table-column :min-width="100" :label="t('paymentNotes')">
            <template #default="scope">
              <div class="flex items-center justify-around">
                <span>
                  {{ scope.row.description }}
                </span>
                <div>
                  <button
                    class="text-black hover:text-blue-600 font-bold"
                    @click="printTx(scope.row)"
                  >
                    <span class="fa fa-print"></span>
                  </button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="w-full col-span-11 md:col-span-3 mt-2" style="font-size: 14px !important">
      <order-info :order="order" :showPrintReceipt="true" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import AlertBadge from '@/components/AlertBadge.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import OrderInfo from './components/OrderInfo.vue'

// models
import { i18nOrderMessages, Order, OrderItem } from '@/models/sales/Order'

// tools
import { useI18n } from 'vue-i18n'

// helpers
import { formatDinero } from '@/utils/money'
import { parseFormat, formatPostgresDate } from '@/utils/date'
import Dinero, { Currency, Dinero as DineroType } from 'dinero.js'
import { Transaction } from '@/models/accounting/Transaction'
import OrderPaymentEdit from './OrderPaymentEdit.vue'

export default defineComponent({
  name: 'order',
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        en: {
          ...i18nOrderMessages.en,
          print: 'Print Receipt',
          printOrder: 'Print Order',
          makePayment: 'Make a payment',
          paymentsMade: 'Total paid amount',
          moneyOwed: 'Money Owed',
          returns: 'Order returns',
          markAsDelivered: 'Mark as delivered',
          doYouWantMarkAsDelivered: 'Do you want to mark order as delivered?',
          orderMarkedAsDelivered: 'Order marked as Delivered',
          Yes: 'Yes',
          Cancel: 'Cancel',
          relatedPayments: 'Related Payments',
          paymentID: 'Payment ID',
          amount: 'Amount',
          date: 'Date',
          totalPaid: 'Total received or returned',
          owed: 'Owed',
          notes: 'Notes',
          edited: 'edited',
          paymentMethod: 'Payment Method',
          'debit-card': 'E-Payment',
          cash: 'Cash',
          paymentNotes: 'Payment Notes'
        },
        ar: {
          ...i18nOrderMessages.ar,
          print: 'اطبع الفاتورة',
          printOrder: 'اطبع فاتورة الطلب',
          makePayment: 'تسديد مبلغ',
          paymentsMade: 'المبلغ المدفوع',
          moneyOwed: 'المبلغ المطلوب',
          returns: 'الترجيعات',
          markAsDelivered: 'تم التوصيل',
          doYouWantMarkAsDelivered: 'هل تم توصيل الطلب؟',
          orderMarkedAsDelivered: 'تم توصيل الطلب',
          Yes: 'نعم',
          Cancel: 'الغاء',
          relatedPayments: 'المدفوعات ذات الصلة',
          paymentID: 'رقم الحركة',
          amount: 'القيمة',
          date: 'التاريخ',
          totalPaid: 'المبلغ المستلم او المرجع',
          owed: 'الباقي',
          notes: 'الملاحظات',
          edited: 'تم تعديلة',
          paymentMethod: 'طريقة الدفع',
          'debit-card': 'دفع الكتروني',
          cash: 'نقد',
          paymentNotes: 'ملاحظات الحركة'
        }
      }
    })

    function getOwed(row: any) {
      let owed = 0
      owed = row.totalDueAmount > 0 ? row.totalDueAmount - row.amountAmount : 0

      const value = Dinero({
        amount: owed,
        currency: row.currency,
        precision: row.precision
      })

      return value
    }

    const orderTableRowClassName = ({ row, rowIndex }: { row: OrderItem; rowIndex: number }) => {
      if (row.quantity - row.returnedQuantity == 0) {
        return 'danger-row'
      } else if (row.returnedQuantity > 0) {
        return 'warning-row'
      }

      return ''
    }

    return { t, formatPostgresDate, getOwed, orderTableRowClassName }
  },

  data() {
    return {
      order: new Order(),
      transactions: null as any,
      error: {
        title: '',
        body: ''
      },
      loading: false
    }
  },

  async beforeMount() {
    await this.load()
  },

  components: {
    AlertBadge,
    LoadingSpinner,
    OrderInfo,
    OrderPaymentEdit
  },

  methods: {
    async load() {
      // get order
      try {
        this.loading = true
        const resp = await this.$http.get<Order>(`${Order.ENDPOINT}/${this.$route.params.id}`)
        this.order = Order.from(resp)

        this.transactions = this.order.invoice.transactions
          .filter((i) => {
            if (i.isReceipt) return i
          })
          .reverse()
      } catch (error) {
        console.log(error)
        this.error.title = error.title
        this.error.body = error.body
      } finally {
        this.loading = false
      }
    },
    formatDinero,
    parseFormat,
    indexMethod(index: number) {
      return index + 1
    },
    printTx(data: any) {
      this.$router.push(this.$Route.SALES_TRANSACTIONS_RECEIPT.replace(':transactionID', data.id))
    },
    onTxUpdated() {
      this.load()
    }
  }
})
</script>

<style scoped>
.card {
  background: var(--secondary-bg-color);
}
</style>

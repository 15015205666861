<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <employee-form
    v-else
    :init-employee="initEmployee"
    :permissionGroups="permissionGroups"
    :successMsg="t('created')"
    :submitAction="submit"
    enableClearForm
    clearFormOnSubmit
  />
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue'
import EmployeeForm from '@/views/hr/employees/EmployeeForm.vue'

import { useI18n } from 'vue-i18n'
import User from '@/models/user/User'
import { useHTTP } from '@/plugins/http'
import PermissionGroup from '@/models/hr/Permission'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'

export default defineComponent({
  name: 'new-employee',
  components: {
    LoadingSpinner,
    EmployeeForm,
    AlertBadge
  },
  setup() {
    const permissionGroups = ref(new Array<PermissionGroup>())
    const error = ref({ title: '', body: '' })
    const http = useHTTP()
    const loading = ref(true)

    const loadPermissions = async () => {
      const url = `${PermissionGroup.ENDPOINT}`

      try {
        const resp = await http.get<PermissionGroup[]>(url)
        permissionGroups.value = resp.map((e) => PermissionGroup.from(e))
      } catch (errorResp) {
        error.value.title = errorResp.title
        error.value.body = errorResp.body
      }
      loading.value = false
    }

    onBeforeMount(async () => {
      await loadPermissions()
    })

    const { t } = useI18n({
      messages: {
        en: {
          created: 'Employee was created successfully'
        },
        ar: {
          created: 'تم انشاء الموظف بنجاح'
        }
      }
    })

    return {
      t,
      permissionGroups,
      error,
      loading
    }
  },
  data() {
    return {
      initEmployee: new User()
    }
  },

  methods: {
    async submit(employee: User) {
      try {
        await this.$http.post(User.ENDPOINT, employee)
      } catch (error) {
        return error
      }
    }
  }
})
</script>

<style></style>

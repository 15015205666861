<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/inventory/items')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
        <template v-if="$can('delete', '/inventory/items')">
          <el-option
            key="delete"
            :label="$t('views.common.listView.selectedRows.delete')"
            value="delete"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/inventory/items')">
          <horizontal-button
            :title="$t('views.inventory.items.newSimpleItem')"
            @click.prevent="$router.push($Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-balham mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community'
import Item, { itemI18nMessages } from '@/models/inventory/Item'
// import ItemTag from "@/models/inventory/Tag";
import HorizontalButton from '@/components/HorizontalButton.vue'

import { formatDinero } from '@/utils/money'
import { useI18n } from 'vue-i18n'
import { ProcessCellForExportParams } from 'ag-grid-community'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'items',

  components: {
    AgGridVue,
    HorizontalButton
  },

  setup() {
    const messages = {
      en: {
        ...itemI18nMessages.en,
        itemPriceUpdated: 'Item price has been updated',
        unlimited: 'Unlimited'
      },
      ar: {
        ...itemI18nMessages.ar,
        itemPriceUpdated: 'تم تحديث سعر المنتج بنجاح',
        unlimited: 'غير محدود'
      }
    }

    const { t } = useI18n({
      messages
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Item[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: 'center' }
    }

    this.columnDefs = [
      {
        headerName: this.t('name'),
        field: 'name',
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: 'start' },
        headerCheckboxSelection: true,
        minWidth: 300
      },
      {
        headerName: this.t('listPriceAmount'),
        field: 'listPriceAmount',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        valueFormatter: ({ data }) => {
          const item = data as Item
          return item.listPriceDinero ? formatDinero(item.listPriceDinero) : ''
        },
        cellStyle: { textAlign: 'center' },
        minWidth: 200,
      },
      {
        headerName: this.t('salePriceAmount'),
        field: 'salePriceAmount',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        valueFormatter: ({ data }) => {
          const item = data as Item
          return item.salePriceDinero ? formatDinero(item.salePriceDinero) : ''
        },
        cellStyle: { textAlign: 'center' },
        minWidth: 200,
      },
      // {
      //   headerName: this.t('wholesalePriceAmount'),
      //   field: 'wholesalePriceAmount',
      //   type: 'numericColumn',
      //   filter: 'agNumberColumnFilter',
      //   valueFormatter: ({ data }) => {
      //     const item = data as Item
      //     return item.wholesalePriceDinero ? formatDinero(item.wholesalePriceDinero) : ''
      //   },
      //   cellStyle: { textAlign: 'center' },
      //   minWidth: 200,
      // },
      {
        headerName: this.t('currentLocationStockQuantity'),
        field: 'currentLocationStockQuantity',
        type: 'numericColumn',
        minWidth: 125,
        filter: 'agNumberColumnFilter',
        cellStyle: ({ value }) => {
          const base = { textAlign: 'center', backgroundColor: 'transparent' }

          if (value < 0) {
            base['backgroundColor'] = 'var(--danger-bg-color)'
          } else if (value < 10) {
            base['backgroundColor'] = 'var(--warning-bg-color)'
          }
          
          if (value == this.t('unlimited')) {
            base['backgroundColor'] = '#91bee7'
          }

          return base
        },
        valueGetter: (params) => {
          const item = params.node?.data as Item
          if (!item) return ''
          
          if (item.unlimitedStock) {
            return this.t('unlimited')
          }

          return item.currentLocationStockQuantity
        },
      },
      {
        headerName: this.t('vendor'),
        field: 'vendor.name',
        minWidth: 150,
      },
      {
        headerName: this.t('itemBrand'),
        field: 'itemBrand.name',
        minWidth: 150,
      },
      {
        headerName: this.t('itemCategory'),
        field: 'itemCategory.name',
        minWidth: 150,
      },
      {
        headerName: this.t('itemTags'),
        valueGetter(params: ValueGetterParams) {
          const item = params.data as Item
          const tags = item.itemTags
          if (!tags) return ''
          return tags.map((tag) => tag.name).join(', ')
        },
        minWidth: 150,
      },
      {
        headerName: this.t('isSerialized'),
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.isSerialized ? '✔' : '✖'
        },
        minWidth: 150,
      },
      {
        headerName: this.t('discountEligible'),
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.discountEligible ? '✔' : '✖'
        },
        minWidth: 200,
      },
      {
        headerName: this.t('sellOutOfStock'),
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.sellOutOfStock ? '✔' : '✖'
        },
        minWidth: 200,
      },
      {
        headerName: this.t('disabled'),
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.disabled ? '✔' : '✖'
        },
        minWidth: 150,
      }
    ]
  },

  mounted() {
  },

  watch: {
    searchTerm() {
      this.filterItems()
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<Item[]>(Item.ENDPOINT)
        this.rowData = data.map((item) => Item.from(item))
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      for (const row of this.rowData) {
        if (row.itemStocks && row.itemStocks.length) {
          row.currentLocationStockQuantity = row.itemStocks[0].quantity
        }
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridApi?.sizeColumnsToFit()
      await this.updateRowData()
    },

    async deleteItems(items: Item[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.deleteConfirm'))) {
        try {
          await this.$http.delete(Item.ENDPOINT, { data: items })
          await this.updateRowData()
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.deleteSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },

    async applyToSelected() {
      if (this.selectedValue === 'delete' || this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()
        if (selected?.length) {
          if (this.selectedValue === 'delete') {
            // send request to archive
            await this.deleteItems(selected)
          } else {
            this.gridApi?.exportDataAsCsv({
              onlySelected: true,
              processCellCallback: this.processCellForExport
            })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }
        }
        this.selectedValue = ''
      }
    },

    processCellForExport(params: ProcessCellForExportParams) {
      if (params.column.getColDef().headerName === 'List Price') {
        return params.node?.data.salePriceDinero
          ? formatDinero(params.node?.data.listPriceDinero)
          : ''
      } else if (params.column.getColDef().headerName === 'Sale Price') {
        return params.node?.data.salePriceDinero
          ? formatDinero(params.node?.data.salePriceDinero)
          : ''
      } else {
        //if the cell doesn't belong to any of the above don't change it
        return params.value
      }
    },

    filterItems() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(this.$Route.INVENTORY_ITEMS_ITEM_VIEW.replace(':id', event.data.id))
    }
  }
})
</script>

<style></style>

import UUIDBase from '@/models/UUIDBase'
import { useI18nInstance } from '@/plugins/i18n'
import Dinero, { Currency, Dinero as DineroType } from 'dinero.js'
import { Transaction } from '../accounting/Transaction'
import Vendor from './Vendor'
import User from '../user/User'

const { t } = useI18nInstance().global

export const vendorBillI18nMessages = {
  en: {
    vendorName: 'Vendor Name',
    dueDate: 'Due Date',
    createdAt: 'Created At',
    totalAmount: 'Total Amount',
    paidAmount: 'Paid Amount',
    balance: 'Balance',
    notes: 'Notes'
  },
  ar: {
    vendorName: 'اسم الموزع',
    dueDate: 'تاريخ الاستحقاق',
    createdAt: 'تاريخ الاصدار',
    totalAmount: 'المبلغ الإجمالي',
    paidAmount: 'المبلغ المدفوع',
    balance: 'الرصيد',
    notes: 'الملاحظات'
  }
}

export class VendorBillPayment extends UUIDBase {
  static ENDPOINT = '/accounting/vendorbills/payments'

  vendorBillID?: string
  vendorBill?: VendorBill
  transactionID?: string
  transaction?: Transaction

  totalDueAmount = 0
  totalDueDinero?: DineroType

  changeDueAmount = 0
  changeDueDinero?: DineroType

  paidBeforeChangeAmount = 0
  paidBeforeChangeDinero?: DineroType

  paymentAmount?: number
  paymentAmountDinero?: DineroType

  currency = ''
  precision = 0

  PayFromAccountID= ""

  static from(json: Record<string, unknown> | VendorBillPayment) {
    const o = Object.assign(new VendorBillPayment(), json)
    o.paymentAmountDinero = Dinero({
      amount: o.paymentAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })

    o.totalDueDinero = Dinero({
      amount: o.totalDueAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })

    o.changeDueDinero = Dinero({
      amount: o.changeDueAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })

    o.paidBeforeChangeDinero = Dinero({
      amount: o.paidBeforeChangeAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })
  }
}

export default class VendorBill extends UUIDBase {
  static from(json: Record<string, unknown> | VendorBill) {
    const o = Object.assign(new VendorBill(), json)
    o.user = User.from(o.user)

    o.totalDueDinero = Dinero({
      amount: o.totalDueAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })

    o.totalPaidDinero = Dinero({
      amount: o.totalPaidAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })

    o.totalBalanceDinero = Dinero({
      amount: o.totalBalanceAmount,
      currency: o.currency as Currency,
      precision: o.precision
    })

    o.otherCostsDinero = Dinero({
      amount: o.otherCostsAmount || 0,
      currency: o.currency as Currency,
      precision: o.precision
    })

    o.vendorBillPayments?.map((i) => {
      const x = VendorBillPayment.from(i)
      return x
    })

    if (o.transactions)
    o.transactions = o.transactions.map((transaction) => {
      return Transaction.from(transaction)
    })

    return o
  }
  static ENDPOINT = '/accounting/vendorbills'
  vendorName = ''
  vendorID?: string
  vendor?: Vendor
  externalID = '' // Bill #
  billDueDate?: Date
  billDate?: Date

  userID?: string
  user = new User()

  totalDueAmount = 0
  totalDueDinero?: DineroType

  totalPaidAmount = 0
  totalPaidDinero?: DineroType

  totalBalanceAmount = 0
  totalBalanceDinero?: DineroType

  otherCostsAmount?: number
  otherCostsDinero?: DineroType

  currency?: string
  precision?: number

  vendorBillPayments?: VendorBillPayment[] //this should be a vendorBillPayment[]
  transactions = Array<Transaction>()
}

<template>
  <div class="h-full">
    <div v-if="loading" class="flex h-full justify-center items-center">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="errorWhileMounting && error.title"
      isDanger
      :title="error.title"
      :body="error.body"
    />

    <div v-else class="flex flex-col md:flex-row">
      <!-- cart place -->
      <div class="w-full mx-2 md:w-9/12 mt-2">
        <alert-badge
          isDanger
          :title="error.title"
          :body="error.body"
          @dismissed="
            () => {
              error.title = ''
              error.body = ''
            }
          "
        />

        <div class="mt-2 center-error">
          <div v-if="searchError">
            {{ searchError }}
          </div>
        </div>
        <div class="mt-2">
          <el-table
            :table-layout="'fixed'"
            ref="returnOrderTable"
            :data="originalOrder.orderItems"
            stripe
            border
            style="width: 100%"
            @selection-change="selectItems"
            v-if="!originalOrder.empty()"
            :max-height="800"
            header-cell-class-name="my-header"
          >
            <el-table-column width="40" type="selection"> </el-table-column>
            <el-table-column :min-width="220" prop="item.name" :label="t('itemName')">
              <template #default="scope">
                <div class="truncate">
                  <span>
                    {{ scope.row.item.name }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              prop="item.soldAtPriceAmount"
              :label="t('soldAtPrice')"
            >
              <template #default="scope">
                <div class="flex justify-between">
                  <span>
                    {{ formatDinero(scope.row.soldAtPriceDinero) }}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              :min-width="150"
              prop="quantity"
              :label="t('purchasedQuantity')"
              align="center"
            >
            </el-table-column>

            <el-table-column
              :min-width="120"
              prop="returnedQuantity"
              :label="t('olderReturns')"
              align="center"
            >
            </el-table-column>

            <el-table-column :min-width="170" prop="quantity" :label="t('toReturnQuantity')">
              <template #default="scope">
                <div class="flex justify-between" v-if="isSelected(scope.row.id)">
                  <el-input-number
                    v-model="returnPayload.orderChangeItems[scope.row.id].quantity"
                    style="width: 115px"
                    :step="1"
                    size="mini"
                    :min="1"
                    :max="
                      originalOrder.orderItems[scope.$index].quantity -
                      originalOrder.orderItems[scope.$index].returnedQuantity
                    "
                  ></el-input-number>
                  <span
                    v-if="scope.row.item.isSerialized"
                    class="text-sm rounded bg-gray-300 h-6 w-6 cursor-pointer hover:bg-gray-400 flex justify-center items-center"
                    @click="openSerialDialog(scope.row, scope.$index)"
                  >
                    <font-awesome-icon icon="barcode" />
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" prop="itemTotal" :label="t('itemTotal')">
              <template #default="scope">
                <template v-if="isSelected(scope.row.id)">
                  {{ formatDinero(computeOrderItemReturnTotal(scope.row)) }}
                </template>
                <template v-else>

                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div
        class="w-full md:w-3/12 mt-4 card"
        style="font-size: 14px !important"
        v-if="!originalOrder.empty()"
      >
        <el-collapse class="px-2 card">
          <el-collapse-item :title="t('orderInfo')" name="orderInfo">
            <order-info :order="originalOrder" />
          </el-collapse-item>
        </el-collapse>

        <div class="flex flex-col card p-3 items-center">
          <div class="flex justify-between w-full p-3 bg-gray-100">
            <p>{{ t('initialToReturnAmount') }}</p>
            <p class="flex justify-end">
              {{ computeAndFormatInitalTotalAmountToReturn() }}
            </p>
          </div>

          <div class="flex justify-between w-full p-3 bg-gray-100">
            <p>{{ t('previouslyPaidAmount') }}</p>
            <p class="flex justify-end">
              {{ formatDinero(originalOrder.computeTotalPaid()) }}
            </p>
          </div>

          <div class="flex justify-between w-full p-3 bg-red-100">
            <p>{{ t('finalToReturnAmount') }}</p>
            <p class="flex justify-end">
              {{ computeAndFormatFinalTotalAmountToReturn() }}
            </p>
          </div>
        </div>

        <div class="w-full p-2">
          <hr class="solid" />
        </div>


        <div class="flex flex-col card p-3 items-center">
          <div class="flex flex-col w-full p-3 border-2 rounded bg-gray-100" :class="{ 'border-b-red-300': invalidTotalReturned}">
            <div class="mb-2"><p>{{ t('returnedByCash') }}</p></div>
            <div class="flex justify-end">
              <money-input currency="IQD" v-model="returnPayload.paidBackByCash"/>
            </div>
          </div>

          <div class="flex flex-col w-full p-3 border-2 rounded mt-2 bg-gray-100" :class="{ 'border-b-red-300': invalidTotalReturned}">
            <div class="mb-2"><p>{{ t('returnedByEpayment') }}</p></div>
            <div class="flex justify-end">
              <money-input currency="IQD" v-model="returnPayload.paidBackByEpayment"/>
            </div>
          </div>
        </div>

        <div class="w-full p-2">
          <hr class="solid" />
        </div>

        <div class="flex justify-center w-full text-black">
          <action-button
            v-if="!originalOrder.empty()"
            faIcon="forward"
            :title="t('buttons.completeReturn')"
            @click.prevent="() => {validate(returnPayload) && openCashierPinDialog()}"
            isSuccess
          />
        </div>
      </div>
    </div>

    <el-dialog
      :title="t('selectSerials')"
      v-model="insertSerialDialogVisible"
      width="30%"
      @close="closeSerialDialog"
    >
      <div class="flex justify-center items-center" :dir="$ctx.getDir()">
        <el-select
          v-if="currentOrderItem.item.id"
          filterable
          clearable
          multiple
          class="w-full"
          v-model="returnPayload.orderChangeItems[currentOrderItem.id || ''].serialIDs"
          :multiple-limit="currentOrderItem.quantity"
        >
          <el-option
            v-for="(item, i) in excludeReturnedSerials(
              originalOrder.orderItems[currentOrderItemIndex].orderItemSerials
            )"
            :key="i"
            :label="item.itemSerial?.number"
            :value="item.itemSerial?.id"
            ref="serialInput"
          >
            <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
              {{ item.itemSerial?.number }}
            </span>
          </el-option>
        </el-select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="closeSerialDialog">{{
            t('buttons.confirm')
          }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="cashierPinDialogOpen" width="30%">
      <div>
        <!-- Enter the pin -->
        <div class="my-2">
          <p>{{ t('enterCashierPin') }}</p>
        </div>

        <!-- password input up to 6 chars -->
        <div class="my-2">
          <el-input
            id="password"
            v-model="cashierPin"
            type="password"
            required
            show-password
            autofocus
            dir="ltr"
            :maxlength="4"
            :minlength="4"
            ref="cashierPinInput"
          />
        </div>

        <!-- finish payment -->
        <div class="flex items-center justify-between mb-2">
          <horizontal-button
            class="text-lg complete-payment"
            :title="t('buttons.completeReturn')"
            @click="submit"
            :rounded="false"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue'

import AlertBadge from '@/components/AlertBadge.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import OrderInfo from './components/OrderInfo.vue'
import { ElCollapse, ElCollapseItem } from 'element-plus'
import ActionButton from '@/components/ActionButton.vue'

// models
import {
  i18nOrderMessages,
  Order,
  OrderChange,
  OrderChangeItemSerial,
  OrderItem,
  OrderItemSerial
} from '@/models/sales/Order'
import Item from '@/models/inventory/Item'
import { Register, RegisterLog } from '@/models/company/Register'

// tools
import { useI18n } from 'vue-i18n'
import { localizeFieldName } from '@/plugins/i18n'

// helpers
import { formatDinero, formatMoney, sumDineros } from '@/utils/money'
import Dinero, { Currency, Dinero as DineroType } from 'dinero.js'

import { ElMessageBox } from 'element-plus'
import HorizontalButton from '@/components/HorizontalButton.vue'
import MoneyInput from '@/components/form/MoneyInput.vue'

function initialState() {
  return {
    error: { title: '', body: '' },
    loading: false,

    originalOrder: new Order(),
    returnPayload: new OrderChange('return'),

    selectedToReturnOrderItemIDs: new Set<string>(),

    currentOrderItem: new OrderItem(new Item()),
    currentOrderItemIndex: 0,
    insertSerialDialogVisible: false,
    newBalance: 0,
  }
}

export default defineComponent({
  name: 'return-order',
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        en: {
          ...i18nOrderMessages.en,

          searchItems: 'Enter receipt number',
          buttons: {
            completeReturn: 'Return',
            confirm: 'Confirm'
          },

          selectSerials: 'Select serials',
          selectCustomer: 'Select a customer',
          selectPaymentType: 'Select order type',

          purchasedQuantity: 'Purchased Quantity',
          olderReturns: 'Older Returns',
          toReturnQuantity: 'Return Quantity',

          orderInfo: 'Order Info',
          returnInfo: 'Return Info',

          outOfStockErr:
            'Item {name} is out of stock. Please update your stock before selling this item',

          successMsg: 'Order return complete',

          errPaymentMustMatch:
            "Payment must be made in full. If you'd like to pay partially, choose layaway or finance",

          orderAlreadyReturned: 'All items in this order have been returned',
          OK: 'Yes',
          Cancel: 'Cancel',
          confirmReturn: 'Are you sure?',
          enterCashierPin: 'Enter cashier pin',
          errCashierPinRequired: 'Cashier pin is required',
          returnedByCash: "Total returned from register",
          returnedByEpayment: "Total returned via e-Payment device",
          errInvalidReturnedMoney: "Total returned by cash + total returned by e-Payment device does NOT equal the total to return to customer",

          emptyReturn: "Please select at least one item to return",
        },
        ar: {
          ...i18nOrderMessages.ar,

          searchItems: 'ادخل رقم الوصل',
          buttons: {
            completeReturn: 'اتمام الارجاع',
            confirm: 'التأكيد'
          },

          selectSerials: 'اختر الارقام',
          selectCustomer: 'اختر زبون',
          selectPaymentType: 'اختر نوع البيع',

          purchasedQuantity: 'الكمية المباعة',
          olderReturns: 'المرجع مسبقاً',
          toReturnQuantity: 'كمية الترجيع الحالي',

          orderInfo: 'معلومات الطلب',
          returnInfo: 'معلومات الترجيع',

          outOfStockErr: 'نفذ مخزون المنتج {name}. يرجى اعادة تحديث المخزن قبل بيع المنتج.',

          successMsg: 'تم ارجاع المنتجات بنجاح',

          errPaymentMustMatch:
            'يجب ان يتم دفع المبلغ بشكل كامل. لدفع مبلغ جزئي، قم بتحويل نوع الدفع الى دين او عربون',

          orderAlreadyReturned: 'تم ارجاع جميع المنتجات في هذا الطلب مسبقا',
          OK: 'نعم',
          Cancel: 'الغاء',
          confirmReturn: 'هل انت متأكد؟',
          enterCashierPin: 'ادخل رمز الكاشير الخاص بك',
          errCashierPinRequired: 'يرجى ادخال رمز الكاشير لاتمام البيع',

          returnedByCash: "المرجع من صندوق النقد",
          returnedByEpayment: "المرجع من جهاز النقد الالكتروني",

          errInvalidReturnedMoney: "مبلغ الترجيع الاجمالي لا يساوي مجموع مبلغ الترجيع النقدي ومبلغ الترجيع الالتكتروني",

          emptyReturn: "يجب اختيار منتج للترجيع",
        }
      }
    })

    const returnOrderTable = ref({
      // will be replaced at runtime by the real deal
      toggleAllSelection() {
        // do nothing
      }
    })

    const errorWhileMounting = ref(false)
    const searchError = ref()
    const cashierPinDialogOpen = ref(false)
    const cashierPin = ref('')
    const cashierPinInput = ref()

    function openCashierPinDialog() {
      cashierPinDialogOpen.value = true
    }

    return {
      t,
      returnOrderTable,
      errorWhileMounting,
      searchError,
      cashierPin,
      cashierPinInput,
      cashierPinDialogOpen,
      openCashierPinDialog
    }
  },

  components: {
    MoneyInput,
    AlertBadge,
    LoadingSpinner,
    OrderInfo,
    ElCollapse,
    ElCollapseItem,
    ActionButton,
    HorizontalButton
  },

  async mounted() {
    // load register status
    try {
      this.loading = true
      const registerLog = await this.$http.get<RegisterLog>(
        `${Register.ENDPOINT}/status/${this.$ctx.currentRegister.id}`
      )

      this.$ctx.updateRegisterStatus(RegisterLog.from(registerLog).action)

      if (registerLog.action === 'close') {
        this.$router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER)
      }

      this.fetchOrder(String(this.$route.params.orderID))
    } catch (error) {
      this.error.title = error.title
      this.error.body = error.body
      this.errorWhileMounting = true
    } finally {
      this.loading = false
    }
  },

  data() {
    return initialState()
  },

  computed: {
    invalidTotalReturned() {
      return this.returnPayload.totalChangeAmount != (this.returnPayload.paidBackByCash + this.returnPayload.paidBackByEpayment)
    },
  },

  methods: {
    formatDinero,
    formatMoney,

    openSerialDialog(oi: OrderItem, oiIndex: number) {
      this.currentOrderItem = oi
      this.currentOrderItemIndex = oiIndex
      this.insertSerialDialogVisible = true
    },

    closeSerialDialog() {
      this.currentOrderItem = new OrderItem(new Item())
      this.insertSerialDialogVisible = false
    },

    excludeReturnedSerials(orderItemSerials: OrderItemSerial[]) {
      return orderItemSerials.filter((ois) => !ois.returned)
    },

    excludeFullyReturnedItems(order: Order) {
      return order.orderItems.filter((oi) => oi.quantity > oi.returnedQuantity)
    },

    async fetchOrder(orderID: string) {
      let order: any
      try {
        const o = await this.$http.get<Order>(`${Order.ENDPOINT}/${orderID}`)
        order = Order.from(o)
      } catch (error) {

        this.$alertModal.showDanger({ title: error.title, body: error.body })
      }

      // reset state
      Object.assign(this.$data, initialState())
      this.originalOrder = Order.from(order)
      const returnableItems = this.excludeFullyReturnedItems(this.originalOrder as Order)
      if (returnableItems.length < 1) {
        this.$alertModal.showDanger({
          title: this.t('orderAlreadyReturned')
        })
      }
      this.originalOrder.orderItems = returnableItems

      this.returnPayload.orderID = this.originalOrder.id || ''
      this.originalOrder.orderItems.forEach((oi) => {
        const serials: Record<string, OrderChangeItemSerial> = {}

        this.returnPayload.orderChangeItems[oi.id || ''] = {
          quantity: oi.quantity - oi.returnedQuantity,
          orderChangeItemSerials: serials,
          serialIDs: new Array<string>()
        }
      })

      // select all
      setTimeout(() => {
        if (this.returnOrderTable) {
          this.returnOrderTable.toggleAllSelection()
        }
      }, 100)
    },

    selectItems(selected: OrderItem[]) {
      this.selectedToReturnOrderItemIDs.clear()
      selected.forEach((oi) => this.selectedToReturnOrderItemIDs.add(oi.id || ''))
    },

    isSelected(orderItemID: string): boolean {
      return this.selectedToReturnOrderItemIDs.has(orderItemID)
    },

    computeOrderItemReturnTotal(orderItem: OrderItem) {
      const returnedQuantity = this.returnPayload.orderChangeItems[orderItem.id || ''].quantity
      return orderItem.soldAtPriceDinero!.multiply(returnedQuantity)
    },

    // for frontend purposes only. This total is being re-compouted on the backend.
    computeAndFormatInitalTotalAmountToReturn() {
      if (!this.originalOrder.empty()) {
        const dineros: DineroType[] = []

        this.originalOrder.orderItems.forEach((oi) => {
          if (oi.id && this.isSelected(oi.id)) {
            dineros.push(this.computeOrderItemReturnTotal(oi as OrderItem))
          }
        })

        return formatDinero(
          sumDineros(dineros, this.originalOrder.currency, this.originalOrder.precision)
        )
      }
    },

    computeAndFormatFinalTotalAmountToReturn2() {
      if (!this.originalOrder.empty()) {
        let totalPrice = 0
        let totalPriceForEligibleItems = 0
        for (const oi of this.originalOrder.orderItems) {
          if (oi.item.discountEligible) {
            totalPriceForEligibleItems +=
              oi.itemSalePriceAmount * (oi.quantity - oi.returnedQuantity)
          } else {
            totalPrice += oi.itemSalePriceAmount * (oi.quantity - oi.returnedQuantity)
          }
        }
        const percentage =
          this.originalOrder.invoice.totalDiscountAmount! / totalPriceForEligibleItems

        if (percentage > 0) {
          // now find final price for each order items
          let finalTotalPrice = 0
          for (const oi of this.originalOrder.orderItems) {
            if (!oi.item.discountEligible) {
              finalTotalPrice += oi.itemSalePriceAmount * (oi.quantity - oi.returnedQuantity)
            } else {
              const oiPriceForOneItem = oi.itemSalePriceAmount
              const oiFinalPriceForOneItem = oiPriceForOneItem - oiPriceForOneItem * percentage
              const oiFinalPrice = oiFinalPriceForOneItem * (oi.quantity - oi.returnedQuantity)
              finalTotalPrice += Math.round(oiFinalPrice)
            }
          }

          return formatDinero(
            Dinero({
              amount: Math.round(finalTotalPrice),
              currency: this.originalOrder.invoice.currency as Currency,
              precision: this.originalOrder.invoice.precision
            })
          )
        }
      }
    },

    computeAndFormatFinalTotalAmountToReturn() {
      if (!this.originalOrder.empty()) {
        const dineros: DineroType[] = []

        this.originalOrder.orderItems.forEach((oi) => {
          if (oi.id && this.isSelected(oi.id)) {
            dineros.push(this.computeOrderItemReturnTotal(oi as OrderItem))
          }
        })

        const totalReturnValue = sumDineros(dineros, this.originalOrder.currency, this.originalOrder.precision)
        const balance = this.originalOrder.invoice.totalBalanceAmount
        const toReturnAmount = Math.max(totalReturnValue.getAmount() - balance, 0)

        this.returnPayload.totalChangeAmount = toReturnAmount;

        return formatMoney(toReturnAmount, "IQD")
      }
    },


    preparePayload(): OrderChange {
      // copy the return payload
      const returnOrderPayload: OrderChange = OrderChange.from(this.returnPayload)

      // only keep order items that have been selected
      for (const orderItemID of Object.keys(returnOrderPayload.orderChangeItems)) {
        if (!this.isSelected(orderItemID)) {
          delete returnOrderPayload.orderChangeItems[orderItemID]
        }
      }

      // move order item serial ids to the correct location
      for (const orderItem of Object.values(returnOrderPayload.orderChangeItems)) {
        orderItem.serialIDs.forEach((id: string) => (orderItem.orderChangeItemSerials[id] = {}))
      }

      // attach neccessary info
      returnOrderPayload.userID = this.$ctx.currentUser.id || ''
      returnOrderPayload.locationID = this.$ctx.currentLocation.id || ''
      returnOrderPayload.registerID = this.$ctx.currentRegister.id || ''

      // return
      return returnOrderPayload
    },

    // having validate here simplifes things
    validate(payload: OrderChange) {
      const errors: string[] = []

      if (this.selectedToReturnOrderItemIDs.size === 0) {
        errors.push(this.t("emptyReturn"))
      }

      this.originalOrder.orderItems.forEach((oi) => {
        if (oi.id && oi.id in payload.orderChangeItems) {
          const orderChangeItem = payload.orderChangeItems[oi.id]
          if (
            oi.orderItemSerials.length &&
            orderChangeItem.quantity !== Object.keys(orderChangeItem.orderChangeItemSerials).length
          ) {
            const errMsg = `${oi.item.name}: ${this.t('validation.matchingLength', {
              thisField: localizeFieldName('lenSerialNumbers', i18nOrderMessages),
              thatField: localizeFieldName('quantity', i18nOrderMessages)
            })}`
            errors.push(errMsg)
          }
        }
      })

      if (this.invalidTotalReturned) {
        errors.push(this.t("errInvalidReturnedMoney"))
      }

      if (errors.length) {
        this.error.title = this.t('validation.inputErrors')
        this.error.body = errors.join('\n')
        return false
      }

      return true
    },

    async submit() {
      this.cashierPinDialogOpen = false
      if (this.cashierPin === '') {
        this.error.title = this.t('errCashierPinRequired')
        return
      }

      ElMessageBox.confirm(this.t('confirmReturn'), 'Warning', {
        confirmButtonText: this.t('OK'),
        cancelButtonText: this.t('Cancel'),
        type: 'warning'
      })
        .then(async (_) => {
          const payload = this.preparePayload()
          if (this.validate(payload)) {
            try {
              const o = await this.$http.put<OrderChange>(Order.ENDPOINT, payload, {
                headers: { pin: this.cashierPin }
              })
              this.$alertModal.showSuccess({ title: this.t('successMsg') })
              const orderChange = OrderChange.from(o)
              await this.$router.push(
                this.$Route.SALES_ORDERS_RETURN_ORDER_RECEIPT.replace(
                  ':id',
                  this.originalOrder.id || ''
                ).replace(':orderChangeID', orderChange.id || '')
              )
            } catch (error) {
              this.error.title = error.title
              this.error.body = error.body
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
})
</script>

<style scoped>
.card {
  background: var(--secondary-bg-color);
}

.center-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

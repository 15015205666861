<template>
  <el-form
    @submit.prevent="validateSubmit('employeeForm')"
    ref="employeeForm"
    :model="employee"
    :rules="rules"
    label-position="top"
    novalidate
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('employeeForm')"
          />
        </div>

        <div class="md:w-4/12 m-2" v-if="enableClearForm">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('employeeForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <input-section>
      <h1>{{ t('sections.generalInfo') }}</h1>

      <input-group>
        <el-form-item :label="t('firstName')" prop="firstName" class="input-label">
          <el-input
            id="name"
            v-model="employee.firstName"
            :placeholder="t('placeholders.firstName')"
            maxlength="255"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('lastName')" prop="lastName" class="input-label">
          <el-input
            id="name"
            v-model="employee.lastName"
            :placeholder="t('placeholders.lastName')"
            maxlength="255"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="t('role')" prop="role" class="input-label">
          <el-select class="w-full" v-model="employee.role">
            <el-option
              v-for="role in ['casher', 'optometrist', 'other']"
              :key="role"
              :label="t(role)"
              :value="role"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </input-group>

      <input-group>
        <el-form-item :label="t('phone')" prop="phone" class="input-label">
          <el-input
            id="phone"
            v-model="employee.phone"
            :placeholder="t('placeholders.phone')"
            maxlength="15"
            dir="ltr"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('email')" prop="email" class="input-label">
          <el-input
            id="email"
            v-model="employee.email"
            type="email"
            :placeholder="t('placeholders.email')"
            dir="ltr"
            maxlength="255"
          >
          </el-input>
        </el-form-item>
      </input-group>

      <el-form-item :label="t('streetAddress')" prop="streetAddress" class="input-label">
        <el-input
          id="streetAddress"
          v-model="employee.streetAddress"
          :placeholder="t('placeholders.streetAddress')"
          show-word-limit
          maxlength="255"
          clearable
        >
        </el-input>
      </el-form-item>

      <input-group>
        <el-form-item :label="t('city')" prop="city" class="input-label">
          <el-input
            id="city"
            v-model="employee.city"
            :placeholder="t('placeholders.city')"
            maxlength="70"
            show-word-limit
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('postalCode')" prop="postalCode" class="input-label">
          <el-input
            id="postalCode"
            v-model="employee.postalCode"
            :placeholder="t('placeholders.postalCode')"
            show-word-limit
            maxlength="10"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('country')" prop="country" class="input-label">
          <el-input
            id="country"
            :placeholder="t('placeholders.country')"
            maxlength="70"
            show-word-limit
            clearable
            v-model="employee.country"
          >
          </el-input>
        </el-form-item>
      </input-group>
    </input-section>

    <input-section>
      <h1>{{ t('sections.employmentInfo') }}</h1>

      <el-form-item :label="t('employeeTitle')" prop="title" class="input-label">
        <el-input
          id="title"
          v-model="employee.employeeTitle"
          :placeholder="t('placeholders.title')"
          maxlength="127"
          show-word-limit
          clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item :label="t('employeeSalaryAmount')" prop="salaryAmount" class="input-label">
        <money-input
          id="salary-amount"
          :currency="$ctx.currency"
          v-model="employee.employeeSalaryAmount"
          @dinero-created="(dinero) => (employee.salaryDinero = dinero)"
        />
      </el-form-item>

      <input-group>
        <el-form-item :label="t('employeeStartDate')" prop="employeeStartDate" class="input-label">
          <el-date-picker
            id="start-date"
            v-model="employee.employeeStartDate"
            type="date"
            :placeholder="t('employeeStartDate')"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item :label="t('employeeEndDate')" prop="employeeEndDate" class="input-label">
          <el-date-picker
            id="end-date"
            v-model="employee.employeeEndDate"
            type="date"
            :placeholder="t('employeeEndDate')"
          >
          </el-date-picker>
        </el-form-item>
      </input-group>
    </input-section>

    <input-section>
      <h1>{{ t('sections.loginInfo') }}</h1>
      <el-form-item :label="t('username')" prop="username" class="input-label">
        <el-input
          id="username"
          :placeholder="t('placeholders.username')"
          maxlength="255"
          show-word-limit
          clearable
          v-model="employee.username"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="t('password')" prop="password" class="input-label">
        <el-input
          id="password"
          :placeholder="t('placeholders.password')"
          maxlength="255"
          show-word-limit
          clearable
          v-model="employee.newPassword"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="t('pin')" prop="pin" class="input-label">
        <el-input
          id="pin"
          :placeholder="t('placeholders.pin')"
          maxlength="4"
          show-word-limit
          clearable
          v-model="employee.newPin"
        >
        </el-input>
      </el-form-item>
    </input-section>

    <!-- start permissions -->
<!--    <input-section>-->
<!--      <h1>{{ t('sections.permissions') }}</h1>-->

<!--      <el-form-item :label="t('predefined permission group')" class="input-label">-->
<!--        <el-select-->
<!--          class="w-full"-->
<!--          v-model="permissionDropdownValue"-->
<!--          @change="changePermissionGroup"-->
<!--          value-key="value"-->
<!--        >-->
<!--          <el-option-->
<!--            v-for="permGroup in predefinedPermissionGroups"-->
<!--            :key="permGroup.id"-->
<!--            :label="permGroup.id"-->
<!--            :value="permGroup.id"-->
<!--          >-->
<!--            <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">-->
<!--              {{ permGroup.id }}-->
<!--            </span>-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->

<!--      <div class="permissions-container">-->
<!--        <div class="permissions-header">-->
<!--          <div>{{ t('permGroup') }}</div>-->
<!--          <div>{{ t('actions.create') }}</div>-->
<!--          <div>{{ t('actions.read') }}</div>-->
<!--          <div>{{ t('actions.update') }}</div>-->
<!--          <div>{{ t('actions.delete') }}</div>-->
<!--        </div>-->

<!--        <div class="permissions-group" v-for="(pg, i) in prmGroups" :key="i">-->
<!--          <div class="group-title">-->
<!--            {{ t('permissions.' + pg.group) }}-->
<!--          </div>-->
<!--          <div class="group-routes">-->
<!--            <div class="group-route" v-for="(pr, j) in pg.permission_routes" :key="j">-->
<!--              <div class="route-title">-->
<!--                {{ t('permissions.' + pr.route) }}-->
<!--              </div>-->
<!--              <div class="route-actions">-->
<!--                <div class="route-action" v-for="(p, k) in pr.permissions" :key="k">-->
<!--                  <div style="padding: 0 10px">-->
<!--                    <el-checkbox v-model="p.checked" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </input-section>-->
    <!-- end permissions -->

    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('employeeForm')"
          />
        </div>

        <div class="md:w-4/12 m-2" v-if="enableClearForm">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('employeeForm')"
          />
        </div>
      </div>
    </input-section>
  </el-form>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'

// components
import AlertBadge from '@/components/AlertBadge.vue'
import HorizontalButton from '@/components/HorizontalButton.vue'
import InputSection from '@/components/form/InputSection.vue'
import InputGroup from '@/components/form/InputGroup.vue'
import { ElForm } from 'element-plus'

// models
import User, { userI18nMessages, UserPermission } from '@/models/user/User'
import { useI18n } from 'vue-i18n'
import MoneyInput from '@/components/form/MoneyInput.vue'

// data
import {
  cashierPermissions,
  defaultAllFalse,
  managerPermissions,
  ownerPermissions,
  PermGroup
} from './predefinedPermissionGroups'
import PermissionGroup from '@/models/hr/Permission'

export default defineComponent({
  name: 'employee-form',

  setup() {
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        en: {
          ...userI18nMessages.en,
          password: 'Password',
          pin: 'Cashier Pin',

          sections: {
            generalInfo: 'General Information',
            employmentInfo: 'Employment Information',
            loginInfo: 'Login Information',
            permissions: 'Permissions'
          },

          placeholders: {
            firstName: 'Example: Ahmed',
            lastName: 'Example: Al-Iraqi',
            phone: 'Example: 077011111111',
            email: 'Example: ahmed@gmail.com',
            password: '123',
            pin: '4432',
            streetAddress: 'Example: Next to Almansour Mall',
            city: 'Example: Baghdad',
            postalCode: 'Example: 10015',
            country: 'Example: Iraq',
            title: 'Example: Sales Rep',
            salary: 'Example: IQD 1,000,000',
            username: 'Example: ahmedjoubouri'
          },

          permGroup: 'Permission Group',

          actions: {
            create: 'Create',
            read: 'Read',
            update: 'Update',
            delete: 'Delete'
          },
          permissions: {
            accounting: 'Accounting',
            '/accounting/accounts': 'Accounts',
            '/accounting/transactions': 'Transactions',
            companyManagement: 'Company Management',
            '/company/registers/close': 'Close Register',
            '/company/registers/open': 'Open Register',
            customers: 'Customers',
            '/customer/customers': 'Customers',
            hr: 'Human Resources',
            '/hr/employeeclocks': 'Clock in/Clock out',
            '/hr/employees': 'Employees',
            '/hr/employeeschedule': 'Employee Schedule',
            inventory: 'Inventory',
            '/inventory/inventoryadjustments': 'Inventory Adjustment',
            '/inventory/inventoryreceivings': 'Inventory Receiving',
            '/inventory/inventorytransfers': 'Inventory Transfer',
            '/inventory/itembrands': 'Brands',
            '/inventory/itemcategories': 'Categories',
            '/inventory/items': 'Items',
            '/inventory/itemtags': 'Tags',
            sales: 'Sales',
            '/sales/orderpayments': 'Order Payments',
            '/sales/orders': 'Orders',
            '/sales/returnorders': 'Return Orders',
            users: 'Employees',
            '/user/users': 'Employees',
            vendors: 'Vendors',
            '/accounting/registerstatus': 'Register Status',
            '/vendor/vendors': 'Vendors',
            '/accounting/company': 'Company',
            '/accounting/company/expensePayee': 'Expense Payee',
            '/accounting/company/expenseType': 'Expense Type',
            '/accounting/customerinvoices': 'Customer Invoices',
            '/accounting/customerinvoices/payments': 'Customer Invoices Payments',
            '/accounting/hr': 'HR',
            '/accounting/postings': 'Postings',
            '/accounting/registertransactions': 'Register Transactions',
            '/accounting/vendorbills': 'Vendor Bills',
            '/accounting/vendorbills/payments': 'Vendor Bills Payments',
            company: 'Company',
            '/company/locations': 'Locations',
            '/company/machines': 'Machines',
            '/company/register-actions': 'Register Actions',
            '/company/registers': 'Registers',
            '/company/registers/compute-closing-amount': 'Compute Register Closing Amount',
            '/company/registers/status': 'Registers Status',
            customer: 'Customer',
            helpers: 'Helpers',
            '/helpers/count': 'Count Rows',
            '/inventory/inventorytransactions': 'Inventory Transactions',
            reports: 'Reports',
            '/reports/accounting/profit-and-loss': 'Profit And Loss',
            '/reports/customer/customer-balance': 'Customer Balance',
            '/reports/customer/top-customers': 'Top Customers',
            '/reports/inventory-quantity': 'Inventory Quantity',
            '/reports/inventory-value': 'Inventory Value',
            '/reports/sales/end-of-day': 'End Of Day',
            '/reports/sales/heatmap': 'Heatmap',
            '/reports/sales/least-sold-items': 'Least Sold Items',
            '/reports/sales/sales-by-category': 'Sales By Category',
            '/reports/sales/sales-by-item': 'Sales By Item',
            '/reports/sales/sales-comparison': 'Sales Comparison',
            '/reports/sales/sales-summary': 'Sales Summary',
            '/reports/sales/top-sold-items': 'Top Sold Items',
            '/reports/vendor/top-vendors': 'Top Vendors',
            '/reports/vendor/vendor-balance': 'Vendor Balance',
            settings: 'Settings',
            '/settings': 'Global Settings',
            '/settings/permissions': 'Permissions',
            user: 'User',
            '/user/permissionusers': 'User Permissions',
            vendor: 'Vendor'
          },
          role: 'Role',
          casher: 'Casher',
          optometrist: 'Optometrist',
          other: 'Other'
        },
        ar: {
          ...userI18nMessages.ar,
          password: 'رمز الدخول',
          pin: 'رمز الكاشير',

          sections: {
            generalInfo: 'المعلومات العامة',
            employmentInfo: 'المعلومات الوظيفية',
            loginInfo: 'تسجيل الدخول',
            permissions: 'تحديد الصلاحيات'
          },

          placeholders: {
            name: 'مثلا: شركة الحمزة للمنتجات الغذائية',
            firstName: 'مثلا: احمد',
            lastName: 'مثلا: العراقي',
            phone: 'مثلا: 077011111111',
            email: 'مثلا: ahmed@gmail.com',
            password: 'Password123',
            pin: '4432',
            streetAddress: 'مثلا: مجاور لمول المنصور',
            city: 'مثلا: بغداد',
            postalCode: 'مثلا: 10015',
            country: 'مثلا: العراق',
            title: 'مثلا: موظف مبيعات',
            salary: 'مثلا: 1,000,000',
            username: 'مثلا: ahmedjoubouri'
          },

          permGroup: 'الصلاحية',

          actions: {
            create: 'انشاء',
            read: 'قراءة',
            update: 'تحديث',
            delete: 'مسح'
          },
          permissions: {
            accounting: 'المحاسبة',
            '/accounting/accounts': 'الحسابات',
            '/accounting/transactions': 'التحركات المالية',
            companyManagement: 'ادارة الشركة',
            '/company/registers/close': 'غلق الخزانة',
            '/company/registers/open': 'فتح الخزانة',
            customers: 'الزبائن',
            '/customer/customers': 'الزبائن',
            hr: 'الموارد البشرية',
            '/hr/employeeclocks': 'تسجيل الدخول والخروج',
            '/hr/employees': 'ادارة الموظفين',
            '/hr/employeeschedule': 'جدول الموظفين',
            inventory: 'المخزن',
            '/inventory/inventoryadjustments': 'تعديل البضاعة',
            '/inventory/inventoryreceivings': 'استلام البضاعة',
            '/inventory/inventorytransfers': 'تحويل البضاعة',
            '/inventory/itembrands': 'العلامات التجارية',
            '/inventory/itemcategories': 'المجاميع',
            '/inventory/items': 'المنتجات',
            '/inventory/itemtags': 'الوسوم',
            sales: 'المبيعات',
            '/sales/orderpayments': 'دفعات المبيعات',
            '/sales/orders': 'المبيعات',
            '/sales/returnorders': 'ارجاع المبيعات',
            users: 'الموظفين',
            '/user/users': 'الموظفين',
            vendors: 'الموزعين',
            '/accounting/registerstatus': 'حالة الخزنة',
            '/vendor/vendors': 'الموزعين',
            '/accounting/company': 'الشركات',
            '/accounting/company/expensePayee': 'المصاريف المدفوعة',
            '/accounting/company/expenseType': 'انواع المصاريف',
            '/accounting/customerinvoices': 'فواتير الزبائن',
            '/accounting/customerinvoices/payments': 'مدفوعات فواتير الزبائن',
            '/accounting/hr': 'الموارد البشرية',
            '/accounting/postings': 'Postings',
            '/accounting/registertransactions': 'الحراكات المالية لصناديق النقد',
            '/accounting/vendorbills': 'مصاريف الموزعين',
            '/accounting/vendorbills/payments': 'مدفوعات مصاريف الموزعين',
            company: 'الشركة',
            '/company/locations': 'المتاجر',
            '/company/machines': 'الاجهزة',
            '/company/register-actions': 'عمليات صناديق النقد',
            '/company/registers': 'صناديق النقد',
            '/company/registers/compute-closing-amount': 'حساب مبلغ اغلاق الخزنة',
            '/company/registers/status': 'حالة صناديق النقد',
            customer: 'الزبون',
            helpers: 'المساعدة',
            '/helpers/count': 'حساب عدد الصفوف',
            '/inventory/inventorytransactions': 'عمليات المخزون',
            reports: 'التقارير',
            '/reports/accounting/profit-and-loss': 'الربح والخسارة',
            '/reports/customer/customer-balance': 'رصيد الزبون',
            '/reports/customer/top-customers': 'افضل الزبائن',
            '/reports/inventory-quantity': 'كمية المخزون',
            '/reports/inventory-value': 'قيمة المخزون',
            '/reports/sales/end-of-day': 'نهاية اليوم',
            '/reports/sales/heatmap': 'خريطة الحرارة',
            '/reports/sales/least-sold-items': 'المنتجات الاقل مبيعا',
            '/reports/sales/sales-by-category': 'المبيعات حسب الفئة',
            '/reports/sales/sales-by-item': 'المبيعات حسب المنتج',
            '/reports/sales/sales-comparison': 'مقارنة المبيعات',
            '/reports/sales/sales-summary': 'ملخص المبيعات',
            '/reports/sales/top-sold-items': 'اكثر المنتجات بيعا',
            '/reports/vendor/top-vendors': 'افضل المخازن',
            '/reports/vendor/vendor-balance': 'رصيد المخزن',
            settings: 'الاعدادات',
            '/settings': 'الاعدادات العامة',
            '/settings/permissions': 'الصلاحيات',
            user: 'المستخدم',
            '/user/permissionusers': 'صلاحيات المستخدم',
            vendor: 'المخزن'
          },
          role: 'الوظيفة',
          casher: 'كاشير',
          optometrist: 'طبيب الفحص',
          other: 'اخرى'
        }
      }
    })

    const rules = {
      firstName: [
        { required: true, message: t('validation.required') },
        { max: 127, message: t('validation.maxLength', { len: 127 }) }
      ],
      lastName: [
        { required: true, message: t('validation.required') },
        { max: 127, message: t('validation.maxLength', { len: 127 }) }
      ],
      phone: [{ max: 15, message: t('validation.maxLength', { len: 15 }) }],
      email: [{ max: 255, message: t('validation.maxLength', { len: 255 }) }],
      streetAddress: [{ max: 255, message: t('validation.maxLength', { len: 255 }) }],
      city: [{ max: 70, message: t('validation.maxLength', { len: 70 }) }],
      postalCode: [{ max: 10, message: t('validation.maxLength', { len: 10 }) }],
      country: [{ max: 70, message: t('validation.maxLength', { len: 70 }) }],
      employeeStartDate: [{ required: true, message: t('validation.required') }],
      username: [
        { required: true, message: t('validation.required') },
        { max: 255, message: t('validation.maxLength', { len: 255 }) }
      ]
    }

    const predefinedPermissionGroups = [
      {
        id: 'allFalse',
        value: defaultAllFalse
      },
      {
        id: 'owner',
        value: ownerPermissions
      },
      {
        id: 'manager',
        value: managerPermissions
      },
      {
        id: 'cashier',
        value: cashierPermissions
      }
    ]

    const permissionDropdownValue = ref('')

    return {
      t,
      rules,
      predefinedPermissionGroups,
      permissionDropdownValue
    }
  },

  components: {
    MoneyInput,
    AlertBadge,
    HorizontalButton,
    InputSection,
    InputGroup
  },
  props: {
    initEmployee: {
      type: User,
      required: true
    },
    permissionGroups: {
      type: Array as PropType<Array<PermissionGroup>>,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      employee: this.initEmployee,
      prmGroups: this.permissionGroups,
      error: { title: '', body: '' }
    }
  },

  mounted() {
    // update permissions
    this.employee.userPermissions.forEach((p) => {
      this.prmGroups.forEach((_, i) => {
        this.prmGroups[i].permission_routes?.forEach((_, j) => {
          this.prmGroups[i].permission_routes![j].permissions.forEach((pr, k) => {
            if (p.permission.id == this.prmGroups[i].permission_routes![j].permissions[k].id) {
              this.prmGroups[i].permission_routes![j].permissions[k].checked = true
            }
          })
        })
      })
    })
  },

  methods: {
    makeTitle({ row }: { row: { groupName: string } }) {
      return row?.groupName ? [1, 5] : [1, 1]
    },

    changePermissionGroup(value: any) {
      this.prmGroups.forEach((_, i) => {
        this.prmGroups[i].permission_routes?.forEach((_, j) => {
          this.prmGroups[i].permission_routes![j].permissions.forEach((pr, k) => {
            this.prmGroups[i].permission_routes![j].permissions[k].checked = false
          })
        })
      })

      this.predefinedPermissionGroups.forEach((e) => {
        if (e.id == this.permissionDropdownValue) {
          e.value.forEach((pg) => {
            this.prmGroups.forEach((_, i) => {
              this.prmGroups[i].permission_routes?.forEach((_, j) => {
                this.prmGroups[i].permission_routes![j].permissions.forEach((pr, k) => {
                  if (pg.object == this.prmGroups[i].permission_routes![j].permissions[k].object) {
                    if (pg.grantedActions![0]) {
                      if (
                        this.prmGroups[i].permission_routes![j].permissions[k].action == 'create'
                      ) {
                        this.prmGroups[i].permission_routes![j].permissions[k].checked = true
                      }
                    }
                    if (pg.grantedActions![1]) {
                      if (this.prmGroups[i].permission_routes![j].permissions[k].action == 'read') {
                        this.prmGroups[i].permission_routes![j].permissions[k].checked = true
                      }
                    }
                    if (pg.grantedActions![2]) {
                      if (
                        this.prmGroups[i].permission_routes![j].permissions[k].action == 'update'
                      ) {
                        this.prmGroups[i].permission_routes![j].permissions[k].checked = true
                      }
                    }
                    if (pg.grantedActions![3]) {
                      if (
                        this.prmGroups[i].permission_routes![j].permissions[k].action == 'delete'
                      ) {
                        this.prmGroups[i].permission_routes![j].permissions[k].checked = true
                      }
                    }
                  }
                })
              })
            })
          })
        }
      })
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      form.resetFields()
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        }
        // populate user permissions
        this.employee.userPermissions = []
        this.prmGroups.forEach((_, i) => {
          this.prmGroups[i].permission_routes?.forEach((_, j) => {
            this.prmGroups[i].permission_routes![j].permissions.forEach((pr, k) => {
              if (pr.checked) {
                this.employee.userPermissions.push(new UserPermission(pr.action, pr.object || ''))
              }
            })
          })
        })

        const resp = await this.submitAction(this.employee)

        if (resp) {
          this.error.title = resp?.title
          this.error.body = resp?.body
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName)
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: '' })
        }
        return true
      })
    }
  }
})
</script>

<style scoped>
form > h1 {
  @apply text-lg py-3 font-bold;
}

form > h2 {
  @apply py-3 font-bold;
}

formt > h3 {
  @apply py-3 font-bold;
}

form {
  @apply flex flex-col items-center;
}

.permissions-container {
  background-color: #fff;
  overflow: hidden;
}

.permissions-header {
  font-size: 14px;
  font-weight: 800;
  display: grid;
  color: #606266;
  grid-template-columns: 240px 1fr 1fr 1fr 1fr;
}

.permissions-header > div {
  padding: 12px;
  border: 1px solid #dddddd;
}

.permissions-header > div:nth-child(n + 2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.permissions-group > .group-title {
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #606266;

  border: 1px solid #dddddd;
}

.permissions-group > .group-routes > .group-route {
  display: grid;
  grid-template-columns: 240px 1fr;
}

.permissions-group > .group-routes > .group-route:hover {
  background-color: #f7f7f7bb;
}

.permissions-group > .group-routes > .group-route > .route-title {
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #606266;

  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
}

.permissions-group > .group-routes > .group-route > .route-actions {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.permissions-group > .group-routes > .group-route > .route-actions > .route-action {
  padding: 12px;

  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

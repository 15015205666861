<template>
  <el-dialog width="30%" v-model="selectCustomerDialogVisiable" :title="t('selectCustomer')">
    <div class="flex items-center justify-center" :dir="$ctx.getDir()">
      <el-select
        filterable
        clearable
        class="w-full"
        value-key="contactDisplayName"
        v-model="selectedCustomerElSelect"
        remote
        :remote-method="searchCustomers"
      >
        <el-option
          v-for="option in customers"
          :key="option.id"
          :label="option.contactDisplayName"
          :value="option"
        ></el-option>
      </el-select>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="
            () => {
              selectCustomerDialogVisiable = false
              newCustomerDialogVisiable = true
            }
          "
          type="secondary"
          ><span class="fa fa-plus"></span> {{ t('views.customers.newCustomer') }}</el-button
        >
        <el-button
          :disabled="!selectedCustomerElSelect"
          @click="confirmSelectCustomer()"
          type="primary"
          >{{ t('confirm') }}</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="newCustomerDialogVisiable" :title="t('views.customers.newCustomer')">
    <NewCustomerDialog @success="onCustomerCreater"></NewCustomerDialog>
  </el-dialog>
  <el-form
    @submit.prevent="validateSubmit('examinationForm')"
    :class="{ ar: locale == 'ar' }"
    ref="examinationForm"
    :model="examination"
    :rules="rules"
    label-position="top"
    class="m-8 bg-white shadow-md p-4 gap-1 examination-form"
    style="direction: ltr"
    novalidate
  >
    <div
      class="self-start col-span-1 md:col-span-2 text-lg gap-2 gap-y-1 grid grid-cols-2 w-full"
      :class="{ 'dir-rtl': locale == 'ar' }"
    >
      <h2 class="text-lg flex items-center col-span-1">
        <span class="border-black border-b-2">{{ t('customerInfo') }}</span>
      </h2>
      <div class="col-span-1 justify-self-end items-center flex">
        <div v-if="examinationCreatedDate" class="flex items-center my-3 mx-2">
          <span>{{ `${t('examinationNumber')}:` }}</span>
          <span class="mx-2">{{ `${examination.externalID}` }}</span>
        </div>
        <div v-if="examinationCreatedDate && examination.externalID">|</div>
        <div v-if="examinationCreatedDate" class="flex items-center my-3 mx-2">
          <span>{{ `${t('examinationDate')}:` }}</span>
          <span class="mx-2">{{ `${examinationCreatedDate}` }}</span>
        </div>
      </div>
    </div>

    <div
      :class="{
        'dir-rtl self-end': locale == 'ar',
        'self-start': locale != 'ar'
      }"
      class="px-4"
    >
      <div class="flex flex-wrap">
        <div class="flex items-center my-1 relative" :class="{ ar: locale == 'ar' }">
          <span>{{ t('name') }}</span>
          <el-input
            tabindex="1"
            autofocus
            style="width: 150px"
            v-model="customerDisplayName"
            readonly
            disabled
            class="mx-3 examination-input"
            ref="nameInput"
          />
          <el-button
            v-if="!customer.id"
            class="select-customer-btn"
            type="primary"
            size="small"
            :icon="Plus"
            @click="selectCustomerDialogVisiable = true"
            circle
          />
        </div>
        <div class="flex items-center my-1 mx-2">
          <span>{{ t('dateOfBirth') }}</span>
          <el-input
            tabindex="2"
            style="width: 98px"
            v-model="customerDateOfBirth"
            readonly
            disabled
            class="mx-3 examination-input"
          />
        </div>
        <div class="flex items-center my-1 mx-2">
          <span>{{ t('age') }}</span>
          <el-input
            tabindex="2"
            style="width: 54px"
            v-model="customerAge"
            readonly
            disabled
            class="mx-3 examination-input"
          />
        </div>
        <div class="flex items-center my-1 mx-2">
          <span class="w-16">{{ t('phone') }} #1 </span>
          <el-input
            tabindex="3"
            style="width: 150px; direction: ltr"
            v-model="customer.phone"
            readonly
            disabled
            class="mx-3 examination-input"
          />
        </div>
        <div class="flex items-center my-1 mx-2">
          <span class="custom-required">{{ t('optometrist') }}</span>
          <el-select
            filterable
            clearable
            v-model="examination.examinatorID"
            class="mx-3 examination-input"
            style="width: 120px"
            placeholder=" "
          >
            <el-option
              v-for="option in examinators"
              :key="option"
              :label="`${option.firstName ? option.firstName : ''} ${option.lastName ? option.lastName : ''}`"
              :value="option.id"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="w-full p-1 border-t-2"></div>

    <div
      class="px-4 mt-1 self-start items-center grid grid-cols-6 gap-y-2 gap-x-5"
      style="direction: ltr"
    >
      <div class="mx-2">VA Without Glasses</div>

      <div class="justify-self-end font-bold self-center flex justify-around items-center">
        <div>OD</div>
        <el-select
          filterable
          clearable
          v-model="examination.vaWithoutGlassesOD"
          class="mx-2"
          style="width: 100px"
          placeholder=" "
        >
          <el-option
            v-for="option in examinationOptions"
            :key="option"
            :label="option"
            :value="option"
          ></el-option>
        </el-select>
      </div>

      <div class="justify-self-end font-bold self-center flex justify-around items-center">
        <div>OS</div>
        <el-select
          filterable
          clearable
          v-model="examination.vaWithoutGlassesOS"
          class="mx-2"
          style="width: 100px"
          placeholder=" "
        >
          <el-option
            v-for="option in examinationOptions"
            :key="option"
            :label="option"
            :value="option"
          ></el-option>
        </el-select>
      </div>

      <div class="justify-self-end">VA With Glasses</div>
      <div class="justify-self-end font-bold self-center flex justify-around items-center">
        <div>OD</div>
        <el-select
          filterable
          clearable
          v-model="examination.vaWithGlassesOD"
          class="mx-2"
          style="width: 100px"
          placeholder=" "
        >
          <el-option
            v-for="option in examinationOptions"
            :key="option"
            :label="option"
            :value="option"
          ></el-option>
        </el-select>
      </div>
      <div class="justify-self-end font-bold self-center flex justify-around items-center">
        <div>OS</div>
        <el-select
          filterable
          clearable
          v-model="examination.vaWithGlassesOS"
          class="mx-2"
          placeholder=" "
          style="width: 100px"
        >
          <el-option
            v-for="option in examinationOptions"
            :key="option"
            :label="option"
            :value="option"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div
      class="px-4 mt-2 self-start items-center grid grid-cols-6 gap-y-2 gap-x-5"
      style="direction: ltr"
    >
      <div class="mx-2" style="width: 130px">I.O.P</div>

      <div class="justify-self-end font-bold self-center flex justify-around items-center">
        <div>OD</div>
        <el-input-number
          tabindex="8"
          v-model="examination.iopOD"
          :min="8"
          :max="70"
          :step="1"
          controls-position="right"
          class="ml-4"
        />
      </div>

      <div class="justify-self-end font-bold self-center flex justify-around items-center">
        <div>OS</div>
        <el-input-number
          tabindex="8"
          v-model="examination.iopOS"
          :min="8"
          :max="70"
          :step="1"
          controls-position="right"
          class="ml-4"
        />
      </div>
    </div>

    <div class="px-4 self-start grid grid-cols-7 gap-y-2 gap-x-5">
      <h2 class="justify-self-start pb-0" style="width: 145px">Distance Glasses</h2>
      <h2 class="justify-self-start pb-0">SPH</h2>
      <h2 class="justify-self-start pb-0">CYL</h2>
      <h2 class="justify-self-start pb-0">AXIS</h2>
      <h2 class="justify-self-start pb-0">IPD</h2>
      <h2 class="justify-self-start pb-0 col-span-2"></h2>

      <div class="justify-self-end font-bold self-center">OD</div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="8"
          v-model="examination.distanceODSPH"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="9"
          v-model="examination.distanceODCYL"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="10"
          v-model="examination.distanceODAXIS"
          :step="5"
          :min="0"
          :max="180"
          :precision="0"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="11"
          v-model="examination.distanceODIPD"
          :step="1"
          :min="40"
          :precision="0"
          controls-position="right"
        />
      </div>
      <div class="flex items-start col-span-2 row-span-3"></div>

      <div class="justify-self-end font-bold self-center">OS</div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="12"
          v-model="examination.distanceOSSPH"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="13"
          v-model="examination.distanceOSCYL"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="14"
          v-model="examination.distanceOSAXIS"
          :step="5"
          :min="0"
          :max="180"
          :precision="0"
          controls-position="right"
        />
      </div>
    </div>

    <div class="px-4 self-start grid grid-cols-7 gap-y-2 gap-x-5">
      <h2 class="justify-self-start pb-0" style="width: 145px">Reading Glasses</h2>
      <h2 class="justify-self-start pb-0">SPH</h2>
      <h2 class="justify-self-start pb-0">CYL</h2>
      <h2 class="justify-self-start pb-0">AXIS</h2>
      <h2 class="justify-self-start pb-0">IPD</h2>
      <h2 class="justify-self-start pb-0 col-span-2"></h2>

      <div class="justify-self-end font-bold self-center flex items-center">
        <el-checkbox v-model="examination.readingAdd" label="Add" />
        <div class="ml-2">OD</div>
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="15"
          v-model="examination.readingODSPH"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="16"
          v-model="examination.readingODCYL"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="17"
          v-model="examination.readingODAXIS"
          :step="5"
          :min="0"
          :max="180"
          :precision="0"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="18"
          v-model="examination.readingODIPD"
          :step="1"
          :min="40"
          :precision="0"
          controls-position="right"
        />
      </div>
      <div class="flex items-start col-span-2 row-span-3"></div>

      <div class="justify-self-end font-bold self-center flex items-center">
        <div class="ml-2">OS</div>
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="19"
          v-model="examination.readingOSSPH"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="20"
          v-model="examination.readingOSCYL"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="21"
          v-model="examination.readingOSAXIS"
          :step="5"
          :min="0"
          :max="180"
          :precision="0"
          controls-position="right"
        />
      </div>
    </div>

    <div class="px-4 self-start grid grid-cols-7 gap-y-2 gap-x-5">
      <h2 class="justify-self-start pb-0" style="width: 145px">Contact Lenses</h2>
      <h2 class="justify-self-start pb-0">SPH</h2>
      <h2 class="justify-self-start pb-0">CYL</h2>
      <h2 class="justify-self-start pb-0">AXIS</h2>
      <h2 class="justify-self-start pb-0"></h2>
      <h2 class="justify-self-start pb-0 col-span-2"></h2>

      <div class="justify-self-end font-bold self-center flex items-center">
        <div class="ml-2">OD</div>
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="22"
          v-model="examination.contactODSPH"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="23"
          v-model="examination.contactODCYL"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="24"
          v-model="examination.contactODAXIS"
          :step="5"
          :min="0"
          :max="180"
          :precision="0"
          controls-position="right"
        />
      </div>
      <div class="flex items-start col-span-3 row-span-3"></div>
      <div class="justify-self-end font-bold self-center flex items-center">
        <div class="ml-2">OS</div>
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="25"
          v-model="examination.contactOSSPH"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="26"
          v-model="examination.contactOSCYL"
          :step="0.25"
          :precision="2"
          controls-position="right"
        />
      </div>
      <div class="flex items-start justify-self-start">
        <el-input-number
          tabindex="27"
          v-model="examination.contactOSAXIS"
          :step="5"
          :min="0"
          :max="180"
          :precision="0"
          controls-position="right"
        />
      </div>
      <div class="flex items-start col-span-3 row-span-3"></div>
    </div>

    <div class="px-4 w-full grid grid-cols-2 input-section">
      <div class="grid custom-grid-examination-notes gap-y-2 gap-x-5">
        <div class="col-span-1"></div>
        <div class="flex flex-col">
          <h2 class="justify-self-start p-0 pb-1">{{ t('notes') }}</h2>
          <div class="flex items-start">
            <el-input
              tabindex="28"
              v-model="examination.notes"
              :rows="3"
              type="textarea"
              class="h-full"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row items-center justify-end">
        <div class="md:w-4/12 m-2" v-if="enablePrint">
          <horizontal-button
            :title="t('printID')"
            faIcon="credit-card"
            is-warning
            @click.prevent="printID()"
          />
        </div>

        <div class="md:w-4/12 m-2" v-if="enablePrint">
          <horizontal-button :title="$t('actions.print')" faIcon="print" @click.prevent="print()" />
        </div>

        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('examinationForm')"
          />
        </div>

        <div class="md:w-4/12 m-2" v-if="enableClearForm">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('examinationForm')"
          />
        </div>
      </div>
    </div>
  </el-form>
</template>

<script lang="ts">
/* eslint-disable */

import { defineComponent, h } from 'vue'
import { ElMessage } from 'element-plus'

// components
import HorizontalButton from '@/components/HorizontalButton.vue'
import { ElForm } from 'element-plus'

// models
import Examination, { examinationI18nMessages } from '@/models/customer/Examination'
import Customer, { customerI18nMessages } from '@/models/customer/Customer'
import { useI18n } from 'vue-i18n'

import { formatPostgresDate, formatAge } from '@/utils/date'
import NewCustomerDialog from '@/dialogs/customer/NewCustomerDialog.vue'
import User from '@/models/user/User'
import { Plus } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'examination-form',

  setup() {
    const { t, locale } = useI18n({
      useScope: 'global',
      messages: {
        en: {
          ...customerI18nMessages.en,
          ...examinationI18nMessages.en,
          age: 'Age',
          customerInfo: 'Customer Info',
          notes: 'Notes',
          pleaseSelectACustomer: 'Please select a Customer',
          pleaseSelectAnOptometrist: 'Please select an Optometrist',
          confirm: 'Confirm',
          printID: 'ID Print'
        },
        ar: {
          ...customerI18nMessages.ar,
          ...examinationI18nMessages.ar,
          age: 'العمر',
          customerInfo: 'بيانات الزبون',
          notes: 'الملاحظات',
          pleaseSelectACustomer: 'يرجى اختيار زبون',
          pleaseSelectAnOptometrist: 'يرجى اختيار الفاحص',
          confirm: 'اختيار',
          printID: 'طباعة الكرت'
        }
      }
    })

    const rules = {
      id: [{ required: true, message: t('validation.required') }]
    }

    return { t, locale, rules, Plus }
  },

  components: {
    HorizontalButton,
    NewCustomerDialog
  },
  props: {
    initCustomer: {
      type: Customer,
      required: true
    },
    initExamination: {
      type: Examination,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    },
    enablePrint: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      examination: this.initExamination,
      examinators: [] as User[],
      customer: this.initCustomer,
      customers: null as Customer[] | any,
      error: { title: '', body: '' },
      selectCustomerDialogVisiable: false,
      newCustomerDialogVisiable: false,
      selectedCustomerElSelect: null,
      examinationOptions: [
        'No.LP',
        'L.P',
        'H.M',
        'C.F 0.5m',
        'C.F 1m',
        'C.F 2m',
        'C.F 3m',
        'C.F 4m',
        'C.F 5m',
        '6/60p',
        '6/60',
        '6/36p',
        '6/36',
        '6/24p',
        '6/24',
        '6/18p',
        '6/18',
        '6/12p',
        '6/12',
        '6/9p',
        '6/9',
        '6/6p',
        '6/6',
        '6/5',
        '6/4'
      ]
    }
  },
  beforeMount() {
    if (this.examination.readingODIPD == null) {
      delete this.examination.readingODIPD
    }

    if (this.examination.distanceODIPD == null) {
      delete this.examination.distanceODIPD
    }

    if (this.examination.contactODAXIS == null) {
      delete this.examination.contactODAXIS
    }

    if (this.examination.contactOSAXIS == null) {
      delete this.examination.contactOSAXIS
    }

    if (this.examination.distanceODAXIS == null) {
      delete this.examination.distanceODAXIS
    }

    if (this.examination.distanceOSAXIS == null) {
      delete this.examination.distanceOSAXIS
    }

    if (this.examination.readingODAXIS == null) {
      delete this.examination.readingODAXIS
    }

    if (this.examination.readingOSAXIS == null) {
      delete this.examination.readingOSAXIS
    }

    if (this.examination.iopOD == null) {
      delete this.examination.iopOD
    }

    if (this.examination.iopOS == null) {
      delete this.examination.iopOS
    }
  },
  async mounted() {
    if (!this.customer.id) {
      const url = `${Customer.ENDPOINT}`
      const customersRes = await this.$http.get<Customer[]>(url)
    }

    const usersRes = await this.$http.get<User[]>(User.ENDPOINT + '?role=optometrist')
    this.examinators = usersRes.map((user) => User.from(user))
  },
  computed: {
    customerDisplayName() {
      if (this.customer.id) {
        return `${this.customer.firstName} ${this.customer.lastName}`
      }
      return ''
    },
    customerDateOfBirth() {
      if (this.customer.id) {
        return `${formatPostgresDate(String(this.customer.dateOfBirth), 'DD/MM/YYYY')}`
      }
      return ''
    },
    customerAge() {
      if (this.customer.id) {
        return `${formatAge(String(this.customer.dateOfBirth))}`
      }
      return ''
    },
    examinationCreatedDate() {
      if (this.examination.id) {
        return `${formatPostgresDate(String(this.examination.createdAt), 'DD/MM/YYYY')}`
      } else {
        return null
      }
    }
  },

  methods: {
    onCustomerCreater(customer: Customer) {
      this.customer = customer
      this.examination.customerID = customer.id
      this.newCustomerDialogVisiable = false
    },

    async searchCustomers(query: string) {
      if (query.trim()) {
        try {
          const url = `${Customer.ENDPOINT}?q=${query}`
          const customersRes = await this.$http.get<Customer[]>(url)
          this.customers = customersRes.map((customer) => Customer.from(customer))
        } catch (error) {
          this.$alertModal.showDanger({ title: error.title })
          this.customers.value = []
        }
      }
    },

    confirmSelectCustomer() {
      this.customer = this.selectedCustomerElSelect! as Customer
      this.examination.customerID = this.customer.id
      this.selectCustomerDialogVisiable = false
    },

    print() {
      this.$router.push({
        name: 'examination-receipt',
        params: { examination_id: this.examination.id }
      })
    },

    printID() {
      this.$router.push({
        name: 'examination-id-print',
        params: { examination_id: this.examination.id }
      })
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      this.examination.notes = undefined

      this.examination.contactODAXIS = undefined
      this.examination.contactODCYL = undefined
      this.examination.contactODSPH = undefined

      this.examination.contactOSAXIS = undefined
      this.examination.contactOSCYL = undefined
      this.examination.contactOSSPH = undefined

      this.examination.vaWithGlassesOD = undefined
      this.examination.vaWithGlassesOS = undefined
      this.examination.vaWithoutGlassesOD = undefined
      this.examination.vaWithoutGlassesOS = undefined

      this.examination.distanceODAXIS = undefined
      this.examination.distanceODCYL = undefined
      this.examination.distanceODIPD = undefined
      this.examination.distanceODSPH = undefined
      this.examination.distanceOSAXIS = undefined
      this.examination.distanceOSCYL = undefined
      this.examination.distanceOSSPH = undefined

      this.examination.readingODAXIS = undefined
      this.examination.readingODCYL = undefined
      this.examination.readingODIPD = undefined
      this.examination.readingODSPH = undefined
      this.examination.readingOSAXIS = undefined
      this.examination.readingOSCYL = undefined
      this.examination.iopOD = undefined
      this.examination.iopOS = undefined
      form.resetFields()
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        }

        if (!this.examination.customerID) {
          ElMessage.error({
            dangerouslyUseHTMLString: true,
            message: `<h2>${this.t('pleaseSelectACustomer')}</h2>`
          })
          return false
        }

        if (!this.examination.examinatorID) {
          ElMessage.error({
            dangerouslyUseHTMLString: true,
            message: `<h2>${this.t('pleaseSelectAnOptometrist')}</h2>`
          })
          return false
        }

        const updatedObj = { ...this.examination } as Examination

        if (updatedObj.examinator && updatedObj.examinatorID) {
          if (updatedObj.examinator.id != updatedObj.examinatorID) {
            updatedObj.examinator = undefined
          }
        }

        for (const key in updatedObj) {
          if (updatedObj.hasOwnProperty(key)) {
            const typedKey = key as keyof Examination
            if (updatedObj[typedKey] === undefined) {
              // @ts-ignore
              updatedObj[typedKey] = null
            }
          }
        }
        const resp = await this.submitAction(updatedObj)

        if (resp) {
          this.error.title = resp?.title
          this.error.body = resp?.body
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName)
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: '' })
        }
        return true
      })
    }
  }
})
</script>

<style scoped>
form > h1 {
  @apply text-lg py-3 font-bold;
}

form > h2 {
  @apply py-3 font-bold;
}

formt > h3 {
  @apply py-3 font-bold;
}

form {
  @apply flex flex-col items-center;
}

.custom-grid-examination-notes {
  grid-template-columns: 145px 1fr;
}

.select-customer-btn {
  position: absolute;
  right: 15px;
  height: 24px;
  width: 32px;
  top: 4px;
}

.ar .select-customer-btn {
  left: 15px;
  top: 7px;
  right: unset;
}

.dir-rtl {
  direction: rtl;
}

.custom-required:before {
  content: '*';
  color: var(--el-color-danger);
  margin-right: 4px;
}
</style>

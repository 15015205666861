<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />


  <div v-else class="flex flex-row">
    <div class="flex flex-col">
      <action-button :title="t('print')" faIcon="print" @click="print" />
    </div>

    <div
      class="flex flex-col items-center mx-4 mt-3 px-2"
      id="small-receipt"
      :class="{
      'dir-ltr': $ctx.locale == 'en',
      'dir-rtl': $ctx.locale == 'ar'
    }"
    >
      <div
        class="flex flex-col items-center w-full"
        style="height: 100vh;"
      >
        <!-- header info -->
        <div id="small-receipt-header" class="flex flex-col w-full">
          
        </div>

        <div id="small-receipt-body" class="w-full" v-if="registerClosing">
          <!-- title -->

          <div class="flex w-full px-5 mt-4 text-center justify-center">
            <p class="text-center text-xl">{{t("registerClosingReport")}}</p>
          </div>

          <div class="border border-gray-200 my-4"></div>

          <div class="flex w-full px-5">
            <div class="w-1/2">
              {{t("store")}}
            </div>
            <div class="w-1/2 font-bold">
              {{$ctx.locale === 'ar' ? registerClosing.location.arName : registerClosing.location.enName}}
            </div>
          </div>
          <div class="flex w-full px-5">
            <div class="w-1/2">
              {{t("register")}}
            </div>
            <div class="w-1/2 font-bold">
              {{$ctx.locale === 'ar' ? registerClosing.register.arName : registerClosing.register.enName}}
            </div>
          </div>

          <div class="border border-gray-200 my-4"></div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("openedBy")}}
            </div>
            <div class="w-1/2 font-bold flex flex-col">
              <span>{{registerClosing.openedBy.firstName + " " + registerClosing.openedBy.lastName}}</span>
              <span class="dir-ltr"> {{formatPostgresDate(registerClosing.openingDate, "YYYY-MM-DD hh:mm:ss A")}}</span>
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("closedBy")}}
            </div>
            <div class="w-1/2 font-bold flex flex-col">
              <span>{{registerClosing.closedBy.firstName + " " + registerClosing.closedBy.lastName}}</span>
              <span class="dir-ltr"> {{formatPostgresDate(registerClosing.closingDate, "YYYY-MM-DD hh:mm:ss A")}}</span>
            </div>
          </div>

          <div class="border border-gray-200 my-4"></div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("expectedOpenFloat")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.expectedOpeningFloatCash, $ctx.currency)}}
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("actualOpenFloat")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.actualOpeningFloatCash, $ctx.currency)}}
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("difference")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.openFloatCashVariance, $ctx.currency)}}
            </div>
          </div>

          <div class="border border-gray-200 my-4"></div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("totalSalesInCash")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.totalCashInFromSalesAndPayments, $ctx.currency)}}
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("totalAdds")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.addedCash, $ctx.currency)}}
            </div>
          </div>

          <div class="border border-gray-200 my-4"></div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("totalRefunds")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.totalSalesRefunds, $ctx.currency)}}
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("totalDrops")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.droppedCash, $ctx.currency)}}
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("totalWithdrawals")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.totalWithdrawals, $ctx.currency)}}
            </div>
          </div>

          <div class="border border-gray-200 my-4"></div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("expectedDepositedCash")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.expectedDepositedCash, $ctx.currency)}}
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("actualDepositedCash")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.actualDepositedCash, $ctx.currency)}}
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("depositedCashDifference")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.depositedCashVariance, $ctx.currency)}}
            </div>
          </div>

          <div class="border border-gray-200 my-4"></div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("expectedClosingFloat")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.expectedClosingFloatCash, $ctx.currency)}}
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("actualClosingFloat")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.actualClosingFloatCash, $ctx.currency)}}
            </div>
          </div>

          <div class="flex w-full px-5 mt-4">
            <div class="w-1/2">
              {{t("difference")}}
            </div>
            <div class="w-1/2 font-bold">
              {{ formatMoney(registerClosing.floatCashVariance, $ctx.currency)}}
            </div>
          </div>

          <div class="border border-gray-200 my-4" v-if="registerClosing.openNotes || registerClosing.closingNotes"></div>

          <div class="flex flex-col w-full px-5 mt-4" v-if="registerClosing.openNotes">
            <div class="w-full">
              {{t("openNotes")}}
            </div>
            <div class="font-bold">
              {{registerClosing.openNotes}}
            </div>
          </div>

          <div class="flex flex-col w-full px-5 mt-4" v-if="registerClosing.closingNotes">
            <div class="w-full">
              {{t("closingNotes")}}
            </div>
            <div class="font-bold">
              {{registerClosing.closingNotes}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { i18nOrderMessages } from "@/models/sales/Order";
import { formatPostgresDate, parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero, formatMoney } from "@/utils/money";
import Location from "@/models/company/Location";
import { Register } from "@/models/company/Register";
import User from "@/models/user/User";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import ActionButton from "@/components/ActionButton.vue";


interface RegisterClosing {
  locationID: string;
  location: Location;

  registerID: string;
  register: Register;

  openingDate: string | null;
  openedByID: string;
  openedBy: User;

  closingDate: string | null;
  closedByID: string;
  closedBy: User;

  totalCashInFromSalesAndPayments: number;
  addedCash: number;

  totalSalesRefunds: number;
  droppedCash: number;
  totalWithdrawals: number;

  expectedDepositedCash: number;
  actualDepositedCash: number;
  depositedCashVariance: number;

  expectedOpeningFloatCash: number;
  actualOpeningFloatCash: number;
  openFloatCashVariance: number;

  expectedClosingFloatCash: number;
  actualClosingFloatCash: number;
  floatCashVariance: number;

  currency: string;
  precision: number | null;

  openNotes?: string;
  closingNotes?: string;
}

export default defineComponent({
  // computed: {
  //   transaction() {
  //     return transaction;
  //   }
  // },
  components: { ActionButton, AlertBadge, LoadingSpinner },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          registerClosingReport: "Register Closing Report",
          store: "Store",
          register: "Register",
          openedBy: "Opened By",
          closedBy: "Closed By",
          expectedOpenFloat: "Expected Opening Floating",
          actualOpenFloat: "Actual Opening Floating",
          totalSalesInCash: "Total sales & payments",
          totalPaymentsInCash: "Total Payments in Cash",
          totalAdds: "Total Adds",
          totalRefunds: "Total Cash Refunds",
          totalDrops: "Total Drops",
          totalWithdrawals: "Total Withdrawals",
          expectedDepositedCash: "Expected Deposited Cash",
          actualDepositedCash: "Actual Deposited Cash",
          depositedCashDifference: "Deposited Cash Difference",
          expectedClosingFloat: "Expected Closing Float",
          actualClosingFloat: "Actual Closing Float",
          difference: "Closing Float Difference",
          openNotes: "Open notes",
          closingNotes: "Closing notes"
        },
        ar: {
          ...i18nOrderMessages.ar,
          registerClosingReport: "تقرير اغلاق الخزانة",
          store: "المتجر",
          register: "صندوق النقد",
          openedBy: "فتح الصندوق",
          closedBy: "غلق الصندوق",
          expectedOpenFloat: "المبلغ الدوار عند الفتح (المتوقع)",
          actualOpenFloat: "المبلغ الدوار عند الفتح (الفعلي)",
          totalSalesInCash: "مبلغ المبيعات والدفعات",
          totalPaymentsInCash: "مبلغ الدفعات",
          totalAdds: "الاضافات",
          totalRefunds: "الترجيعات النقدية",
          totalDrops: "التوديعات النقدية",
          totalWithdrawals: "السحوبات النقدية",
          expectedDepositedCash: "مبلغ التوديع (المتوقع)",
          actualDepositedCash: "مبلغ التوديع (الفعلي)",
          depositedCashDifference: "الفرق",
          expectedClosingFloat: "المبلغ الدوار عند الاغلاق (المتوقع)",
          actualClosingFloat: "المبلغ الدوار عند الاغلاق (الفعلي)",
          difference: "الفرق",
          openNotes: "ملاحظات الفتح",
          closingNotes: "ملاحظات الاغلاق"
        }
      }
    });



    return { t };
  },

  data() {
    const registerClosing: RegisterClosing = undefined;
    return {
      loading: true,
      barcodeText: "",
      error: {
        title: "",
        body: ""
      },
      registerClosing
    };
  },

  async beforeMount() {

    try {
      const resp = await this.$http.get<RegisterClosing>(`/reports/sales/register-closing/${this.$route.params.id}`);
      resp.register = Register.from(resp.register);
      resp.openedBy = User.from(resp.openedBy);
      resp.closedBy = User.from(resp.closedBy);
      resp.location = Location.from(resp.location);
      this.registerClosing = resp;
    } catch (errorResp) {
      this.error.title = errorResp.title
      this.error.body = errorResp.body
    }

    this.loading = false
  },
  created() {
    // Add print CSS when component is created
    this.addPrintCSS();
  },
  beforeUnmount() {
    // Remove print CSS when component is about to be destroyed
    this.removePrintCSS();
  },
  methods: {
    parseFormat,
    formatDinero,
    formatMoney,
    formatPostgresDate,
    truncateItemName(itemName: string) {
      const maxLength = Math.min(itemName.length, 40);
      return maxLength >= 40
        ? `${itemName.substring(0, maxLength)}...`
        : itemName;
    },
    print() {
      window.print();
    },
    addPrintCSS() {
      // Create a style element with a unique id
      const style = document.createElement("style");
      style.setAttribute("id", "print-css");
      style.textContent = `
          @media print {
            @page:first {
              size: A4;
              margin-left: 0;
              margin-right: 0;
              margin-top: 0px;
            }

            @page {
              size: A4;
              margin-left: 0;
              margin-right: 0;
              margin-top: 40px;
              margin-bottom: 30px;
            }

            receipt-row {
              width: 50%;
            }
          }`;
      // Append the style to the document head
      document.head.appendChild(style);
    },
    removePrintCSS() {
      // Find and remove the style element
      const style = document.querySelector("#print-css");
      if (style && style.parentNode) {
        style.parentNode.removeChild(style);
      }
    },
  }
});
</script>
<style scoped>
.thecky-site {
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
}

#small-receipt {
  width: 100mm;
  overflow: scroll;
  background-color: #fff;
}

#small-receipt-header {
  margin-top: 10px;
}

#small-receipt-body {
  margin-top: 10px;
}

@media print {
  body * {
    page-break-after: avoid;
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  #small-receipt,
  #small-receipt * {
    visibility: visible;
  }

  #small-receipt {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    margin: 0cm;
  }

  #small-receipt-header {
    margin-top: 0;
  }

  #small-receipt-body {
    position: relative;
  }

  p,
  div {
    page-break-inside: avoid;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { Transaction } from "@/models/accounting/Transaction";
import RegisterForm from "@/views/accounting/registers/RegisterForm.vue";
import { CellClickedEvent, ColDef, GridOptions } from "ag-grid-community";
import { Register, RegisterStatus } from "@/models/company/Register";
import { AgGridVue } from 'ag-grid-vue3'
import { useI18n } from "vue-i18n";
import { formatMoney } from "../../../utils/money";
import { formatPostgresDate } from "@/utils/date";
import User from "@/models/user/User";

interface RegisterClosingResponse {
  id: string,
  externalID: string,
  closingDate: string | null,
  closedBy: User,
  openingDate: string | null,
  openedBy: User,
}

export default defineComponent({
  name: "Register",
  components: { RegisterForm, AlertBadge, LoadingSpinner, AgGridVue },

  setup() {
    const messages = {
      en: {
        closingReports: "Register Closing Reports",
        no: 'Register Name',
        status: 'Status',
        openingFloating: 'Opening Floating',
        closingFloating: 'Closing Floating',
        totalCashIn: 'Total Cash In',
        totalCashOut: 'Total Cash Out',
        balance: 'Balance',
        moneyDropped: 'Money Dropped',
        openRegister: 'Open Register',
        closeRegister: 'Close Register',
        action: "Action",
        open: "open",
        close: "closed",
        openedBy: "Opened By",
        closedBy: "Closed By",
        closingDate: "Closing date",
        openingDate: "Opening date",
        closingTime: "Closing time",
        openingTime: "Opening time"
      },
      ar: {
        closingReports: "تقارير اغلاق الخزانة",
        no: 'اسم المسجلة',
        status: 'الحالة',
        openingFloating: 'المبلغ الدوار عند الفتح',
        closingFloating: 'المبلغ الدوار عند الإغلاق',
        totalCashIn: 'النقد الداخل',
        totalCashOut: 'النقد الخارج',
        balance: 'الرصيد',
        moneyDropped: 'النقد المودّع',
        action: "فتح/اغلاق",
        open: "مفتوحة",
        close: "مغلقة",
        openedBy: "موظف الفتح",
        closedBy: "موظف الاغلاق",
        closingDate: "تاريخ الاغلاق",
        openingDate: "تاريخ الفتح",
        closingTime: "وقت الاغلاق",
        openingTime: "وقت الفتح",
        openRegister: 'أفتح الصندوق',
        closeRegister: 'أغلق الصندوق',
      }
    }
    const { t } = useI18n({ messages, useScope: 'global' })

    return {t}
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: RegisterClosingResponse[] = []
    const gridOptions: GridOptions = {
      autoSizeStrategy: {
        type: 'fitCellContents'
      },
    }
    const defaultColDef: ColDef = {}
    const registerStatus: RegisterStatus = {}

    return {
      loading: false,
      error: {
        title: "",
        body: ""
      },
      showRegisterForm: false,
      initRegisterTransaction: new Transaction(),
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      registerStatus
    }
  },

  methods: {
    formatMoney,
    async submitTransaction(registerTransaction: Transaction) {
      try {
        this.loading = true
        const url = `${Transaction.ENDPOINT}`
        await this.$http.post<Transaction>(url, registerTransaction)
        this.loading = false
      } catch (err) {
        this.error.title = err.title;
        this.error.body = err.body
        this.loading = false
        return err
      }

      return false
    },

    async updateRowData() {
      this.gridApi?.showLoadingOverlay()
      try {
        const resp = await this.$http.get<RegisterClosingResponse[]>(`/reports/sales/register-closing?register_id=${this.$route.params.id}`)
         resp.forEach(r => r.closedBy = User.from(r.closedBy))
        this.rowData = resp
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      await this.updateRowData()
    },

    async handleRegisterClick(event: any) {
      this.$router.push(
        await this.$router.push(this.$Route.SALES_REGISTERS_CLOSE_REGISTER_REPORT.replace(":id", event.data.id))
      )
    }
  },

  async beforeMount() {

    // Get register status
    try {
      this.loading = true;
      const registerRes = await this.$http.get<RegisterStatus>(`/company/register-status?register_id=${this.$route.params.id}`);
      this.registerStatus = RegisterStatus.from(registerRes);
      this.showRegisterForm =  this.$ctx.currentRegister.id === this.$route.params.id && this.registerStatus.status === "open"
    } catch (error) {
      this.$alertModal.showDanger({
        title: error.title,
        body: error.body
      })
    } finally {
      this.loading = false;
    }

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t("openingDate"),
        field: "openingDate",
        filter: 'agDateColumnFilter',
        cellRenderer: params => {
          return `<div dir='ltr'>${formatPostgresDate(params.value, "YYYY-MM-DD")}</div>`
        }
      },
      {
        headerName: this.t("openingTime"),
        field: "openingDate",
        cellRenderer: params => {
          return `<div dir='ltr'>${formatPostgresDate(params.value, "hh:mm:ss A")}</div>`
        }
      },
      {
        headerName: this.t("closingDate"),
        field: "closingDate",
        filter: 'agDateColumnFilter',
        cellRenderer: params => {
          return `<div dir='ltr'>${formatPostgresDate(params.value, "YYYY-MM-DD")}</div>`
        }
      },
      {
        headerName: this.t("closingTime"),
        field: "closingDate",
        cellRenderer: params => {
          return `<div dir='ltr'>${formatPostgresDate(params.value, "hh:mm:ss A")}</div>`
        }
      },
    ]

  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  }
});
</script>

<template>

  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <template v-else>
    <section class="w-full" id="stats" v-if="registerStatus">
      <div class="bg-gray-100 px-5 pt-5">
        <div class="flex flex-col md:flex-row sm:flex-row justify-around text-gray-800">

          <div class="bg-white p-4 lg:w-2/6 rounded-lg shadow-md mb-4 sm:mb-0 flex flex-col items-center justify-center">
            <h3 class="text-sm">{{t("openingFloating")}}</h3>
            <p class="text-3xl mt-2 mb-1">{{formatMoney(registerStatus?.openingFloatingAmount, registerStatus.currency)}}</p>
          </div>

          <div class="bg-green-100 p-4 lg:w-2/6 rounded-lg shadow-md mb-4 sm:mb-0 sm:mx-2 flex flex-col items-center justify-center">
            <h3 class="text-sm">{{t("totalCashIn")}}</h3>
            <p class="text-3xl mt-2 mb-1">{{formatMoney(registerStatus?.totalCashInAmount, registerStatus.currency)}}</p>
          </div>

          <div class="bg-red-100 p-4 lg:w-2/6 rounded-lg shadow-md mb-4 sm:mb-0 flex flex-col items-center justify-center">
            <h3 class="text-sm">{{t("totalCashOut")}}</h3>
            <p class="text-3xl mt-2 mb-1">{{formatMoney(registerStatus?.totalCashOutAmount, registerStatus.currency)}}</p>
          </div>


          <div class="bg-blue-100 p-4 lg:w-2/6 rounded-lg shadow-md mb-4 sm:mb-0 sm:mx-2 flex flex-col items-center justify-center">
            <h3 class="text-sm">{{t("balance")}}</h3>
            <p class="text-3xl mt-2 mb-1">{{formatMoney(registerStatus?.balanceAmount, registerStatus.currency)}}</p>
          </div>

        </div>
      </div>

      <div class="bg-gray-100 px-5 pt-2 pb-5">
        <div class="flex flex-col md:flex-row sm:flex-row justify-around text-gray-800">

          <div class="bg-white p-4 lg:w-2/6 rounded-lg shadow-md mb-4 sm:mb-0 flex flex-col items-center justify-center" :class="{'opacity-30': registerStatus.status === 'open'}">
            <h3 class="text-sm">{{t("closingFloating")}}</h3>
            <p class="text-3xl mt-2 mb-1" v-if="registerStatus?.status === 'close'">{{formatMoney(registerStatus?.closingFloatingAmount, registerStatus.currency)}}</p>
          </div>

          <div class="bg-white p-4 lg:w-2/6 rounded-lg shadow-md mb-4 sm:mb-0 sm:mx-2 flex flex-col items-center justify-center">
            <h3 class="text-sm">{{t("openedBy")}}</h3>
            <p class="text-3xl mt-2 mb-1">{{registerStatus?.openedBy.fullName}}</p>
          </div>

          <div class="bg-white p-4 lg:w-2/6 rounded-lg shadow-md mb-4 sm:mb-0 flex flex-col items-center justify-center" :class="{'opacity-30': registerStatus.status === 'open'}">
            <h3 class="text-sm">{{t("closedBy")}}</h3>
            <p class="text-3xl mt-2 mb-1" v-if="registerStatus.status=== 'close'">{{registerStatus?.closedBy.fullName}}</p>
          </div>

          <div class="bg-white p-4 lg:w-2/6 rounded-lg shadow-md mb-4 sm:mb-0 sm:mx-2 flex flex-col items-center justify-center">
            <h3 class="text-sm">{{t("status")}}</h3>
            <p class="text-3xl mt-2 mb-1">{{t(registerStatus?.status)}}</p>
          </div>

        </div>
      </div>
    </section>

    <section id="actions" class="flex flex-col md:flex-row justify-around">
      <div class="w-10/12 md:w-7/12 mx-6">
        <div class="mb-6"><h1>{{ t('closingReports') }}</h1></div>
        <ag-grid-vue
          class="ag-theme-alpine mt-4 md:mt-0"
          :columnDefs="columnDefs"
          :rowData="rowData"
          rowSelection="multiple"
          :enable-rtl="$ctx.getDir() === 'rtl'"
          style="height: 45vh"
          :pagination="true"
          :paginationAutoPageSize="true"
          :defaultColDef="defaultColDef"
          :onGridReady="onGridReady"
          @cell-double-clicked="handleRegisterClick"
        >
        </ag-grid-vue>
      </div>
      <div v-if="showRegisterForm" class="w-full md:w-5/12 flex justify-center">
        <register-form
          :initRegisterTransaction="initRegisterTransaction"
          :successMsg="$t('actions.saveChangesSuccess')"
          :submitAction="submitTransaction"
          :clearFormOnSubmit="true"
          :enableClearForm="true"
          class="w-10/12"
        />
      </div>

    </section>

  </template>
</template>

<style scoped>

</style>
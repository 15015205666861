<template>
  <div>
    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
          () => {
            error.title = ''
            error.body = ''
          }
        "
    />

    <el-form
      @submit.prevent="validateSubmit('expenseForm')"
      novalidate
      :model="expense"
      :rules="rules"
      label-position="top"
      ref="expenseForm"
      class="flex flex-col items-center"
    >
      <div class="input-section">
        <div class="flex flex-col md:flex-row justify-end">
          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.saveChanges')"
              isSuccess
              faIcon="save"
              @click.prevent="validateSubmit('expenseForm')"
            />
          </div>

          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.clearButton')"
              isDanger
              faIcon="trash"
              @click.prevent="clearForm('expenseForm')"
            />
          </div>
        </div>
      </div>


      <!-- Item Name -->
      <div class="input-section">
        <h1>{{ t('sections.newExpense') }}</h1>
      </div>

      <!-- expense amount -->
      <div class="input-section">
        <el-form-item :label="t('amount')" prop="amount" class="input-label">
          <money-input
            id="amount"
            :currency="$ctx.currency"
            v-model="expense.amount"
            ref="amountRef"
          />
        </el-form-item>
      </div>

      <!-- payee -->
      <div class="input-section">
        <el-form-item :label="t('payee')" class="input-label" prop="payToAccountID">
          <el-select
            id="debited-acount"
            v-model="expense.payToAccountID"
            :trigger-on-focus="false"
            value-key="name"
            class="w-full"
            filterable
            remote
            :remote-method="searchExpensesAccounts"
          >
            <el-option
              v-for="option in expenseAccounts"
              :key="option.id"
              :label="$ctx.locale === 'ar' ? option.arName : option.enName"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>


      <div class="input-section">
        <el-form-item :label="t('paidFrom')" prop="payFromAccountID" class="input-label">
          <el-select
            id="credit-account"
            v-model="expense.payFromAccountID"
            :trigger-on-focus="false"
            value-key="name"
            class="w-full"
            filterable
            remote
            :remote-method="searchAccounts"
          >
            <el-option
              :key="registerAccount.id"
              :label="$ctx.locale === 'ar' ? registerAccount.arName : registerAccount.enName"
              :value="registerAccount.id"
            >
              <div class="w-full bg-green-100">
                {{$ctx.locale === 'ar' ? registerAccount.arName : registerAccount.enName}}
              </div>
            </el-option>

            <el-option
              :key="safeAccount.id"
              :label="$ctx.locale === 'ar' ? safeAccount.arName : safeAccount.enName"
              :value="safeAccount.id"
            >
              <div class="w-full bg-green-100">
                {{$ctx.locale === 'ar' ? safeAccount.arName : safeAccount.enName}}
              </div>
            </el-option>

            <el-option
              v-for="option in accounts"
              :key="option.id"
              :label="$ctx.locale === 'ar' ? option.arName : option.enName"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <!-- expense date -->
      <div class="input-section">
        <el-form-item :label="t('expenseDate')" prop="expenseDate" class="input-label">
          <el-date-picker
            id="expenseDate"
            v-model="expense.expenseDate"
            type="date"
            :placeholder="t('expenseDate')"
          >
          </el-date-picker>
        </el-form-item>
      </div>

      <!-- notes -->
      <div class="input-section">
        <el-form-item :label="t('notes')">
          <el-input v-model="expense.notes" :rows="4" type="textarea"/>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// models

import { Expense } from './Expenses.vue'
import { expenseI18nMessages } from './Expenses.vue'

// components
import HorizontalButton from '@/components/HorizontalButton.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import { ElForm } from 'element-plus'
import MoneyInput from '@/components/form/MoneyInput.vue'
import { useI18n } from 'vue-i18n'
import { Account } from '@/models/accounting/Account'
interface Option {
  name: string
  values: string[]
}

export default defineComponent({
  name: 'new-expense-form',
  setup() {
    const messages = {
      en: {
        ...expenseI18nMessages.en,
        sections: {
          newExpense: 'New Expense'
        },
        success: "Expense has been recoded"
      },
      ar: {
        ...expenseI18nMessages.ar,
        sections: {
          newExpense: 'تسجيل مصروف جديد'
        },
        success: "تم تسجيل المصروف"
      }
    }
    const { t } = useI18n({ messages, useScope: 'global' })
    const rules = {
      expenseDate: [{ required: true, message: t('validation.required') }],
      amount: [{ required: true, message: t('validation.required') }],
      payFromAccountID: [{ required: true, message: t('validation.required') }],
      payToAccountID: [{ required: true, message: t('validation.required') }]
    }
    return { t, rules }
  },
  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },

  data() {

    const accounts : Account[] = [];
    const expenseAccounts : Account[] = [];
    const registerAccount: Account = new Account();
    const safeAccount: Account = new Account();

    return {
      expense: new Expense(),
      error: { title: '', body: '' },
      accounts,
      registerAccount,
      safeAccount,
      expenseAccounts
    }
  },
  async beforeMount() {
    try {
      this.registerAccount = Account.from(await this.$http.get<Account>(`${Account.ENDPOINT}/${this.$ctx.currentRegister.accountID}`))
      this.safeAccount = Account.from(await this.$http.get<Account>(`${Account.ENDPOINT}/${this.$ctx.currentLocation.safeAccountID}`))
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },
  methods: {
    async searchAccounts(query: string) {
      if (query) {
        try {
          const url = `${Account.ENDPOINT}?q=${query}`
          this.accounts = await this.$http.get<Account[]>(url)
        } catch (error) {
          this.$alertModal.showDanger({ title: error.title })
          this.accounts = []
        }
      }
    },

    async searchExpensesAccounts(query: string) {
      if (query) {
        try {
          const url = `${Account.ENDPOINT}?account-category=expenses&q=${query}`
          this.expenseAccounts = await this.$http.get<Account[]>(url)
        } catch (error) {
          this.$alertModal.showDanger({ title: error.title })
          this.expenseAccounts = []
        }
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      this.expense = new Expense()
      this.$globalEmitter.emit('money-input-reset')
      form.resetFields()
    },

    async validateSubmit(formName: string) {
      this.expense.currency = this.$ctx.currency
      this.expense.precision = this.$ctx.currencyPrecision

      const form = this.$refs[formName] as typeof ElForm
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        }
        try {
           await this.$http.post("/accounting/company", this.expense)
        } catch (error) {
          this.error.title = error?.title
          this.error.body = error?.body
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        }

          this.$alertModal.showSuccess({title: this.t("success")})
          this.$router.push(this.$Route.ACCOUNTING_COMPANY)
        return true
      })
    }
  }
})
</script>

<style scoped>
h1 {
  @apply text-lg py-3 font-bold;
}
h2 {
  @apply py-3 font-bold;
}
h3 {
  @apply py-3 font-bold;
}
.input-group {
  @apply flex flex-col justify-between w-full items-end;
}
.input-group-row {
  @apply flex justify-between w-full;
}
.input-group-row > label {
  @apply w-6/12;
}
.input-section {
  @apply rounded w-full justify-center;
}
@screen lg {
  .input-section {
    @apply w-7/12;
  }
}
@screen md {
  .input-section {
    @apply flex-row;
  }
  .input-group {
    @apply flex-row;
  }
  .input-group > .input-label {
    @apply w-6/12;
  }
}
.remove-button {
  @apply text-lg;
  color: var(--danger-text-color);
  align-self: center;
  justify-self: center;
  cursor: pointer;
  margin: 0.5rem;
}
.remove-button:hover {
  color: var(--danger-hover-bg-color);
  align-self: center;
  justify-self: center;
}
.fully-paid-button {
  height: 40px;
}
hr.solid {
  border-top: 1px solid var(--tertiary-bg-color);
}
.hide {
  display: none;
}
.input:hover + .hide {
  display: flex;
  justify-content: center;
}
.danger {
  color: var(--danger-text-color);
  background: var(--danger-bg-color);
}
.success {
  color: var(--success-text-color);
  background: var(--success-bg-color);
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}
tr:nth-child(even) {
  background-color: #f3f3f3;
}
</style>

<template>
  <div class="m-3" style="height: 90vh">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select class="input-label" v-model="selectedValue" @change="applyToSelected">
        <el-option
          key="export"
          :label="$t('views.common.listView.selectedRows.export')"
          value="export"
        >
        </el-option>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full md:w-6/12"
        :prefix-icon="Search"
        v-model="searchTerm"
      />
    </div>

    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      @cell-double-clicked="onCellDoubleClicked"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import actionDropdown from '@/views/accounting/customers/CustomerInvoiceMakePayment.vue'

import { i18nOrderMessages, OrderPayment } from '@/models/sales/Order'
import { useI18n } from 'vue-i18n'
import { parseFormat } from '@/utils/date'
import { formatDinero } from '@/utils/money'
import Dinero, { Currency } from 'dinero.js'
import { CustomerInvoice } from '@/models/sales/CustomerInvoice'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'customers',

  setup() {
    const { t } = useI18n({
      messages: {
        ...i18nOrderMessages,
        en: {
          customer: 'Customer',
          invoiceNo: 'Invoice #',
          invoiceDate: 'Invoice Date',
          dueDate: 'Due Date',
          type: 'Type',
          amountPaid: 'Amount Paid',
          balance: 'Balance',
          action: 'Action',
          total: 'Total',
          newCustomer: 'New Customer'
        },
        ar: {
          customer: 'الزبون',
          invoiceNo: 'رقم الفاتورة',
          invoiceDate: 'تاريخ الفاتورة',
          dueDate: 'تاريخ الاستحقاق',
          type: 'نوع الفاتورة',
          amountPaid: 'المبلغ المدفوع',
          balance: 'الرصيد',
          action: 'العمليات',
          total: 'المجموع',
          newCustomer: 'زبون جديد'
        }
      },
      useScope: 'global'
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: CustomerInvoice[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t('customer'),
        field: 'customer.contactDisplayName',
        cellStyle: { textAlign: 'center' },
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t('invoiceNo'),
        field: 'externalID',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('invoiceDate'),
        field: 'createdAt',
        valueFormatter: (params) => {
          return parseFormat(params.value)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('dueDate'),
        field: 'billDueDate',
        valueFormatter: (params) => {
          return parseFormat(params.value)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('type'),
        field: 'paymentType',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('total'),
        field: 'totalDueAmount',
        valueFormatter: (params) => {
          const invoice = params.node?.data as CustomerInvoice
          return formatDinero(invoice.totalDueDinero)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('amountPaid'),
        field: 'totalPaidAmount',

        valueFormatter: ({ data }) => {
          const invoice = data as CustomerInvoice
          const dineroValue = Dinero({
            amount: invoice.totalPaidAmount,
            currency: invoice.currency as Currency,
            precision: invoice.precision
          })
          return formatDinero(dineroValue)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('balance'),

        valueFormatter: ({ data }) => {
          const invoice = data as CustomerInvoice
          const dineroValue = Dinero({
            amount: invoice.totalBalanceAmount,
            currency: invoice.currency as Currency,
            precision: invoice.precision
          })
          return formatDinero(dineroValue)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('action'),
        //field: "action",
        cellRenderer: actionDropdown
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterItems()
    }
  },

  methods: {
    async updateRowData() {
      try {
        this.gridApi?.showLoadingOverlay()
        const invoicesRes = await this.$http.get<CustomerInvoice[]>(`${CustomerInvoice.ENDPOINT}`)

        this.rowData = invoicesRes.map((invoice) => CustomerInvoice.from(invoice))
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady() {
      await this.updateRowData()
    },

    async applyToSelected() {
      if (this.selectedValue === 'delete' || this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'export') {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    calculateAmountPaid(orderPayments: OrderPayment[]) {
      return orderPayments.reduce((acc, orderPayment) => acc + orderPayment.totalPaidAmount, 0)
    },

    filterItems() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.ACCOUNTING_CUSTOMER_INVOICES.replace(':id', event.data.customerID)
      )
    }
  }
})
</script>

<style></style>

<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/customer/customers')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>

        <template v-if="$can('delete', '/customer/customers')">
          <el-option
            key="delete"
            :label="$t('views.common.listView.selectedRows.delete')"
            value="delete"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/customer/customers')">
          <horizontal-button
            :title="$t('views.customers.newCustomer')"
            @click.prevent="$router.push($Route.CUSTOMERS_NEW_CUSTOMER)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      @cell-double-clicked="onCellDoubleClicked"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
      :rowBuffer="rowBuffer"
      :rowModelType="rowModelType"
      :cacheBlockSize="cacheBlockSize"
      :cacheOverflowSize="cacheOverflowSize"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :infiniteInitialRowCount="infiniteInitialRowCount"
      :maxBlocksInCache="maxBlocksInCache"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import {
  ColDef,
  GridApi,
  GridOptions,
  IGetRowsParams,
  InfiniteRowModelModule,
  ModuleRegistry
} from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'

import Customer, { CustomersPaginated, customerI18nMessages } from '@/models/customer/Customer'
import { useI18n } from 'vue-i18n'
import CustomPriceType from '@/models/inventory/CustomPrice'
import { Search } from '@element-plus/icons-vue'
import { onBeforeMount } from 'vue'
ModuleRegistry.registerModules([InfiniteRowModelModule])

export default defineComponent({
  name: 'customers',

  setup() {
    const { t } = useI18n({
      messages: customerI18nMessages
    })

    return { t, Search }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Customer[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    const rowBuffer = ref<any>(null)
    const rowSelection = ref<any>(null)
    const rowModelType = ref<any>(null)
    const cacheBlockSize = ref<any>(null)
    const cacheOverflowSize = ref<any>(null)
    const maxConcurrentDatasourceRequests = ref<any>(null)
    const infiniteInitialRowCount = ref<any>(null)
    const maxBlocksInCache = ref<any>(null)

    onBeforeMount(() => {
      rowBuffer.value = 0
      rowSelection.value = 'multiple'
      rowModelType.value = 'infinite'
      cacheBlockSize.value = 100
      cacheOverflowSize.value = 100
      maxConcurrentDatasourceRequests.value = 2
      infiniteInitialRowCount.value = 0
      maxBlocksInCache.value = 10
    })

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: '',
      customPriceTypes: [] as Array<CustomPriceType>,
      gridApi: undefined as GridApi | undefined,
      rowBuffer,
      rowSelection,
      rowModelType,
      cacheBlockSize,
      cacheOverflowSize,
      maxConcurrentDatasourceRequests,
      infiniteInitialRowCount,
      maxBlocksInCache
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  async beforeMount() {
    const customPriceTypesResp = await this.$http.get<CustomPriceType[]>(CustomPriceType.ENDPOINT)

    this.customPriceTypes = customPriceTypesResp.map((cat) => CustomPriceType.from(cat))

    const regularCustomPrice = new CustomPriceType()
    regularCustomPrice.id = 'regular'
    regularCustomPrice.enName = 'Regular'
    regularCustomPrice.arName = 'اعتيادي'

    this.customPriceTypes = [regularCustomPrice, ...this.customPriceTypes]

    this.defaultColDef = {
      sortable: true,
      // filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        field: '-',
        hide: true,
        lockVisible: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep'
        }
      },
      {
        headerName: this.t('customerDisplayName'),
        field: 'contactDisplayName',
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t('businessName'),
        field: 'businessName'
      },
      {
        headerName: this.t('email'),
        field: 'email',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('phone'),
        field: 'phone',
        cellStyle: { direction: 'ltr !important', textAlign: 'center' }
      },
      {
        headerName: this.t('phone2'),
        field: 'phone2',
        cellStyle: { direction: 'ltr !important', textAlign: 'center' }
      },
      {
        headerName: this.t('customerType'),
        field: 'customerType',
        valueGetter: (params) => {
          const customer = params.node?.data as Customer
          if (!customer) return ''

          if (!customer.customerType.length) customer.customerType = 'regular'

          for (const ct of this.customPriceTypes) {
            if (ct.id == customer.customerType) {
              return this.$ctx.locale == 'en' ? ct.enName : ct.arName
            }
          }

          return customer.customerType
        }
      },
      {
        headerName: this.t('streetAddress'),
        field: 'streetAddress'
      },
      {
        headerName: this.t('city'),
        field: 'city'
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterResult()
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<Customer[]>(Customer.ENDPOINT)
        this.rowData = data.map((customer) => Customer.from(customer))
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      const dataSource = {
        rowCount: undefined,
        getRows: async (params: IGetRowsParams) => {
          this.gridApi?.showLoadingOverlay()

          let url = `${Customer.ENDPOINT}?startRow=${params.startRow}&endRow=${params.endRow}`
          if (params.sortModel.length) {
            url += `&colId=${params.sortModel[0].colId}&sort=${params.sortModel[0].sort}`
          }

          if (Object.keys(params.filterModel).length) {
            url += `&query=${params.filterModel['-'].filter}`
          }

          const res = await this.$http.get<CustomersPaginated>(url)
          this.rowData = res.data.map((customer) => Customer.from(customer))
          this.gridApi?.hideOverlay()

          const lastRow = res.total
          // call the success callback
          params.successCallback(this.rowData, lastRow)
        }
      }
      this.gridApi?.setGridOption('datasource', dataSource)
      this.gridApi?.setGridOption('serverSideSortOnServer', true)

      // await this.updateRowData()
    },

    async delete(customers: Customer[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.deleteConfirm'))) {
        try {
          await this.$http.delete(Customer.ENDPOINT, { data: customers })
          await this.updateRowData()
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.deleteSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },

    async applyToSelected() {
      if (this.selectedValue === 'delete' || this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'delete') {
            // send request to archive
            await this.delete(selected)
          } else {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    filterResult() {
      // this.gridApi?.setQuickFilter(this.searchTerm)
      this.gridApi?.setFilterModel({ '-': { filter: this.searchTerm } })
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(this.$Route.CUSTOMERS_CUSTOMER_VIEW.replace(':id', event.data.id))
    }
  }
})
</script>

<style lang="scss"></style>

<template>
    <!-- @submit errors -->
<!--    <div class="flex flex-col items-center justify-center w-full">-->
<!--      <div class="flex gap-4">-->
<!--        <div>-->
<!--          <label class="input-label">{{ t('depositTooltip') }}</label>-->
<!--          <easy-total class="m-auto" v-model="depositTotal" />-->
<!--        </div>-->

<!--        <div>-->
<!--          <label class="input-label">{{ t('floatingTooltip') }}</label>-->
<!--          <easy-total class="m-auto" v-model="floatingTotal" />-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="md:w-4/12 m-2">-->
<!--        <horizontal-button :title="t('submitCount')" isSuccess @click.prevent="validateSubmit()" />-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="register-container">

    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <div class="transaction-details">
      <div class="total-transactions">
        {{t("totalTransactions")}} <span class="amount"> {{ formatMoney(allTransactionsAmount, $ctx.currency) }}</span>
      </div>
      <div class="opening-float">
        {{t("openFloat")}} <span class="amount"> {{ formatMoney(openingFloatAmount, $ctx.currency) }}</span>
      </div>
    </div>

    <div class="closing-details">
      <div class="closing-drop"  :class="{'bg-yellow-100' : depositTotal !== allTransactionsAmount, 'bg-white': depositTotal === allTransactionsAmount,}">
        <h3>{{t("closingDrop")}}</h3>
        <p>{{t("depositTooltip")}}</p>
        <div class="input-group">
          <label for="cash">{{t("cash")}}</label>
          <money-input :currency="$ctx.currency" id="cash" v-model="depositTotal" />
        </div>
      </div>

      <div class="floating-money" :class="{'bg-yellow-100' : floatingTotal !== $ctx.currentRegister.defaultOpenAmount, 'bg-white': floatingTotal === $ctx.currentRegister.defaultOpenAmountAmount }">
        <h3>{{t("floatingMoney")}}</h3>
        <p>{{t("floatingTooltip")}}</p>
        <div class="input-group">
          <label for="floating">{{t("cash")}}</label>
          <money-input id="floating" :currency="$ctx.currency" v-model="floatingTotal" />
        </div>
      </div>
    </div>

    <div>
      <div class="input-group" >
        <label for="notes">{{t("notes")}}</label>
        <el-input type="textarea" id="notes" v-model="notes"></el-input>
      </div>
    </div>

    <button @click="validateSubmit">{{t("submitCount")}}</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EasyTotal from '@/components/money/EasyTotal.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import HorizontalButton from '@/components/HorizontalButton.vue'

import Dinero from 'dinero.js'
import { useI18n } from 'vue-i18n'
import { CloseRegisterPayload, Register, RegisterLog } from "@/models/company/Register";
import MoneyInput from "@/components/form/MoneyInput.vue";
import { Money } from "@element-plus/icons-vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { formatMoney } from "../../../utils/money";

export default defineComponent({
  name: 'close-register',

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          submitCount: 'Close Register',
          depositTooltip: 'Please enter the closing deposit amount',
          floatingTooltip: 'Please enter the closing floating amount',
          cash: "Cash",
          totalTransactions: "Sum of all transactions",
          openFloat: "Opening float",
          closingDrop: "Closing Drop",
          floatingMoney: "Floating Money",
          printReport: "Do you want to print register closing report?",
          notes: "Notes"
        },
        ar: {
          submitCount: 'اغلق الخزانة',
          depositTooltip: 'يرجى ادخال مبلغ التوديع النهائي',
          floatingTooltip: 'يرجى ادخال مبلغ الخردة النهائي (لن يودع)',
          cash: "النقد",
          totalTransactions: "مجموع الحركات المالية",
          openFloat: "الخردة عند الفتح",
          closingDrop: "الايداع",
          floatingMoney: "الخردة",
          printReport: "هل تريد طباعة تقرير اغلاق الخزانة؟",
          notes: "الملاحظات"
        }
      }
    })

    return { t }
  },

  components: {
    LoadingSpinner,
    MoneyInput,
    AlertBadge,
  },


  async mounted() {
    // load register status
    try {
      this.loading = true
      const registerLog = await this.$http.get<RegisterLog>(
        `${Register.ENDPOINT}/status/${this.$ctx.currentRegister.id}`
      )

      this.$ctx.updateRegisterStatus(RegisterLog.from(registerLog).action)

      if (!registerLog.action || registerLog.action === 'close') {
        await this.$router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER)
      }

      this.openingFloatAmount = registerLog.actualOpenAmount

      const expectedClosingAmount = await this.$http.get<{expectedDepositAmount: number, currency: number, precision: string}>(
        `${Register.ENDPOINT}/compute-closing-amount/${this.$ctx.currentRegister.id}`
      )

      this.allTransactionsAmount = expectedClosingAmount.expectedDepositAmount;

    } catch (error) {
      this.error.title = error.title
      this.error.body = error.body
    } finally {
      this.loading = false
    }
  },

  data() {
    return {
      error: {
        title: '',
        body: ''
      },
      depositTotal: 0,
      floatingTotal: 0,
      openingFloatAmount: 0,
      allTransactionsAmount: 0,
      loading: false,
      notes: ""
    }
  },

  methods: {
    formatMoney,
    async validateSubmit() {
      try {
        const payload: CloseRegisterPayload = {
          registerID: this.$ctx.currentRegister.id,
          currency: this.$ctx.currency,
          precision: this.$ctx.currencyPrecision,
          actualClosingDepositAmount: this.depositTotal,
          actualClosingFloatingAmount: this.floatingTotal,
          action: 'close',
          timestamp: new Date(),
          userID: this.$ctx.currentUser.id,
          notes: this.notes
        }

        const { registerClosingReportID } = await this.$http.post<{registerClosingReportID: string}>(`company/register-actions`, payload)


        if(confirm(this.t("printReport"))) {
          await this.$router.push(this.$Route.SALES_REGISTERS_CLOSE_REGISTER_REPORT.replace(":id", registerClosingReportID))

        } else {
          await this.$router.push(this.$Route.SALES)
        }

      } catch (error) {
        this.error.title = error.title
        this.error.body = error.body
      }
    }
  }
})
</script>

<style scoped>
.register-container {
  max-width: 900px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #f9f9f9;
}

.transaction-details,
.closing-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.total-transactions,
.opening-float,
.closing-drop,
.floating-money {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.total-transactions,
.opening-float {
  background: #fff;
}
.total-transactions span,
.opening-float span,
.total-entries span,
.amount {
  display: block;
  font-size: 1.5rem;
  color: #333;
  margin-top: 8px;
  font-weight: bold;
}

.closing-drop, .floating-money {
  flex-basis: 48%;
}

h3 {
  margin: 20px 0 10px 0;
  font-size: 1.2rem;
  color: #333;
}

.input-group {
  margin-bottom: 15px;
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.input-group label {
  display: block;
  font-weight: bold;
  color: #666;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Added to include padding in the input width */
}

button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 8px;
  background-color: #db4c3f;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #c44233;
}

/* Additional styles for responsiveness and spacing */
@media (max-width: 768px) {
  .transaction-details,
  .closing-details {
    flex-direction: column;
  }

  .closing-drop, .floating-money {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}
</style>